<template>
    <div>
        <div class="wbnz-changelog" data-webynize-id="282"></div>
    </div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement('script');
        script.src = 'https://widgets.webynize.com/changelog.js';
        script.async = true;
        this.$el.appendChild(script);
    },
};
</script>

<style scoped>
</style>
