<template>
    <div class="table-responsive">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Selected</th>
                    <th>Comments</th>
                    <th>Date</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td>{{ truncate(item.feedback_option, 15) }}</td>
                    <td>
                        <span v-if="expandedComments[item.id]">
                            {{ item.comments }}
                            <a @click="toggleReadMore(item.id)" class="text-primary" role="button">Show less</a>
                        </span>
                        <span v-else>
                            {{ item.comments.length > 100 ? item.comments.slice(0, 100) + "..." : item.comments }}
                            <a
                                v-if="item.comments.length > 100"
                                @click="toggleReadMore(item.id)"
                                class="text-primary"
                                role="button"
                            >
                                Read more
                            </a>
                        </span>
                    </td>
                    <td>{{ formatDate(item.created_at) }}</td>
                    <td>
                        <div class="d-flex flex-wrap gap-2">
                            <button class="btn btn-danger btn-sm" @click="deleteItem(item.id)">
                                <i class="bi bi-trash d-sm-none"></i>
                                <span class="d-none d-sm-inline">Delete</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav aria-label="Page navigation" v-if="items && items.length < 0">
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: !pagination.prev_page_url }">
                    <button class="page-link" @click="changePage(pagination.current_page - 1)">Previous</button>
                </li>
                <li
                    class="page-item"
                    v-for="page in pagination.last_page"
                    :key="page"
                    :class="{ active: pagination.current_page === page }"
                >
                    <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: !pagination.next_page_url }">
                    <button class="page-link" @click="changePage(pagination.current_page + 1)">Next</button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            expandedComments: {},
        };
    },
    methods: {
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString();
        },
        deleteItem(itemId) {
            this.$emit("delete-item", itemId);
        },
        changePage(page) {
            if (page > 0 && page <= this.pagination.last_page) {
                this.$emit("page-changed", page);
            }
        },
        toggleReadMore(itemId) {
            this.expandedComments[itemId] = !this.expandedComments[itemId];
        },
        truncate(text, length = 20) {
            if (!text) return "";
            return text.length > length ? text.substring(0, length) + "..." : text;
        },
    },
};
</script>

<style scoped>
.table td {
    max-width: 200px;
    overflow: hidden;
}

@media (max-width: 600px) {
    .table td {
        max-width: 150px;
    }
}
</style>
