<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.background_color"
                    type="text"
                    id="background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Title Text Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.title_font_color"
                    type="text"
                    id="title_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.title_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Description Text Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.description_font_color"
                    type="text"
                    id="description_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.description_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Question Text Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.question_font_color"
                    type="text"
                    id="question_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.question_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Description Text Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.answer_font_color"
                    type="text"
                    id="answer_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.answer_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Icon Primary Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.icon_primary_color"
                    type="text"
                    id="icon_primary_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.icon_primary_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Icon Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.icon_background_color"
                    type="text"
                    id="icon_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.icon_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Divider Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.divider_color"
                    type="text"
                    id="divider_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.divider_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Branding color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.branding_color"
                    type="text"
                    id="branding_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.branding_color" :disabled="isRestricted" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
