<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Popup title text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.popup_title_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.popup_title_color" :disabled="isRestricted" />
            </div>
        </div>

        <div class="form-group">
            <label for="">Title text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.title_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.title_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Product update text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.product_update_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.product_update_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Product update background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.product_update_background_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.product_update_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Link color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.link_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.link_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.background_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Badge text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.badge_text_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.badge_text_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Badge background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.badge_background_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.badge_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Close button color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.close_button_color"
                    type="text"
                    id="qf-tb-text-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.close_button_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Branding color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.branding_color"
                    type="text"
                    id="branding_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.branding_color" :disabled="isRestricted" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
