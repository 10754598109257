<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Title Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.title_font_color"
                    type="text"
                    id="title_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.title_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.background_color"
                    type="text"
                    id="background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Item Title Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.item_title_color"
                    type="text"
                    id="item_title_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.item_title_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Item Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.item_font_color"
                    type="text"
                    id="item_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.item_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Item Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.item_background_color"
                    type="text"
                    id="item_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.item_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Item Date Front Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.item_days_ago_font_color"
                    type="text"
                    id="item_days_ago_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.item_days_ago_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Link Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.link_color"
                    type="text"
                    id="link_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.link_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Close Button Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.close_button_color"
                    type="text"
                    id="close_button_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.close_button_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Load More Button Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.load_more_font_color"
                    type="text"
                    id="load_more_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.load_more_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Load More Button Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.load_more_background_color"
                    type="text"
                    id="load_more_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.load_more_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Branding color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.branding_color"
                    type="text"
                    id="branding_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.branding_color" :disabled="isRestricted" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
