<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Content font size</label>
            <input
                v-model="localWidgetSettings.content_font_size"
                type="number"
                id="qf-tb-text"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Star size</label>
            <input v-model="localWidgetSettings.star_size" type="number" id="qf-tb-text" class="form-control setting" />
        </div>
        <div class="form-group">
            <label for="">Button font size</label>
            <input
                v-model="localWidgetSettings.button_font_size"
                type="number"
                id="qf-tb-text"
                class="form-control setting"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
