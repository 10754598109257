<template>
    <div class="dropdown-container dropup-center mx-n2">
        <div class="dropdown" ref="dropdown">
            <button
                ref="dropdownButton"
                type="button"
                class="w-100 text-start border-0 bg-transparent shadow-none bg-accent-hover rounded d-flex gap-3 align-items-center"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
                @click="handleClick"
            >
                <slot name="trigger"></slot>
            </button>
            <div class="dropdown-menu dropdown-menu-end p-1 shadow-sm rounded-3 border-0 fade">
                <slot name="menu"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { Dropdown } from "bootstrap";

export default {
    name: "BaseDropdown",
    props: {
        triggerMode: {
            type: String,
            default: "click",
            validator: (value) => ["click", "hover"].includes(value),
        },
    },
    data() {
        return {
            bsDropdown: null,
            isHovering: false,
        };
    },
    mounted() {
        this.initDropdown();
    },
    beforeUnmount() {
        if (this.triggerMode === "hover" && this.$refs.dropdown) {
            this.$refs.dropdown.removeEventListener("mouseenter", this.startHover);
            this.$refs.dropdown.removeEventListener("mouseleave", this.stopHover);
        }
    },
    methods: {
        initDropdown() {
            if (this.$refs.dropdownButton) {
                this.bsDropdown = new Dropdown(this.$refs.dropdownButton);
            }

            if (this.triggerMode === "hover" && this.$refs.dropdown) {
                this.$refs.dropdown.addEventListener("mouseenter", this.startHover);
                this.$refs.dropdown.addEventListener("mouseleave", this.stopHover);
            }
        },
        startHover() {
            this.isHovering = true;
            this.bsDropdown?.show();
        },
        stopHover() {
            this.isHovering = false;
            setTimeout(() => {
                if (!this.isHovering) {
                    this.bsDropdown?.hide();
                }
            }, 100);
        },
        handleClick(event) {
            if (this.triggerMode === "hover") {
                event.preventDefault();
            }
        },
    },
};
</script>

<style scoped>
.dropdown-container {
    max-width: 320px;
}

.dropdown-toggle {
    transition: background 0.2s ease-in-out;
}

.dropdown-toggle:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.dropdown-menu {
    min-width: 200px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    z-index: 2000;
}

.show {
    opacity: 1 !important;
    transform: translateY(0) !important;
}

:deep(.dropdown-item) {
    padding: 8px 16px;
    border-radius: 8px;
}

button:focus,
button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}
</style>
