<template>
    <div v-if="!items || items.length < 1" class="text-center">No data found.</div>

    <div class="table-responsive" v-else>
        <table class="table table-dark table-hover table-striped align-middle rounded-1 overflow-hidden">
            <thead>
                <tr>
                    <th class="py-2 px-3">Title</th>
                    <th class="py-2 px-3">Publish Date</th>
                    <th class="py-2 px-3 text-center">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td class="py-2 px-3">{{ item.title }}</td>
                    <td class="py-2 px-3">
                        {{ publish_date_formatted(item.publish_date) }}
                    </td>
                    <td class="py-2 px-3 text-center">
                        <div class="d-flex flex-wrap gap-2">
                            <button
                                @click="this.$parent.showEditModal($event, item)"
                                type="button"
                                class="btn btn-sm btn-primary"
                            >
                                <i class="fas fa-pen d-inline d-sm-none"></i>
                                <span class="d-none d-sm-inline">Edit</span>
                            </button>
                            <button
                                @click="this.$parent.showDeleteModal(item.id, $route.params.widget_id)"
                                type="button"
                                class="btn btn-sm btn-danger"
                            >
                                <i class="bi bi-trash d-sm-none"></i>
                                <span class="d-none d-sm-inline">Delete</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav aria-label="Page navigation" v-if="items && items.length < 0">
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: !pagination.prev_page_url }">
                    <button class="page-link" @click="changePage(pagination.current_page - 1)">Previous</button>
                </li>
                <li
                    class="page-item"
                    v-for="page in pagination.last_page"
                    :key="page"
                    :class="{ active: pagination.current_page === page }"
                >
                    <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: !pagination.next_page_url }">
                    <button class="page-link" @click="changePage(pagination.current_page + 1)">Next</button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    components: {},

    props: {
        items: {
            type: Array,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
    },
    methods: {
        truncate(text, length = 20) {
            if (!text) return "";
            return text.length > length ? text.substring(0, length) + "..." : text;
        },
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString();
        },
        publish_date_formatted(date) {
            const dateObject = new Date(date);
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return dateObject.toLocaleString("en-US", options);
        },
        changePage(page) {
            if (page > 0 && page <= this.pagination.last_page) {
                this.$parent.changePage(page);
            }
        },
    },
    mounted() {},
};
</script>

<style scoped></style>
