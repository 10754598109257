<template>
    <div
        class="modal fade"
        id="projectWidgetsModal"
        tabindex="-1"
        aria-labelledby="projectWidgetsModalLabel"
        aria-hidden="true"
        ref="projectWidgetsModal"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="projectWidgetsModalLabel">Project Widgets</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        @click="closeModal"
                        :disabled="saving"
                    ></button>
                </div>
                <div class="modal-body">
                    <div v-if="loadingProject" class="d-flex justify-content-center my-3">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="availableWidgets.length > 0" class="row">
                            <SelectableCard
                                v-for="widget in availableWidgets"
                                :key="widget.id"
                                :item="widget"
                                v-model:selected="selectedWidgetIds"
                                :multiple="true"
                                :truncateLimit="20"
                            />
                        </div>
                        <div v-else class="text-center my-3">
                            <p class="mb-3 d-inline-block">No widgets added yet.</p>
                            <button class="btn btn-primary btn-sm ms-2" @click="goToWidgets">Add Widgets</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="!loadingProject">
                    <button type="button" class="btn btn-secondary" @click="closeModal" :disabled="saving">
                        Cancel
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="saveSelection"
                        :disabled="saving"
                        v-if="availableWidgets.length > 0"
                    >
                        <span v-if="saving" class="spinner-border spinner-border-sm me-1" role="status"></span>
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import SelectableCard from "@/components/SelectableCard.vue";

export default {
    name: "ProjectWidgetsModal",
    components: { SelectableCard },
    data() {
        return {
            projectId: null,
            availableWidgets: [],
            selectedWidgetIds: [],
            loadingProject: false,
            saving: false,
            token: this.$store.getters.logged_in_user.data.token || "",
        };
    },
    methods: {
        async fetchProjectWidgets() {
            this.loadingProject = true;
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}projects/read/${this.projectId}`, {
                    headers: { Authorization: "Bearer " + this.token },
                });
                const projectData = response.data;
                this.availableWidgets = projectData.widgets || [];
                this.selectedWidgetIds = this.availableWidgets.map((widget) => widget.id);
            } catch (error) {
                console.error("Error fetching project details:", error);
            } finally {
                this.loadingProject = false;
            }
        },
        async saveSelection() {
            if (!this.projectId) return;
            this.saving = true;
            try {
                const payload = {
                    widget_ids: this.selectedWidgetIds,
                };
                const response = await axios.put(
                    `${process.env.VUE_APP_API_URL}projects/updateWidgets/${this.projectId}`,
                    payload,
                    { headers: { Authorization: "Bearer " + this.token } }
                );
                console.log("Project widgets updated:", response.data);
                this.closeModal();
                this.$emit("project-widgets-updated", response.data);
            } catch (error) {
                console.error("Error updating project widgets:", error);
            } finally {
                this.saving = false;
            }
        },
        async show(projectId) {
            this.projectId = projectId;
            this.selectedWidgetIds = [];
            const modalInstance = new Modal(this.$refs.projectWidgetsModal);
            modalInstance.show();
            await this.fetchProjectWidgets();
        },
        closeModal() {
            const modalInstance = Modal.getInstance(this.$refs.projectWidgetsModal);
            if (modalInstance) {
                modalInstance.hide();
            }
        },
        goToWidgets() {
            this.closeModal();
            this.$router.push({ name: "Widgets" });
        },
    },
};
</script>
