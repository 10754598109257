<template>
    <!-- delete modal -->
    <div class="modal" id="delete_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                    <button @click="deleteRecord($event)" type="button" class="btn btn-primary">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            widget_id: this.$route.params.widget_id,
            id: "",
            name: "",
            email: "",
            access_level: "",
            delete_modal: null,
        };
    },
    methods: {
        async showModal(event, id) {
            this.id = id;
            this.delete_modal.show();
        },
        async deleteRecord(event) {
            event.target.disabled = true;

            axios
                .delete(
                    process.env.VUE_APP_API_URL +
                        "onboarding-checklist-items/" +
                        this.id +
                        "?widget_id=" +
                        this.widget_id,
                    {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    }
                )
                .then(
                    (response) => {
                        if (response.data.success == true) {
                            this.$parent.$refs.readComponent.read();
                        }

                        event.target.disabled = false;
                        this.delete_modal.hide();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            this.$parent.fetchItems();
            this.$parent.updatePreview();
        },
    },
    mounted() {
        this.delete_modal = new Modal(document.getElementById("delete_modal"));
    },
};
</script>
