<template>
    <div class="total-analytics-container">
        <button @click="toggleVisibility" class="btn btn-primary mb-3">
            {{ isVisible ? "Hide Breakdown" : "Show Breakdown" }}
        </button>

        <div v-if="isVisible && totalAnalytics">
            <div class="analytics-summary">
                <div v-if="showViews" class="summary-item">
                    <strong>This Year Views:</strong> {{ totalAnalytics.this_year.views }} |
                    <strong>This Month Views:</strong> {{ totalAnalytics.this_month.views }} |
                    <strong>Today Views:</strong> {{ totalAnalytics.today.views }}
                </div>
                <div v-if="showClicks" class="summary-item">
                    <strong>This Year Clicks:</strong> {{ totalAnalytics.this_year.clicks }} |
                    <strong>This Month Clicks:</strong> {{ totalAnalytics.this_month.clicks }} |
                    <strong>Today Clicks:</strong> {{ totalAnalytics.today.clicks }}
                </div>
                <div v-if="showEmails" class="summary-item">
                    <strong>This Year Emails:</strong> {{ totalAnalytics.this_year.emails }} |
                    <strong>This Month Emails:</strong> {{ totalAnalytics.this_month.emails }} |
                    <strong>Today Emails:</strong> {{ totalAnalytics.today.emails }}
                </div>
            </div>
        </div>

        <div v-if="isLoading" class="spinner-container">
            <div class="spinner" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            analytics_url: process.env.VUE_APP_ANALYTICS_URL,
            isVisible: false,
            isLoading: false,
            totalAnalytics: null,
        };
    },
    props: {
        widgetId: {
            type: String,
            required: true,
        },
        showViews: {
            type: Boolean,
            default: true,
        },
        showClicks: {
            type: Boolean,
            default: true,
        },
        showEmails: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        toggleVisibility() {
            this.isVisible = !this.isVisible;
            if (this.isVisible && !this.totalAnalytics) {
                this.fetchTotalAnalytics();
            }
        },
        async fetchTotalAnalytics() {
            this.isLoading = true;
            try {
                const response = await fetch(`${this.analytics_url}widget-analytics/${this.widgetId}/total`);
                const data = await response.json();
                this.totalAnalytics = data.totals;
            } catch (error) {
                console.error("Error fetching total analytics:", error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.total-analytics-container {
    font-size: 0.875rem;
}

.spinner-container {
    text-align: center;
    padding: 10px;
}

button {
    font-size: 0.875rem;
}

.analytics-summary {
    font-size: 0.875rem;
}

.summary-item {
    margin-bottom: 0.5rem;
}

.summary-item strong {
    margin-right: 0.5rem;
}
</style>
