<template>
    <button class="btn btn-primary fs-5 d-flex justify-content-center align-items-center" @click="showModal">
        View Emails
    </button>
    <div class="modal" tabindex="-1" id="emailsModal" ref="emailsModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title d-flex align-items-center">
                        Email Signup Form Submissions
                        <span class="badge rounded-pill bg-secondary ms-2">{{ emails.total }}</span>
                    </h5>
                    <div class="ms-auto d-flex">
                        <button class="btn btn-success btn-sm me-2" @click="download" :disabled="downloading">
                            <div v-if="downloading" class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <i v-else class="bi bi-download"></i> Download
                        </button>
                        <button class="btn btn-info btn-sm" @click="json" :disabled="processing">
                            <div v-if="processing" class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                            <i v-else class="bi bi-code-slash"></i> JSON Feed
                        </button>
                    </div>
                    <button type="button" class="btn-close ms-2" @click="hideModal"></button>
                </div>
                <div v-if="loading" class="d-flex justify-content-center align-items-center" style="min-height: 100px">
                    <div class="spinner"></div>
                </div>
                <div class="modal-body" v-else>
                    <EmailSignupFormTable
                        :emails="emails"
                        :paginationLoadingIndex="paginationLoadingIndex"
                        @page-changed="fetchEmails"
                        @delete-email="handleDeleteEmail"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmailSignupFormTable from "./EmailSignupFormTable.vue";
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    components: {
        EmailSignupFormTable,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            loading: true,
            downloading: false,
            processing: false,
            emails: [],
            modalInstance: null,
            widget_id: this.$route.params.widget_id,
            paginationLoadingIndex: null,
        };
    },
    methods: {
        async fetchEmails(page = 1) {
            this.paginationLoadingIndex = page;

            const sortBy = "created_at";
            const sortDirection = "desc";

            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API_URL}email-signup-forms/${this.widget_id}/emails?page=${page}&widget_id=${this.widget_id}&sort_by=${sortBy}&sort_direction=${sortDirection}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                );
                this.emails = response.data;
            } catch (error) {
                console.error("Error fetching emails:", error);
            } finally {
                this.loading = false;
                this.paginationLoadingIndex = null;
            }
        },
        async handleDeleteEmail(emailId) {
            try {
                await axios.delete(
                    `${process.env.VUE_APP_API_URL}email-signup-forms/emails/${emailId}?widget_id=${this.widget_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                );
                await this.fetchEmails(this.pagination.current_page);
            } catch (error) {
                console.error("Error deleting email:", error.response ? error.response.data : error.message);
            } finally {
                this.loading = false;
            }
        },
        showModal() {
            this.modalInstance = new Modal(this.$refs.emailsModal);
            this.modalInstance.show();
            this.fetchEmails();
        },
        hideModal() {
            if (this.modalInstance) {
                this.modalInstance.hide();
            }
        },
        async download() {
            this.downloading = true;

            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API_URL}email-signup-forms/emails/${this.widget_id}/download`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            Accept: "text/csv",
                        },
                        responseType: "blob",
                    }
                );

                const blob = new Blob([response.data], { type: "text/csv" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = `${this.widget_id}.csv`;
                link.click();
            } catch (error) {
                console.error("Error downloading emails as CSV:", error);
            } finally {
                this.downloading = false;
            }
        },
        async json() {
            this.processing = true;

            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}email-signup-forms/emails/${this.widget_id}/save`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.status === 200) {
                    window.open(
                        `${process.env.VUE_APP_FILE_SERVER_URL}email-signup-forms/${this.widget_id}_list.json`,
                        "_blank"
                    );
                }
            } catch (error) {
                console.error("Error processing emails:", error);
            } finally {
                this.processing = false;
            }
        },
    },
};
</script>

<style scoped></style>
