<template>
    <div
        class="modal fade"
        id="analyticsModal"
        tabindex="-1"
        aria-labelledby="analyticsModalLabel"
        aria-hidden="true"
        ref="analyticsModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="analyticsModalLabel">
                        Analytics - {{ widgetType }} Widget - {{ widgetName }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="filter-options mb-4">
                        <div class="filter-item">
                            <VueDatePicker
                                id="dateRange"
                                v-model="dateRange"
                                range
                                :format="formatDate"
                                @update:model-value="confirmDateSelection"
                                placeholder="Select a date range"
                                multi-calendars
                            />
                        </div>

                        <div class="filter-item">
                            <TotalAnalytics
                                :widgetId="widget_id"
                                :show-views="showViews"
                                :show-clicks="showClicks"
                                :show-emails="showEmails"
                            />
                        </div>
                    </div>

                    <div v-if="isLoading" class="spinner-container">
                        <div class="spinner" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div v-if="!isLoading">
                        <div v-if="activeChartCount > 1" class="form-check">
                            <label class="form-check-label">
                                <input type="checkbox" v-model="showSeparateGraphs" class="form-check-input" />
                                Show Separate Bar Graphs
                            </label>
                        </div>

                        <div v-if="!showSeparateGraphs" class="chart-container">
                            <Bar :chart-data="combinedChartData" :options="chartOptions" />
                        </div>

                        <div v-else class="chart-container row">
                            <div v-if="showViews" :class="['mb-3', `col-md-${12 / activeChartCount}`]">
                                <h6>Views</h6>
                                <Bar :chart-data="viewsChartData" :options="chartOptions" />
                            </div>

                            <div v-if="showClicks" :class="['mb-3', `col-md-${12 / activeChartCount}`]">
                                <h6>Clicks</h6>
                                <Bar :chart-data="clicksChartData" :options="chartOptions" />
                            </div>

                            <div v-if="showEmails" :class="['mb-3', `col-md-${12 / activeChartCount}`]">
                                <h6>Emails</h6>
                                <Bar :chart-data="emailsChartData" :options="chartOptions" />
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive" v-if="paginatedData.length">
                        <table class="table table-striped mb-0">
                            <colgroup>
                                <col style="width: 18%" />
                                <col style="width: 57%" />
                                <col style="width: 12%" />
                                <col style="width: 13%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th class="sort">URL <i class="gg-select float-end"></i></th>
                                    <th class="sort">Views <i class="gg-select float-end"></i></th>
                                    <th class="sort">Clicks <i class="gg-select float-end"></i></th>
                                </tr>
                            </thead>
                            <tbody class="fs-sm">
                                <tr v-for="(item, index) in paginatedData" :key="index">
                                    <td scope="row">{{ formatDateTime(item.day) }}</td>
                                    <td>
                                        <a :href="item.url" target="_blank">{{ item.url }}</a>
                                    </td>
                                    <td>{{ formatNumber(item.total_views) }}</td>
                                    <td>{{ formatNumber(item.total_clicks) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div
                        class="pagination-container d-flex justify-content-end align-items-center gap-2 mt-3"
                        v-if="paginatedData.length"
                    >
                        <button class="btn btn-outline-secondary" @click="prevPage" :disabled="currentPage === 1">
                            Previous
                        </button>

                        <button
                            v-for="page in totalPages"
                            :key="page"
                            @click="goToPage(page)"
                            :class="[
                                'btn',
                                'pagination-btn',
                                page === currentPage ? 'btn-primary' : 'btn-outline-secondary',
                            ]"
                        >
                            {{ page }}
                        </button>

                        <button
                            class="btn btn-outline-secondary"
                            @click="nextPage"
                            :disabled="currentPage === totalPages"
                        >
                            Next
                        </button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import TotalAnalytics from "./TotalAnalytics";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
ChartJS.defaults.font.family = "Work Sans, sans-serif";

export default {
    components: {
        Bar,
        TotalAnalytics,
    },
    props: {
        widgetType: {
            type: String,
            required: true,
        },
        showViews: {
            type: Boolean,
            default: true,
        },
        showClicks: {
            type: Boolean,
            default: true,
        },
        showEmails: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            widget_id: this.$route.params.widget_id,
            analytics_url: process.env.VUE_APP_ANALYTICS_URL,
            widgetName: "",
            dateRange: [],
            showSeparateGraphs: true,
            isLoading: true,
            analyticsData: null,
            mergedData: [],
            currentPage: 1,
            itemsPerPage: 10,
            combinedChartData: {
                labels: [],
                datasets: [
                    {
                        label: "Total Views",
                        data: [],
                        backgroundColor: "#42A5F5",
                        borderRadius: 3,
                    },
                    {
                        label: "Total Clicks",
                        data: [],
                        backgroundColor: "#66BB6A",
                        borderRadius: 3,
                    },
                    {
                        label: "Total Emails",
                        data: [],
                        backgroundColor: "#FF7043",
                        borderRadius: 3,
                    },
                ],
            },
            viewsChartData: {
                labels: [],
                datasets: [
                    {
                        label: "Views",
                        data: [],
                        backgroundColor: "#42A5F5",
                        borderRadius: 3,
                    },
                ],
            },
            clicksChartData: {
                labels: [],
                datasets: [
                    {
                        label: "Clicks",
                        data: [],
                        backgroundColor: "#66BB6A",
                        borderRadius: 3,
                    },
                ],
            },
            emailsChartData: {
                labels: [],
                datasets: [
                    {
                        label: "Emails",
                        data: [],
                        backgroundColor: "#FF7043",
                        borderRadius: 3,
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: "top",
                        labels: {
                            font: { size: 18 },
                        },
                    },
                    title: {
                        display: true,
                        text: "Widget Analytics",
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            callback: function (value) {
                                return Math.round(value);
                            },
                        },
                    },
                },
            },
        };
    },
    computed: {
        activeChartCount() {
            let count = 0;
            if (this.showViews) count++;
            if (this.showClicks) count++;
            if (this.showEmails) count++;
            return count || 1;
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.mergedData.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.mergedData.length / this.itemsPerPage);
        },
    },
    mounted() {
        this.modalInstance = new Modal(this.$refs.analyticsModal);
        this.initializeDates();
    },
    methods: {
        initializeDates() {
            const today = new Date();
            const startDate = new Date(today);
            startDate.setDate(today.getDate() - 6);

            today.setDate(today.getDate() + 1);

            this.dateRange = [startDate.toISOString().split("T")[0], today.toISOString().split("T")[0]];
        },
        showModal(widgetName = "") {
            this.fetchAnalyticsData();
            this.widgetName = widgetName;
            this.modalInstance.show();
        },
        confirmDateSelection() {
            if (this.dateRange.length === 2) {
                this.updateAnalytics();
            }
        },
        updateAnalytics() {
            if (this.dateRange.length === 2) {
                this.startDate = this.dateRange[0];
                this.endDate = this.dateRange[1];
                this.fetchAnalyticsData();
            }
        },
        async fetchAnalyticsData() {
            this.isLoading = true;

            if (this.dateRange.length !== 2) {
                console.error("Invalid date range.");
                return;
            }

            let [startDate, endDate] = this.dateRange;

            startDate = new Date(startDate);
            endDate = new Date(endDate);

            if (isNaN(startDate) || isNaN(endDate)) {
                console.error("Invalid dates");
                return;
            }

            const formattedStartDate = startDate.toISOString().split("T")[0];
            const formattedEndDate = endDate.toISOString().split("T")[0];

            let url = `${this.analytics_url}widget-analytics/${this.widget_id}?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

            try {
                const response = await fetch(url);
                const data = await response.json();

                this.analyticsData = data;

                this.mergedData = data.merged_data;

                const labels = data.views.map((item) => item.day);
                const viewsData = data.views.map((item) => item.total_views);
                const clicksData = data.clicks.map((item) => item.total_clicks);
                const emailsData = data.emails.map((item) => item.total_emails);

                this.combinedChartData.labels = labels;
                const combinedDatasets = [];
                if (this.showViews) {
                    combinedDatasets.push({
                        label: "Total Views",
                        data: viewsData,
                        backgroundColor: "#42A5F5",
                        borderRadius: 3,
                    });
                }
                if (this.showClicks) {
                    combinedDatasets.push({
                        label: "Total Clicks",
                        data: clicksData,
                        backgroundColor: "#66BB6A",
                        borderRadius: 3,
                    });
                }
                if (this.showEmails) {
                    combinedDatasets.push({
                        label: "Total Emails",
                        data: emailsData,
                        backgroundColor: "#FF7043",
                        borderRadius: 3,
                    });
                }
                this.combinedChartData.datasets = combinedDatasets;

                this.viewsChartData.labels = labels;
                this.viewsChartData.datasets[0].data = viewsData;

                this.clicksChartData.labels = labels;
                this.clicksChartData.datasets[0].data = clicksData;

                if (emailsData.length > 0) {
                    this.emailsChartData.labels = labels;
                    this.emailsChartData.datasets[0].data = emailsData;
                }
            } catch (error) {
                console.error("Error fetching data from API:", error);
            } finally {
                this.isLoading = false;
            }
        },
        generateFakeData(numEntries) {
            const fakeData = { views: [], clicks: [] };
            for (let i = 0; i < numEntries; i++) {
                const day = new Date();
                day.setDate(day.getDate() - i);
                fakeData.views.push({
                    day: day.toISOString().split("T")[0],
                    total_views: Math.floor(Math.random() * 1000),
                });
                fakeData.clicks.push({
                    day: day.toISOString().split("T")[0],
                    total_clicks: Math.floor(Math.random() * 500),
                });
            }
            return fakeData;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        goToPage(page) {
            this.currentPage = page;
        },
        formatDateTime(dateString) {
            const options = { year: "numeric", month: "long", day: "2-digit" };
            return new Date(dateString).toLocaleDateString("en-US", options);
        },
        formatNumber(number) {
            return parseInt(number).toLocaleString();
        },
        formatDate(date) {
            if (!date || !Array.isArray(date) || date.length !== 2) return "";
            const options = { year: "numeric", month: "long", day: "numeric" };
            return `${date[0].toLocaleDateString("en-US", options)} to ${date[1].toLocaleDateString("en-US", options)}`;
        }
    },
};
</script>

<style scoped>
.modal-dialog {
    max-width: 1400px;
}

.modal-content {
    color: #000;
}

.filter-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.filter-item {
    display: flex;
    flex-direction: column;
}

@media (max-width: 767px) {
    .filter-options {
        grid-template-columns: 1fr;
    }
}

select,
input[type="date"] {
    padding: 0.5rem;
}

.chart-container {
    width: 100%;
    margin-top: 1rem;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
</style>
