<template>
    <Create :create_modal="create_modal" :widget_types="widget_types" />
    <Read ref="readComponent" />
    <Delete ref="deleteComponent" />
    <SelectProject ref="selectProjectComponent" />
    <ProjectWidgets ref="projectWidgetsComponent" @project-widgets-updated="handleWidgetsUpdated" />
    <Prompt ref="promptComponent" />
</template>

<script>
import Create from "./Create";
import Read from "./Read";
import Delete from "./Delete";
import { Modal } from "bootstrap";
import store from "../../../store";
import SelectProject from "./SelectProject.vue";
import ProjectWidgets from "../../../components/ProjectWidgets.vue";
import Prompt from "./Prompt.vue";

export default {
    components: {
        Create,
        Delete,
        Read,
        SelectProject,
        ProjectWidgets,
        Prompt,
    },
    data() {
        return {
            create_widget_modal: null,
            widget_types: [],
        };
    },
    methods: {
        showCreateModal() {
            this.create_modal.show();
            document.getElementById("widget_type_id").selectedIndex = 0;
        },
        async readWidgetTypes(event, url) {
            if (event) {
                event.target.disabled = true;
            }

            if (!url) {
                url = process.env.VUE_APP_API_URL + "widget-types/all";
            }

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            this.widget_types = await res.json();

            if (event) {
                event.target.disabled = false;
            }
        },
        handleWidgetsUpdated() {
            if (this.$refs.readComponent) {
                this.$refs.readComponent.read();
            }
        },
    },
    mounted() {
        this.readWidgetTypes();
        this.create_modal = new Modal(document.getElementById("create_widget_modal"));
    },
};
</script>
