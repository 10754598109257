<template>
    <div class="col-sm-9">
        <div class="d-flex flex-wrap justify-content-between mb-3 flex-column flex-sm-row align-items-lg-center gap">
            <h4 class="me-auto d-flex align-items-center">
                Users
                <span class="badge rounded-pill bg-secondary ms-2">{{ data.total }}</span>
            </h4>

            <button
                data-bs-toggle="modal"
                @click="$parent.$refs.createComponent.showCreateModal()"
                type="button"
                class="btn btn-primary d-flex align-items-center gap-2"
            >
                <i class="bi bi-plus-lg"></i> Add User
            </button>

            <select v-model="selectedStatus" @change="filterByStatus" class="form-select w-auto">
                <option value="">Filter by Status</option>
                <option value="1">Paid</option>
                <option value="13">Free Plans</option>
                <option value="6">Premium Trials</option>
                <option value="7">To Cancel</option>
                <option value="0">Cancelled</option>
                <option value="9">Unpaid</option>
                <option value="8">Refunded</option>
                <option value="2">Suspended</option>
                <option value="10">Deleted</option>
            </select>

            <form @submit.prevent="search(event)">
                <div class="input-group float-end">
                    <input
                        v-model="searchKeyword"
                        type="text"
                        class="form-control"
                        placeholder="Type any word..."
                        aria-label="Search"
                        aria-describedby="button-addon2"
                    />
                    <button class="btn btn-secondary" type="submit" id="button-addon2">
                        <i class="bi bi-search"></i>
                    </button>
                </div>
            </form>
        </div>

        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>
        <div class="table-responsive bg-white p-20 rounded" v-else>
            <table class="table table-hovered align-middle table-fixed">
                <colgroup>
                    <col style="width: 30%" />
                    <col style="width: 20%" />
                    <col style="width: 15%" />
                    <col style="width: 20%" />
                    <col style="width: 15%" />
                </colgroup>

                <thead>
                    <tr>
                        <th class="small">Name</th>
                        <th class="small">Recent Widgets</th>
                        <th class="small">Access</th>
                        <th class="small">Date Added</th>
                        <th class="small">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data.data" :key="item.id">
                        <td>
                            <div class="d-flex align-items-center">
                                <template v-if="item.profile_picture">
                                    <img
                                        :src="`${fileServerUrl}${item.profile_picture}?nocache=${Date.now()}`"
                                        alt="Profile"
                                        class="rounded-circle me-2"
                                        width="40"
                                        height="40"
                                    />
                                </template>
                                <i v-else class="bi bi-person-circle text-muted me-2" style="font-size: 2rem"></i>

                                <div>
                                    <div class="fw-semibold">{{ item.name }}</div>
                                    <div class="text-muted small">{{ item.email }}</div>
                                </div>
                            </div>
                        </td>

                        <td>
                            <div v-if="item.widgets.length > 0" class="d-flex flex-column">
                                <a
                                    v-for="widget_item in item.widgets.slice(0, 3)"
                                    :key="widget_item.id"
                                    class="text-muted small text-decoration-none"
                                    :href="getWidgetRoute(widget_item.widget_type_id, widget_item.id)"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    :title="widget_item.widget_type.name"
                                >
                                    {{ widget_item.widget_type.name }}
                                </a>

                                <button
                                    v-if="item.widgets.length > 3"
                                    class="btn btn-sm btn-link text-muted p-0"
                                    @click="showMoreWidgets(item.id)"
                                >
                                    +{{ item.widgets.length - 3 }} more
                                </button>
                            </div>

                            <div v-else class="text-muted small">-</div>
                        </td>

                        <td>
                            <span
                                class="badge rounded-pill text-uppercase fw-normal"
                                :class="getAccessBadgeClass(item.access_level)"
                            >
                                {{ item.access_level }}
                            </span>
                        </td>

                        <td>
                            <div class="text-muted small">{{ item.created_at }}</div>
                        </td>

                        <td>
                            <div class="dropdown">
                                <button
                                    class="btn btn-light border"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i class="bi bi-three-dots-vertical"></i>
                                </button>
                                <ul class="dropdown-menu shadow-sm border-0">
                                    <li>
                                        <a
                                            class="dropdown-item d-flex align-items-center gap-2"
                                            href="#"
                                            @click.prevent="
                                                $parent.$refs.updateComponent.showEditModal($event, item.id)
                                            "
                                        >
                                            <i class="fas fa-pen text-primary"></i> Edit
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item d-flex align-items-center gap-2"
                                            href="#"
                                            @click.prevent="
                                                $parent.$refs.changePasswordComponent.showChangePasswordModal(
                                                    $event,
                                                    item.id
                                                )
                                            "
                                        >
                                            <i class="fas fa-key text-warning"></i> Change Password
                                        </a>
                                    </li>
                                    <li>
                                        <hr class="dropdown-divider" />
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item d-flex align-items-center gap-2 text-danger"
                                            href="#"
                                            @click.prevent="$parent.$refs.deleteComponent.showDeleteModal(item.id)"
                                        >
                                            <i class="bi bi-trash"></i> Delete
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation example" v-if="data.data && data.data.length > 0">
                <ul class="pagination justify-content-center flex-wrap gap-1">
                    <span v-for="(item, index) in data.links" :key="item.url + item.label" class="me-1">
                        <li :class="{ active: item.active }" class="page-item">
                            <a
                                v-if="item.url && !(paginationLoadingIndex === index)"
                                @click="handlePageClick(item, index)"
                                class="page-link"
                            >
                                <span v-html="item.label"></span>
                            </a>
                            <span v-else-if="paginationLoadingIndex === index" class="page-link">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </span>
                        </li>
                    </span>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import store from "../../../store";

export default {
    data() {
        return {
            loading: true,
            data: {},
            searchKeyword: "",
            paginationLoadingIndex: null,
            previousUrl: null,
            fileServerUrl: "",
            selectedStatus: "",
            page: 1,
        };
    },
    watch: {
        "$route.query": {
            handler() {
                this.read();
            },
            deep: true,
            immediate: true,
        },
        searchKeyword(newSearch) {
            if (newSearch === "") {
                this.search();
            }
        },
    },
    methods: {
        async search() {
            this.loading = true;
            this.$router.push({
                query: {
                    ...this.$route.query,
                    search: this.searchKeyword ?? undefined,
                    page: undefined,
                },
            });
        },

        async filterByStatus() {
            this.loading = true;

            this.$router.push({
                query: {
                    ...this.$route.query,
                    status: this.selectedStatus ?? undefined,
                    page: undefined,
                },
            });
        },

        handlePageClick(item, index) {
            this.paginationLoadingIndex = index;
            const pageNumber = this.getPageNumberFromUrl(item.url);
            this.page = pageNumber;

            this.$router.push({
                query: {
                    ...this.$route.query,
                    status: this.selectedStatus ?? undefined,
                    search: this.searchKeyword ?? undefined,
                    page: this.page ?? 1,
                },
            });
        },

        getPageNumberFromUrl(url) {
            const urlParams = new URLSearchParams(url.split("?")[1]);
            return parseInt(urlParams.get("page")) || 1;
        },

        async read() {
            const params = new URLSearchParams(this.$route.query).toString();
            const url = `${process.env.VUE_APP_API_URL}users/read?${params}`;

            try {
                const res = await fetch(url, {
                    headers: new Headers({
                        Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                    }),
                });

                this.data = await res.json();
                window.scrollTo(0, 0);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.paginationLoadingIndex = null;
                this.loading = false;
            }
        },

        getWidgetRoute(widgetTypeId, widgetId) {
            const routes = {
                1: `/app/update-widget/tooltips/${widgetId}/`,
                2: `/app/update-widget/onboarding-checklist-items/${widgetId}/`,
                3: `/app/update-widget/quick-feedback/${widgetId}/`,
                4: `/app/update-widget/product-updates/${widgetId}/`,
                5: `/app/update-widget/announcements/${widgetId}/`,
                6: `/app/update-widget/social-proofs/${widgetId}/`,
                7: `/app/update-widget/vote-button/${widgetId}/`,
                11: `/app/update-widget/featured-articles/${widgetId}/`,
                12: `/app/update-widget/exit-intent-popup/${widgetId}/`,
                13: `/app/update-widget/back-to-top-button/${widgetId}/`,
                14: `/app/update-widget/product-tour/${widgetId}/`,
                15: `/app/update-widget/changelog/${widgetId}/`,
                16: `/app/update-widget/social-share-button/${widgetId}/`,
                17: `/app/update-widget/cookie-consent/${widgetId}/`,
                18: `/app/update-widget/cancellation-form/${widgetId}/`,
                19: `/app/update-widget/faq/${widgetId}/`,
                20: `/app/update-widget/table-of-contents/${widgetId}/`,
                21: `/app/update-widget/email-signup-form/${widgetId}/`,
                22: `/app/update-widget/pricing-table/${widgetId}/`,
            };

            return routes[widgetTypeId] || null;
        },
        getAccessBadgeClass(accessLevel) {
            const level = accessLevel.toLowerCase();
            const classes = {
                admin: "admin",
                customer: "customer",
                support: "support",
            };
            return classes[level] || "customer";
        },
    },
    mounted() {
        this.fileServerUrl = process.env.VUE_APP_FILE_SERVER_URL;

        this.searchKeyword = this.$route.query.search || "";
        this.selectedStatus = this.$route.query.status || "";
        this.page = this.$route.query.page || 1;
    },
};
</script>

<style scoped>
.admin {
    background: linear-gradient(90deg, #b22222, #8b0000);
    background-size: 200% 100%;
    background-position: 100% 0;
    color: white;
}

.support {
    background: linear-gradient(90deg, #008080, #005f5f);
    background-size: 200% 100%;
    background-position: 100% 0;
    color: white;
}

.customer {
    background: linear-gradient(90deg, #ffd700, #f2a900);
    background-size: 200% 100%;
    background-position: 100% 0;
    color: white;
}

.table-fixed {
    table-layout: fixed;
    width: 100%;
}

@media (max-width: 768px) {
    .table-fixed {
        table-layout: auto;
    }
}
</style>
