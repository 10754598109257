import { createRouter, createWebHistory } from 'vue-router'
import store from "../store";
import Login from '../views/Login'
import GoogleAuth from '../views/GoogleAuth'
import Signup from '../views/Signup'
import Forgot from '../views/Forgot'
import Reset from '../views/Reset'
import Users from '../views/admin/users/Users'
import ActivityTypes from '../views/admin/activity_types/ActivityTypes'
import WidgetCategories from '../views/admin/widget_categories/WidgetCategories'
import Plans from '../views/admin/plans/Plans'
import PlanSettings from '../views/admin/plan_settings/PlanSettings'
import WidgetTypes from '../views/admin/widget_types/WidgetTypes'
import WidgetList from '../views/admin/widget_list/WidgetList'
import Cache from '../views/admin/content/Cache'
import CannedResponses from '../views/admin/canned_responses/CannedResponses'
import Invoices from '../views/admin/invoices/Invoices'
import Stats from '../views/admin/stats/Stats'
import Settings from '../views/admin/settings/Settings'
import Widgets from '../views/users/widgets/Widgets'
import Projects from '../views/users/projects/Projects.vue';
import CreateWidget from '../views/users/widgets/CreateWidget'
import Tooltips from '../views/users/tooltips/Tooltips'
import OnboardingChecklistItems from '../views/users/onboarding-checklist-items/OnboardingChecklistItems'
import QuickFeedback from '../views/users/quick-feedback/QuickFeedback'
import SocialProof from '../views/users/social-proofs/SocialProof'
import VoteButton from '../views/users/vote-button/VoteButton'
import ProductUpdates from '../views/users/product-updates/ProductUpdates'
import Announcements from '../views/users/announcements/Announcements'
import BackToTopButton from '../views/users/back-to-top-button/BackToTopButton'
import CookieConsent from '../views/users/cookie-consent/CookieConsent'
import ExitIntentPopup from '../views/users/exit-intent-popup/ExitIntentPopup'
import FeaturedArticles from '../views/users/featured-articles/FeaturedArticles'
import Changelog from '../views/users/changelog/Changelog'
import ProductTour from '../views/users/product-tour/ProductTour'
import SocialShareButton from '../views/users/social-share-button/SocialShareButton'
import CancellationForm from '../views/users/cancellation-form/CancellationForm'
import FAQ from '../views/users/faq/FAQ'
import TableOfContents from '../views/users/table-of-contents/TableOfContents'
import EmailSignupForm from '../views/users/email-signup-form/EmailSignupForm'
import PricingTable from '../views/users/pricing-table/PricingTable'
import Profile from '../views/Profile'
import ChangePassword from '../views/ChangePassword'
import Billing from "../views/users/billing/Billing"
import ChangePlan from '../views/ChangePlan'
import ExploreMoreWidgets from '../views/ExploreMoreWidgets'
import Activities from '../views/admin/activities/Activities.vue';
import ProductUpdate from '../views/admin/product_updates/ProductUpdate.vue';
import AddOns from '../views/AddOns.vue';

const OwnWidgetMiddleware = async (to, from, next) => {
    const token = store?.getters?.logged_in_user?.data?.token ?? "";
    const userId = store?.getters?.logged_in_user?.data?.user?.id ?? 12;
    const widgetId = to.params.widget_id;

    try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}widgets/${userId}/${widgetId}?widget_id=${widgetId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.ok) {
            return next();
        } else if (response.status === 403) {
            return next({ name: 'CreateWidget' });
        } else {
            return next({ name: 'Login' });
        }
    } catch (error) {
        console.error('Error in OwnWidgetMiddleware:', error);
        return next({ name: 'Login' });
    }
};

const routes = [
    {
        path: '/', name: 'Home',
        meta: { login_needed: true }
    },
    {
        path: '/google-auth/', name: 'GoogleAuth', component: GoogleAuth,
        meta: { title: 'Google Authentication', login_needed: false }
    },
    {
        path: '/login/', name: 'Login', component: Login,
        meta: { title: 'Login', login_needed: false }
    },
    {
        path: '/signup/', name: 'Signup', component: Signup,
        meta: { title: 'Signup', login_needed: false }
    },
    {
        path: '/forgot/', name: 'Forgot', component: Forgot,
        meta: { title: 'Forgot', login_needed: false }
    },
    {
        path: '/reset-password/:key/', name: 'Reset', component: Reset,
        meta: { title: 'Reset Password', login_needed: false }
    },
    {
        path: '/admin/canned-responses/', name: 'CannedResponses', component: CannedResponses,
        meta: { title: 'Canned Responses', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/admin-settings/', name: 'Settings', component: Settings,
        meta: { title: 'Admin Settings', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/widget-categories/', name: 'WidgetCategories', component: WidgetCategories,
        meta: { title: 'Widget Categories', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/activities/', name: 'Activities', component: Activities,
        meta: { title: 'Activities', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/activity-types/', name: 'ActivityTypes', component: ActivityTypes,
        meta: { title: 'Activity Types', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/plans/:status/', name: 'Plans', component: Plans,
        meta: { title: 'Plans', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/plans/settings', name: 'PlanSettings', component: PlanSettings,
        meta: { title: 'Plan Settings', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/users/', name: 'Users', component: Users,
        meta: { title: 'Users', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/widget-types/', name: 'WidgetTypes', component: WidgetTypes,
        meta: { title: 'Widget Types', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/widget-list/', name: 'WidgetList', component: WidgetList,
        meta: { title: 'Widget List', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/stats/', name: 'Stats', component: Stats,
        meta: { title: 'Widget Stats', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/product-updates/', name: 'ProductUpdate', component: ProductUpdate,
        meta: { title: 'Product Updates', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/cache/', name: 'Cache', component: Cache,
        meta: { title: 'Cache', login_needed: true, admin: true, support: true }
    },
    {
        path: '/admin/invoices/', name: 'Invoices', component: Invoices,
        meta: { title: 'Invoices', login_needed: true, admin: true, support: true }
    },
    {
        path: '/widgets/', name: 'Widgets', component: Widgets,
        meta: { title: 'My Widgets', login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/projects/', name: 'Projects', component: Projects,
        meta: { title: 'Projects', login_needed: true, admin: true, support: true, customer: true }
    }, 
    {
        path: '/create-widget/:widget_id?', name: 'CreateWidget', component: CreateWidget,
        meta: { login_needed: false, admin: false, support: false, customer: false }
    },
    {
        path: '/update-widget/tooltips/:widget_id/', name: 'Tooltips', component: Tooltips, beforeEnter: OwnWidgetMiddleware,
        meta: { title: 'Customize Tooltips widget', login_needed: false, admin: false, support: true, customer: true }
    },
    {
        path: '/update-widget/onboarding-checklist/:widget_id/', 
        redirect: to => {
            const { widget_id } = to.params;
            return `/update-widget/onboarding-checklist-items/${widget_id}/`;
        }
    },
    { 
        path: '/update-widget/onboarding-checklist-items/:widget_id/', name: 'OnboardingChecklistItems', component: OnboardingChecklistItems, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Onboarding Checklist Items widget', login_needed: false, admin: false, support: true, customer: true }
    },
    {
        path: '/update-widget/feedback/:widget_id/', 
        redirect: to => {
            const { widget_id } = to.params;
            return `/update-widget/quick-feedback/${widget_id}/`;
        }
    },
    { 
        path: '/update-widget/quick-feedback/:widget_id/', name: 'QuickFeedback', component: QuickFeedback, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Quick Feedback widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/product-updates/:widget_id/', name: 'ProductUpdates', component: ProductUpdates, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Product Updates widget', login_needed: false, admin: false, support: true, customer: true }
    },
    {
        path: '/update-widget/social-proof/:widget_id/', 
        redirect: to => {
            const { widget_id } = to.params;
            return `/update-widget/social-proofs/${widget_id}/`;
        }
    },
    { 
        path: '/update-widget/social-proofs/:widget_id/', name: 'Social proof', component: SocialProof, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Social Proofs widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/vote-button/:widget_id/', name: 'VoteButton', component: VoteButton, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Vote Button widget', login_needed: false, admin: false, support: true, customer: true }
    },
    {
        path: '/update-widget/announcement/:widget_id/', 
        redirect: to => {
            const { widget_id } = to.params;
            return `/update-widget/announcements/${widget_id}/`;
        }
    },
    { 
        path: '/update-widget/announcements/:widget_id/', name: 'Announcements', component: Announcements, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Announcements widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/back-to-top-button/:widget_id/', name: 'BackToTopButton', component: BackToTopButton, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Back-to-top Button widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/exit-intent-popup/:widget_id/', name: 'ExitIntentPopup', component: ExitIntentPopup, beforeEnter: OwnWidgetMiddleware,
        meta: { title: 'Customize Exit-intent popup widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/cookie-consent/:widget_id/', name: 'CookieConsent', component: CookieConsent, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Cookie Consent widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/featured-articles/:widget_id/', name: 'FeaturedArticles', component: FeaturedArticles, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Featured Articles widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/changelog/:widget_id/', name: 'Changelog', component: Changelog, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Changelog widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/product-tour/:widget_id/', name: 'ProductTour', component: ProductTour, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Product Tour widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/social-share-button/:widget_id/', name: 'SocialShareButton', component: SocialShareButton, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Social Share Button widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/cancellation-form/:widget_id/', name: 'CancellationForm', component: CancellationForm, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Cancellation Form widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/faq/:widget_id/', name: 'FAQ', component: FAQ, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize FAQ widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/table-of-contents/:widget_id/', name: 'TableOfContents', component: TableOfContents, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Table of Contents widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/email-signup-form/:widget_id/', name: 'EmailSignupForm', component: EmailSignupForm, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Email Signup Form widget', login_needed: false, admin: false, support: true, customer: true }
    },
    { 
        path: '/update-widget/pricing-table/:widget_id/', name: 'PricingTable', component: PricingTable, beforeEnter: OwnWidgetMiddleware, 
        meta: { title: 'Customize Pricing Table widget', login_needed: false, admin: false, support: true, customer: true }
    },
    {
        path: '/account/profile/', name: 'Profile', component: Profile,
        meta: { title: 'Profile', login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/account/change-password/', name: 'ChangePassword', component: ChangePassword,
        meta: { title: 'Change Password', login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/account/billing/', name: 'Billing', component: Billing,
        meta: { title: 'Billing', login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/account/change-plan/', name: 'ChangePlan', component: ChangePlan,
        meta: { title: 'Change Plan', login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/account/add-ons/', name: 'AddOns', component: AddOns,
        meta: { title: 'Add-ons', login_needed: true, admin: true, support: true, customer: true }
    },
    {
        path: '/explore-more-widgets/', name: 'ExploreMoreWidgets', component: ExploreMoreWidgets,
        meta: { title: 'Explore More Widgets', login_needed: true, admin: true, support: true, customer: true }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    var current_path = to.path;
    var access_level = "";
    var is_authenticated = false;
    const normalizedPath = current_path.replace(/\/$/, '');
   
    if (store
        && store.getters
        && store.getters.logged_in_user
        && store.getters.logged_in_user.data
        && store.getters.logged_in_user.data.token) {
        localStorage.setItem("access_level", store.getters.logged_in_user.data.user.access_level);
        access_level = store.getters.logged_in_user.data.user.access_level;
        is_authenticated = store.getters.is_authenticated;
    }

    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }

    // if not logged in and login_needed is true, redirect to login

    if (!is_authenticated && to.meta.login_needed) {
        console.log('redirect to login')
        next('/login/');
    }

    // if user goes to login page and was already logged in
    else if (normalizedPath == '/login' && is_authenticated) {
    
        var access_level_login  = access_level.toLowerCase();
        if (access_level_login == 'customer') {
            next('/widgets');
        }

        if (access_level_login == 'admin' || access_level_login == 'support') {
            // if admin or support, go to users list
            next('/admin/users/');
        }
    }
    // if user goes to signup page and was already logged in
    else if (normalizedPath == '/signup' && is_authenticated) {
        // if customer, go to my widgets
        var access_level_signup  = access_level.toLowerCase();
        if (access_level_signup == 'customer') {
            next('/widgets/');
        }

        if (access_level_signup == 'admin' || access_level_signup == 'support') {
            // if admin or support, go to users list
            next('/admin/users/');
        }
    }

    // if not authenticated and there's no need to login
    else if (!to.meta.login_needed) {
        console.log(to.meta.login_needed)
        next();
    }

    // if authenticated admin
    else if (is_authenticated && to.meta.admin && access_level.toLowerCase() == 'admin') {
        next();
    }

    // if authenticated support
    else if (is_authenticated && to.meta.support && access_level.toLowerCase() == 'support') {
        next();
    }

    // if authenticated customer
    else if (is_authenticated && to.meta.customer && access_level.toLowerCase() == 'customer') {
        next();
    }
});

/*
// who's the user?
if (store
    && store.getters
    && store.getters.logged_in_user
    && store.getters.logged_in_user.data
    && store.getters.logged_in_user.data.token) {
    console.log('user access_level', store.getters.logged_in_user.data.user.access_level)
    console.log('user token', store.getters.logged_in_user.data.token)
}

    console.log('access_level: ', access_level)
    console.log('is_authenticated: ', is_authenticated)
    console.log('token: ', token)

    console.log('to: ', to)
    console.log('current_path: ', current_path)

    console.log('to.meta.login_needed: ', to.meta.login_needed)
    console.log('to.meta.admin: ', to.meta.admin)
    console.log('to.meta.support: ', to.meta.support)
    console.log('to.meta.customer: ', to.meta.customer)

// guard for login_needed
router.beforeEach((to, from, next) => {

    console.log('to.meta.login_needed', to.meta.login_needed)

    if (to.matched.some((record) => record.meta.login_needed)) {
        if (store.getters.is_authenticated) {
            next();
            return;
        }
        next("/login");
    } else {
        next();
    }
});

// guard for guest
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {

        if (store.getters.is_authenticated) {
            next("/admin/users");
            return;
        }

        next();
    } else {
        next();
    }
});

// guard for admin pages only
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiredAdmin)) {

        if (store.getters.is_authenticated && store.getters.logged_in_user.data.user.access_level != "Admin") {
            next("/admin/users");
            return;
        }

        next();
    } else {
        next();
    }
});

// guard for atleast support pages only
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiredAtleastSupport)) {
        if (store.getters.is_authenticated &&
            (store.getters.logged_in_user.data.user.access_level != "admin" &&
                store.getters.logged_in_user.data.user.access_level != "support")) {
            next("/users/widgets");
            return;
        }

        next();
    } else {
        next();
    }
});
*/

export default router
