<template>
    <div
        class="modal fade"
        id="projectSelectModal"
        tabindex="-1"
        aria-labelledby="projectSelectModalLabel"
        ref="projectSelectModal"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="projectSelectModalLabel">Select a Project</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="loadingProjects" class="d-flex justify-content-center my-3">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row">
                            <SelectableCard
                                v-for="project in projects"
                                :key="project.id"
                                :item="project"
                                v-model:selected="selectedProjectId"
                                truncateLimit="40"
                            />
                        </div>
                    </div>
                </div>
                <div class="modal-footer" v-if="!loadingProjects">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="saving">
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary" @click="confirmSelection" :disabled="saving">
                        <span v-if="saving" class="spinner-border spinner-border-sm me-1" role="status"></span>
                        Select
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import SelectableCard from "../../../components/SelectableCard.vue";

export default {
    name: "ProjectSelectModal",
    components: { SelectableCard },
    data() {
        return {
            widgetId: null,
            projects: [],
            selectedProjectId: "",
            loadingProjects: false,
            saving: false,
            token: this.$store.getters.logged_in_user.data.token || "",
        };
    },
    methods: {
        async fetchProjects() {
            this.loadingProjects = true;
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}projects/read`, {
                    headers: { Authorization: "Bearer " + this.token },
                });
                this.projects = response.data;
            } catch (error) {
                console.error("Error fetching projects:", error);
            } finally {
                this.loadingProjects = false;
            }
        },
        async confirmSelection() {
            if (!this.selectedProjectId) return;
            this.saving = true;

            try {
                await axios.put(
                    `${process.env.VUE_APP_API_URL}widgets/assign-project/${this.widgetId}`,
                    { project_id: this.selectedProjectId },
                    { headers: { Authorization: "Bearer " + this.token } }
                );
                this.$parent.$refs.readComponent.read();
                const modalInstance = Modal.getInstance(this.$refs.projectSelectModal);
                if (modalInstance) {
                    modalInstance.hide();
                }
                this.selectedProjectId = "";
            } catch (error) {
                console.error("Error updating widget project:", error);
            } finally {
                this.saving = false;
            }
        },
        async show(widgetId) {
            this.widgetId = widgetId;
            const modalInstance = new Modal(this.$refs.projectSelectModal);
            modalInstance.show();
            await this.fetchProjects();
        },
    },
};
</script>

<style scoped>
.project-option {
    transition: background 0.2s, border-color 0.2s;
}
.project-option:hover {
    background-color: #f8f9fa;
}
</style>
