<template>
    <div class="col-sm-9">
        <div class="d-flex flex-wrap justify-content-between mb-3 flex-column flex-sm-row align-items-lg-center gap">
            <h4 class="me-auto">
                User Activities
                <span class="badge rounded-pill bg-secondary">{{ data.total }}</span>
            </h4>
        </div>

        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>
        <div class="table-responsive bg-white p-20 rounded" v-else>
            <div class="d-flex flex-wrap gap-3 mb-3">
                <div>
                    <label for="activityTypeFilter" class="form-label">Activity Type</label>
                    <select
                        id="activityTypeFilter"
                        v-model="filters.activity_type_id"
                        class="form-select"
                        @change="applyFilters"
                    >
                        <option value="">All</option>
                        <option v-for="type in activityTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                    </select>
                </div>

                <div>
                    <label for="monthFilter" class="form-label">Month</label>
                    <select id="monthFilter" v-model="filters.month" class="form-select" @change="applyFilters">
                        <option value="">All</option>
                        <option v-for="(month, index) in months" :key="index" :value="index + 1">
                            {{ month }}
                        </option>
                    </select>
                </div>

                <div>
                    <label for="yearFilter" class="form-label">Year</label>
                    <select id="yearFilter" v-model="filters.year" class="form-select" @change="applyFilters">
                        <option value="">All</option>
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                </div>
            </div>
            <table class="table table-hovered">
                <thead v-if="!data.data || data.data.length < 1">
                    <tr>
                        <td colspan="5">No data found.</td>
                    </tr>
                </thead>
                <thead v-if="data.data && data.data.length > 0">
                    <tr>
                        <th>Customer</th>
                        <th>Activity</th>
                        <th>Added By</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody v-if="data.data && data.data.length > 0">
                    <tr v-for="item in data.data" :key="item.id">
                        <td>{{ item.added_by_name }}</td>
                        <td>
                            <b>{{ getActivityTypeName(item.activity_type_id) }}</b
                            ><br />
                            <div v-html="item.description"></div>
                        </td>
                        <td>
                            <b>{{ item.added_by_name }}</b
                            ><br />
                            {{ formatDate(item.date_time) }}
                        </td>
                        <td>
                            <div class="d-flex flex-wrap gap-2">
                                <base-button @click="editActivity($event, item.id)">
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </base-button>
                                <base-button @click="deleteActivity(item.id)" variant="danger">
                                    <i class="bi bi-trash d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Delete</span>
                                </base-button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav aria-label="Page navigation example" v-if="data.data && data.data.length > 0">
                <ul class="pagination justify-content-center flex-wrap gap-1">
                    <span v-for="(item, index) in data.links" :key="item.url + item.label" class="me-1">
                        <li :class="{ active: item.active }" class="page-item">
                            <a
                                v-if="item.url && !(paginationLoadingIndex === index)"
                                @click="read(event, item.url, index)"
                                class="page-link"
                            >
                                <span v-html="item.label"></span>
                            </a>
                            <span v-else-if="paginationLoadingIndex === index" class="page-link">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </span>
                        </li>
                    </span>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import store from "../../../store";

export default {
    data() {
        return {
            loading: true,
            data: "",
            activityTypes: [],
            widgetTypes: [],
            search_keyword: "",
            paginationLoadingIndex: null,
            filters: {
                activity_type_id: "",
                month: "",
                year: "",
            },
            months: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            years: Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i),
        };
    },
    methods: {
        formatDate(dateTime) {
            const options = {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
            };

            const date = new Date(dateTime);
            return new Intl.DateTimeFormat("en-US", options).format(date);
        },

        async fetchActivityTypesAndWidgetTypes() {
            const activityRes = await fetch(process.env.VUE_APP_API_URL + "activity-types/read", {
                headers: new Headers({
                    Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });
            const activityData = await activityRes.json();
            this.activityTypes = activityData.data;

            const widgetRes = await fetch(process.env.VUE_APP_API_URL + "widget-types/status/1", {
                headers: new Headers({
                    Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });
            const widgetData = await widgetRes.json();
            this.widgetTypes = widgetData;
        },

        getActivityTypeName(activityTypeId) {
            const activityType = this.activityTypes.find((type) => type.id === activityTypeId);
            return activityType ? activityType.name : "Unknown";
        },

        async applyFilters() {
            this.loading = true;

            const params = new URLSearchParams({
                activity_type_id: this.filters.activity_type_id || "",
                month: this.filters.month || "",
                year: this.filters.year || "",
            });

            const url = `${process.env.VUE_APP_API_URL}user-activities/read?${params.toString()}`;

            try {
                const res = await fetch(url, {
                    headers: new Headers({
                        Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                    }),
                });

                this.data = await res.json();
            } catch (error) {
                console.error("Error fetching filtered data:", error);
            } finally {
                this.loading = false;
            }
        },

        async read(event, url, index) {
            this.paginationLoadingIndex = index;

            if (event) {
                event.target.disabled = true;
            }

            if (!url) {
                url = process.env.VUE_APP_API_URL + "user-activities/read";
            }

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            this.data = await res.json();
            this.loading = false;
            this.paginationLoadingIndex = null;
            window.scrollTo(0, 0);
            if (event) {
                event.target.disabled = false;
            }
        },

        editActivity(event, id) {
            this.$parent.$refs.updateComponent.showEditModal(event, id);
        },

        async deleteActivity(id) {
            this.$parent.$refs.deleteComponent.showDeleteModal(id);
        },
    },
    mounted() {
        this.fetchActivityTypesAndWidgetTypes();
        this.read();
    },
};
</script>
