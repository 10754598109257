<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Change Plan</h4>

        <section id="pricing-container" class="pricing">
            <div class="mt-5">
                <PricingTable />
            </div>
        </section>
    </div>
</template>

<script>
import PricingTable from "../components/PricingTable.vue";

export default {
    components: {
        PricingTable,
    },
};
</script>

<style>
.wbnz-pricing-table {
    /* General Styles */
    --background-color: #ffffff;
    --plan-background-color: #ffffff;
    --plan-border-radius: 20px;
    --plan-padding: 30px;

    /* Plan Title */
    --plan-title-font-color: #333333;
    --plan-title-font-size: 24px;

    /* Plan Price */
    --plan-price-font-color: #333333;
    --plan-price-font-size: 40px;
    --plan-price-period-font-color: #666666;
    --plan-price-period-font-size: 16px;

    /* Plan Description */
    --plan-description-font-color: #666666;
    --plan-description-font-size: 16px;

    /* Plan Button */
    --plan-button-background-color: #21ba58;
    --plan-button-font-color: #ffffff;
    --plan-button-font-size: 16px;
    --plan-button-border-radius: 10px;

    /* Featured Tag */
    --featured-tag-text: "Popular";
    --featured-tag-background-color: #21ba58;
    --featured-tag-font-color: #ffffff;
    --featured-tag-font-size: 12px;

    /* Plan Features */
    --plan-features-font-color: #666666;
    --plan-features-font-size: 16px;
    --plan-features-icon-color: #21ba58;

    /* Pricing Tab */
    --pricing-tab-background-color: #f3f4f6;
    --pricing-tab-font-color: #4f4f4f;
    --pricing-tab-font-size: 16px;
    --pricing-tab-active-background-color: #ffffff;
    --pricing-tab-active-font-color: #1a1a1a;
    --pricing-tab-border-radius: 8px;

    --transition: all 0.2s ease;
}

.wbnz-pricing-table * {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

.wbnz-pricing-table a {
    text-decoration: none;
}

.wbnz-pricing-table {
    font-family: "Inter", system-ui;
    box-sizing: border-box;
}

.wbnz-pricing-table-container {
    background-color: var(--background-color);
}

.wbnz-pricing-tab {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.wbnz-pricing-tab__list {
    font-weight: 600;
    background-color: var(--pricing-tab-background-color);
    display: flex;
    gap: 4px;
    justify-content: center;
    padding: 0;
    list-style: none;
    padding: 4px;
    border-radius: var(--pricing-tab-border-radius);
    border: 1px solid #ddd;
}

.wbnz-pricing-tab__item {
    border-radius: var(--pricing-tab-border-radius);
}

.wbnz-pricing-tab__button {
    font: inherit;
    padding: 0.5rem 1rem;
    border-radius: calc(var(--pricing-tab-border-radius) - 2px);
    font-size: var(--pricing-tab-font-size);
    background-color: transparent;
    color: var(--pricing-tab-font-color);
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: none;
}

.wbnz-pricing-tab__button:focus {
    outline: none;
}

.wbnz-pricing-tab__button[aria-selected="true"] {
    background-color: var(--pricing-tab-active-background-color);
    color: var(--pricing-tab-active-font-color);
}

.wbnz-pricing-tab__button:not([aria-selected="true"]):hover {
    background-color: #e0e0e0;
}

.wbnz-pricing {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: center;
    gap: 20px;
    justify-items: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

@media (max-width: 768px) {
    .wbnz-pricing {
        grid-template-columns: 1fr;
    }

    .wbnz-pricing__card {
        width: 100%;
    }
}
.wbnz-pricing__card {
    background-color: var(--plan-background-color);
    border: 1px solid #ddd;
    border-radius: var(--plan-border-radius);
    width: 100%;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.wbnz-pricing__card:hover {
    transform: scale(1.01);
}

.wbnz-pricing__card:hover .wbnz-pricing__details {
    transform: scale(1.02);
}

.wbnz-pricing__card:hover .wbnz-pricing__features {
    transform: translateY(4px);
}

.wbnz-pricing__card.wbnz-pricing__card--featured {
    border: 1px solid var(--featured-tag-background-color);
    position: relative;
}

.wbnz-pricing__card.wbnz-pricing__card--featured::before {
    content: "";
    position: absolute;
    top: -50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, var(--featured-tag-background-color) 20%, transparent 80%);
    opacity: 0;
    transform: translateX(-50%);
    filter: blur(5px);
    animation: fadeInFeaturedLight 1s ease-out forwards;
}

@keyframes fadeInFeaturedLight {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.15;
    }
}

.wbnz-pricing__card--featured .wbnz-pricing__plan-name::after {
    content: var(--featured-tag-text);
    display: inline-block;
    background-color: var(--featured-tag-background-color);
    color: var(--featured-tag-font-color);
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: var(--featured-tag-font-size);
    vertical-align: middle;
}

.wbnz-pricing__card .wbnz-pricing__details {
    padding: var(--plan-padding);
    border-bottom: 1px solid #ddd;
    transition: transform 0.3s ease;
    border-radius: 20px 20px 0 0;
}

.wbnz-pricing__card .wbnz-pricing__plan-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    color: var(--plan-title-font-color);
    font-size: var(--plan-title-font-size);
    margin-bottom: 10px;
    font-weight: 700;
    line-height: normal !important;
}

.wbnz-pricing__card .wbnz-pricing__price {
    font-weight: 700;
    color: var(--plan-price-font-color);
    margin-bottom: 15px;
}

.wbnz-pricing__price .wbnz-pricing__price-amount {
    font-size: var(--plan-price-font-size);
    font-weight: 800;
}

.wbnz-pricing__price .wbnz-pricing__price-period {
    font-size: var(--plan-price-period-font-size);
    font-weight: normal;
    color: var(--plan-price-period-font-color);
}

.wbnz-pricing__card .wbnz-pricing__description {
    font-size: var(--plan-description-font-size);
    margin-bottom: 25px;
    color: var(--plan-description-font-color);
}

.wbnz-pricing__button {
    width: 100%;
    display: inline-block;
    text-align: center;
    font: inherit;
    font-size: var(--plan-button-font-size);
    background-color: var(--plan-button-background-color);
    color: var(--plan-button-font-color);
    padding: 15px 23px;
    border-radius: var(--plan-button-border-radius);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.wbnz-pricing__button:hover {
    transform: scale(1.01);
}

.wbnz-pricing__features {
    list-style: none;
    padding: var(--plan-padding);
    font-size: var(--plan-features-font-size);
    color: var(--plan-features-font-color);
    transition: transform 0.3s ease;
}

.wbnz-pricing__features li:not(:last-child) {
    margin-bottom: 15px;
}

.wbnz-pricing__feature {
    color: var(--plan-features-font-color);
    font-size: var(--plan-features-font-size);
    display: flex;
    align-items: center;
    gap: 10px;
}

.wbnz-pricing__feature-icon {
    color: var(--plan-features-icon-color);
    width: 20px;
    height: 20px;
    stroke: var(--plan-features-icon-color);
    fill: var(--plan-features-icon-color);
}

.wbnz-pricing__feature-icon--danger {
    color: #e53e3e;
    stroke: #e53e3e;
    fill: #e53e3e;
}

.wbnz-pricing__features {
    opacity: 0;
    transform: translateY(10px);
    animation: featureListAnimation 0.6s ease forwards;
}

@keyframes featureListAnimation {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.wbnz-pricing__sample-notice {
    text-align: center;
}

.wbnz-pricing__sample-notice p {
    display: inline-block;
    background: #fffbcc;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #856404;
    border: 1px solid #ffeeba;
    border-radius: 5px;
    margin-bottom: 10px;
}
</style>
