<template>
    <div class="form-grid">
        <div class="form-group form-grid-span-full">
            <label>Pricing Tab Monthly Text</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetSettings.pricing_tab_monthly_text"
                id="pricing_tab_monthly_text "
            />
        </div>
        <div class="form-group form-grid-span-full">
            <label>Pricing Tab Yearly Text</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetSettings.pricing_tab_yearly_text"
                id="pricing_tab_yearly_text "
            />
        </div>
        <div class="form-group form-grid-span-full">
            <label>Note Text</label>
            <input type="text" class="form-control" v-model="localWidgetSettings.note_text" id="note_text" />
        </div>
        <div class="form-group form-grid-span-full">
            <label>Featured Tag Text</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetSettings.featured_tag_text"
                id="featured_tag_text"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
