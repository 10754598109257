<template>
    <div
        class="modal fade"
        id="editItemModal"
        tabindex="-1"
        aria-labelledby="editItemModalLabel"
        aria-hidden="true"
        ref="editModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editItemModalLabel">Edit project</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <fieldset>
                            <div class="mb-3">
                                <label for="name" class="form-label">Name</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    v-model.trim="data.name"
                                    placeholder="Enter the name"
                                    @blur="validateField('name')"
                                />
                                <p v-if="!validations.name" class="text-danger small">This field is required.</p>
                            </div>

                            <div class="mb-3">
                                <label for="description" class="form-label">Description</label>
                                <textarea
                                    class="form-control"
                                    id="description"
                                    v-model.trim="data.description"
                                    placeholder="Enter the description"
                                    rows="4"
                                    @blur="validateField('description')"
                                ></textarea>
                                <p v-if="!validations.description" class="text-danger small">This field is required.</p>
                            </div>

                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="background_color" class="form-label">Background Color</label>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            id="background_color"
                                            class="form-control setting setting-color"
                                            v-model="data.background_color"
                                            placeholder="Choose background color"
                                        />
                                        <PickrColorPicker v-model="data.background_color" />
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="text_color" class="form-label">Text Color</label>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            id="text_color"
                                            class="form-control setting setting-color"
                                            v-model="data.text_color"
                                            placeholder="Choose text color"
                                        />
                                        <PickrColorPicker v-model="data.text_color" />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary" @click="submitForm">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import PickrColorPicker from "../../../components/PickrColorPicker.vue";

export default {
    components: {
        PickrColorPicker,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            data: {
                name: "",
                description: "",
                background_color: "#42C471",
                text_color: "#FFFFFF",
                selectedWidgets: [],
            },
            validations: {
                name: true,
                description: true,
            },
        };
    },
    computed: {
        isFormValid() {
            return Object.values(this.validations).every((isValid) => isValid);
        },
    },
    methods: {
        async submitForm() {
            this.validateAllFields();

            if (!this.isFormValid) {
                console.warn("Form is invalid");
                return;
            }

            try {
                const payload = {
                    name: this.data.name,
                    description: this.data.description,
                    background_color: this.data.background_color,
                    text_color: this.data.text_color,
                };

                console.log(this.token);

                const response = await axios.put(
                    `${process.env.VUE_APP_API_URL}projects/update/${this.data.id}`,
                    payload,
                    {
                        headers: { Authorization: "Bearer " + this.token },
                    }
                );

                console.log("Project Updated:", response.data);
                this.clearForm();
                const modalInstance = Modal.getInstance(this.$refs.editModal);
                modalInstance.hide();
                this.$emit("updated-project");
                this.$parent.$refs.readComponent.fetchItems();
            } catch (error) {
                console.error("Error updating project:", error);
            }
        },
        clearForm() {
            this.data = {
                name: "",
                description: "",
                background_color: "#42C471",
                text_color: "#FFFFFF",
                selectedWidgets: [],
            };
        },
        validateField(field) {
            this.validations[field] = !!this.data[field];
        },
        validateAllFields() {
            Object.keys(this.validations).forEach((field) => {
                this.validateField(field);
            });
        },
        showModal(item) {
            this.data = {
                ...item,
            };
            console.log(this.data);
            const modalInstance = new Modal(this.$refs.editModal);
            modalInstance.show();
        },
    },
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
