import axios from "axios";
import store from "./store";
import router from "./router";

axios.defaults.baseURL = process.env.VUE_APP_API_URL || "https://api.webynize.com/api";
axios.defaults.headers.common["Content-Type"] = "application/json";

const token = localStorage.getItem("token");
if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axios.interceptors.response.use(
    (response) => {
        if (response.data && response.data.success === false && response.data.error === "Unauthorized") {
            store.dispatch("logoutAuth");
            router.push("/login");
        }
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            store.dispatch("logoutAuth");
            router.push("/login");
        }
        return Promise.reject(error);
    }
);

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
});

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.data && response.data.success === false && response.data.error === "Unauthorized") {
            store.dispatch("logoutAuth");
            router.push("/login");
        }
        return response;
    },
    async (error) => {
        if (error.response && error.response.status === 401) {
            store.dispatch("logoutAuth");
            router.push("/login");
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
