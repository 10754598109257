<template>
    <div class="table-responsive">
        <table class="table table-dark table-hover table-striped align-middle rounded-1 overflow-hidden">
            <thead v-if="!list || list.length < 1">
                <tr>
                    <th colspan="4">No data found.</th>
                </tr>
            </thead>
            <thead class="thead-dark" v-else>
                <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Selector</th>
                    <th scope="col">Title</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <draggableComponent v-model="list" tag="tbody" item-key="id" @end="onReorder">
                <template #item="{ element }">
                    <tr>
                        <td scope="row">{{ element.id }}</td>
                        <td>{{ truncate(element.selector, 15) }}</td>
                        <td>{{ truncate(element.title, 20) }}</td>
                        <td>
                            <div class="d-flex flex-wrap gap-2">
                                <button
                                    class="btn btn-primary btn-sm"
                                    @click.prevent="this.$parent.showEditModal(event, element)"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </button>
                                <button
                                    class="btn btn-danger btn-sm"
                                    @click.prevent="this.$parent.showDeleteModal(event, element.id)"
                                >
                                    <i class="bi bi-trash d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Delete</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </draggableComponent>
        </table>
    </div>
</template>

<script>
import draggableComponent from "vuedraggable";
import axios from "axios";

export default {
    name: "TableExample",
    components: {
        draggableComponent,
    },
    props: {
        tours: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            widget_id: this.$route.params.widget_id,
            list: this.tours,
        };
    },
    watch: {
        tours: {
            immediate: true,
            handler(newTours) {
                this.list = newTours;
            },
        },
    },
    methods: {
        onReorder() {
            console.log("Items have been rearranged");

            const reorderedList = this.list.map((item, index) => ({
                id: item.id,
                order: index + 1,
            }));

            this.saveNewOrder(reorderedList);
        },
        async saveNewOrder(reorderedList) {
            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}product-tour-items/update-orders?widget_id=${this.widget_id}`,
                    { items: reorderedList },
                    { headers: { Authorization: "Bearer " + this.token } }
                );

                console.log("Product Tour Items Reordered:", response.data);

                this.$emit("reordered-article");

                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error reordering product tour items:", error);
            }
        },
        truncate(text, length = 20) {
            if (!text) return "";
            return text.length > length ? text.substring(0, length) + "..." : text;
        },
    },
};
</script>

<style scoped>
.buttons {
    margin-top: 35px;
}
</style>
