<template>
    <div class="form-grid mb-4">
        <div class="form-group form-grid-span-full">
            <label for="">Custom CSS</label>
            <textarea
                v-model="localWidgetSettings.custom_css"
                class="form-control"
                name="custom_css"
                id="custom_css"
                cols="30"
                rows="10"
                @click="handleProtectedClick"
                :readonly="isRestricted"
            ></textarea>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
