<template>
    <div v-if="is_logged_in && show_menu" class="col-sm-3 mb-3">
        <div class="sidebar-container p-3 bg-dark text-light shadow-lg top-0 start-0" style="border-radius: 10px;">
            <ul class="list-unstyled mb-0">
                <li v-for="(navItem, i) in menuList" :key="i" class="mb-2">
                    <button
                        class="btn-toggle align-items-center justify-content-between w-100 text-start p-2 d-flex text-light rounded"
                        :aria-expanded="navItem.expand"
                        @click.prevent="navItemCollapse(i)"
                        style="transition: background-color 0.3s ease, color 0.3s ease;"
                    >
                        <div class="d-flex align-items-center">
                            <i :class="navItem.icon" class="me-2"></i>
                            <span>{{ navItem.name }}</span>
                        </div>
                        <i :class="navItem.expand ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"></i>
                    </button>

                    <div
                        :id="'collapse-' + i"
                        class="collapse-custom"
                        :class="{ expanded: navItem.expand }"
                    >
                    <ul class="list-unstyled btn-toggle-nav p-2">
                        <li v-for="(subItem, j) in navItem.items" :key="j" class="mb-1">
                            <router-link
                                :to="{ path: subItem.link }"
                                :class="[
                                    'd-flex align-items-center p-2 rounded text-decoration-none',
                                    $route.path.replace(/\/+$/, '') === subItem.link.replace(/\/+$/, '') ? 'active' : 'text-light',
                                ]"
                                style="transition: background-color 0.3s ease;"
                            >
                                <i :class="[subItem.icon, { 'text-white': $route.path === subItem.link, 'text-light': $route.path !== subItem.link }]"
                                class="me-2"></i>
                                <span :class="{ 'text-white': $route.path === subItem.link, 'text-light': $route.path !== subItem.link }">
                                    {{ subItem.name }}
                                </span>
                            </router-link>
                        </li>
                    </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show_menu: true,
            menuList: [],
        };
    },
    name: "Sidebar",
    computed: {
        is_logged_in() {
            return this.$store.getters.is_authenticated;
        },
        role() {
            return this.$store.getters.logged_in_user.data.user.access_level;
        },
    },
    watch: {
        $route() {
            this.populateMenuList();
            this.showSidebar();
            this.detectLink(this.$route);
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("logoutAuth");
            this.$router.push("/login/");
        },
        populateMenuList() {
            this.menuList = [
                {
                    name: "Users",
                    expand: false,
                    icon: "bi bi-person",
                    items: [
                        { name: "List", link: "/admin/users/", route_name: "Users", icon: "bi bi-list" },
                        { name: "Activities", link: "/admin/activities/", route_name: "Activities", icon: "bi bi-activity" },
                        { name: "Activity types", link: "/admin/activity-types/", route_name: "ActivityTypes", icon: "bi bi-tag" },
                    ],
                },
                {
                    name: "Widgets",
                    expand: false,
                    icon: "bi bi-grid",
                    items: [
                        { name: "List", link: "/admin/widget-list/", route_name: "WidgetList", icon: "bi bi-list-check" },
                        { name: "Types", link: "/admin/widget-types/", route_name: "WidgetTypes", icon: "bi bi-type" },
                        { name: "Categories", link: "/admin/widget-categories/", route_name: "WidgetCategories", icon: "bi bi-folder" },
                        { name: "Stats", link: "/admin/stats/", route_name: "Stats", icon: "bi bi-bar-chart" },
                    ],
                },
                {
                    name: "Content",
                    expand: false,
                    icon: "bi bi-file-earmark-text",
                    items: [
                        { name: "Product Updates", link: "/admin/product-updates/", route_name: "ProductUpdates", icon: "bi bi-upc-scan" },
                        { name: "Cache", link: "/admin/cache/", route_name: "Cache", icon: "bi bi-server" }
                    ],
                },
                {
                    name: "Plans",
                    expand: false,
                    icon: "bi bi-calendar", 
                    items: [
                        { name: "Annually", link: "/admin/plans/yearly/", route_name: "PlansYearly", icon: "bi bi-calendar3" },
                        { name: "Monthly", link: "/admin/plans/monthly/", route_name: "PlansMonthly", icon: "bi bi-calendar-month" },
                        { name: "Inactive", link: "/admin/plans/inactive/", route_name: "PlansInactive", icon: "bi bi-calendar-x" },
                        { name: "Settings", link: "/admin/plans/settings/", route_name: "PlanSettings", icon: "bi bi-sliders" },
                    ],
                },
                {
                    name: "Canned Responses",
                    expand: false,
                    icon: "bi bi-reply",
                    items: [
                        { name: "List", link: "/admin/canned-responses/", route_name: "CannedResponses", icon: "bi bi-list-check" },
                    ],
                },
                {
                    name: "Invoices",
                    expand: false,
                    icon: "bi bi-file-earmark-text",
                    items: [
                        { name: "List", link: "/admin/invoices/", route_name: "Invoices", icon: "bi bi-file-earmark-list" },
                    ],
                },
                {
                    name: "Admin stats",
                    expand: false,
                    icon: "bi bi-graph-up",
                    items: [
                        { name: "Summary", link: "/admin/admin-stats/summary/", route_name: "StatsSummary", icon: "bi bi-graph-up" },
                        { name: "Year", link: "/admin/admin-stats/year/", route_name: "StatsYear", icon: "bi bi-calendar3" },
                        { name: "Month", link: "/admin/admin-stats/month/", route_name: "StatsMonth", icon: "bi bi-calendar-month" },
                    ],
                },
                {
                    name: "Admin settings",
                    expand: false,
                    icon: "bi bi-gear",
                    items: [
                        { name: "Profile", link: "/account/profile/", route_name: "Profile", icon: "bi bi-person-circle" },
                        { name: "Settings", link: "/admin/admin-settings/", route_name: "Settings", icon: "bi bi-gear" },
                    ],
                },
            ];

            if (this.$store.getters.logged_in_user && this.$store.getters.logged_in_user.data.user.access_level === "support") {
                this.menuList.splice(-2);
            }
        },
        navItemCollapse(index) {
            this.menuList.forEach((item, i) => {
                item.expand = i === index ? !item.expand : false;
                
                const collapseElement = document.getElementById(`collapse-${i}`);
                if (collapseElement) {
                    collapseElement.style.maxHeight = item.expand ? `${collapseElement.scrollHeight}px` : `0px`;
                }
            });
            
            this.$nextTick(() => {
                const collapseElement = document.getElementById(`collapse-${index}`);
                if (collapseElement) {
                    const height = collapseElement.scrollHeight;
                    if (this.menuList[index].expand) {
                        collapseElement.style.maxHeight = `${height}px`;
                    } else {
                        collapseElement.style.maxHeight = `0px`;
                    }
                }
            });
        },
        detectLink() {
            const currentPath = this.$route.path.replace(/\/+$/, '');

            this.menuList.forEach((navItem) => {
                navItem.expand = false;

                navItem.items.forEach(item => {
                    const itemLink = item.link.replace(/\/+$/, '');
                    if (itemLink === currentPath) {
                        navItem.expand = true;
                    }
                });
            });

            this.$nextTick(() => {
                this.menuList.forEach((item, index) => {
                    const collapseElement = document.getElementById(`collapse-${index}`);
                    if (collapseElement) {
                        collapseElement.style.maxHeight = item.expand ? `${collapseElement.scrollHeight}px` : `0px`;
                    }
                });
            });
        },
        showSidebar() {
            this.show_menu = !this.$route.path.includes("create-widget") && !this.$route.path.includes("update-widget");
        }
    },
    mounted() {
        this.populateMenuList();
        this.showSidebar();
        this.detectLink();
    },
};
</script>

<style scoped>
.btn-toggle {
    font-weight: 600;
    background-color: transparent;
    border: 0;
}
.btn-toggle:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}
.sidebar-container {
    border-radius: 10px;
    background-color: #011901 !important;
}

.btn-toggle:hover {
    background-color: #333;
    color: #fff;
}

.link-dark:hover {
    background-color: #333;
    color: #fff;
}

.active {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.active i {
    color: #fff !important;
}

.hover-bg:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.collapse-custom {
    overflow: hidden;
    transition: all .3s ease-in-out;
    opacity: 0;
    max-height: 0;
}

.collapse-custom.expanded {
    overflow: hidden;
    opacity: 1;
    max-height: auto;
}
</style>

