<template>
    <div class="progress">
        <div
            class="progress-bar"
            :class="progressClass"
            role="progressbar"
            :style="{ width: progress + '%' }"
            :aria-valuenow="progress"
            aria-valuemin="0"
            aria-valuemax="100"
        ></div>
    </div>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            required: true,
        },
    },
    computed: {
        progressClass() {
            if (this.progress >= 90) return "progress-bar--danger";
            if (this.progress >= 80) return "progress-bar--warning";
            return "";
        },
    },
};
</script>

<style scoped>
.progress {
    height: 10px;
    border-radius: 5px;
}

.progress-bar {
    transition: width 1s ease-in-out;
    background: linear-gradient(90deg, #198754 0%, #28a745 100%);
}

.progress-bar--warning {
    background: linear-gradient(90deg, #ffcc00 0%, #ff9900 100%);
}

.progress-bar--danger {
    background: linear-gradient(90deg, #dc3545 0%, #ff0000 100%);
}
</style>
