<template>
    <span
        ref="tooltipWrapper"
        class="tooltip-wrapper"
        data-bs-toggle="tooltip"
        :title="tooltipText"
        :class="`tooltip-${size}`"
        :data-bs-placement="placement"
    >
        <slot></slot>
    </span>
</template>

<script>
import { Tooltip } from "bootstrap";

export default {
    props: {
        tooltipText: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: "md",
            validator: (value) => ["sm", "md", "lg"].includes(value),
        },
        placement: {
            type: String,
            default: "top",
            validator: (value) => ["top", "bottom", "left", "right", "auto"].includes(value),
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.tooltipInstance = new Tooltip(this.$refs.tooltipWrapper, {
                customClass: "custom-tooltip",
                placement: this.placement,
            });
        });
    },
    unmounted() {
        if (this.tooltipInstance) {
            this.tooltipInstance.dispose();
            this.tooltipInstance = null;
        }
    },
};
</script>

<style>
.tooltip-wrapper {
    cursor: help;
    display: inline-block;
}

.tooltip-sm {
    font-size: 0.75rem;
}
.tooltip-md {
    font-size: 0.875rem;
}
.tooltip-lg {
    font-size: 1rem;
}

:deep(.custom-tooltip) {
    --bs-tooltip-bg: white !important;
    --bs-tooltip-color: #333 !important;
    --bs-tooltip-border-radius: 6px;
    --bs-tooltip-padding-x: 0.75rem;
    --bs-tooltip-padding-y: 0.5rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
}
</style>
