<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Content text align</label>
            <select
                v-model="localWidgetSettings.content_text_align"
                class="form-select setting"
                name="content_text_align"
                id="content_text_align"
            >
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
            </select>
        </div>

        <div class="form-group">
            <label for="">Popup text align</label>
            <select
                v-model="localWidgetSettings.popup_text_align"
                class="form-select setting"
                name="popup_text_align"
                id="popup_text_align"
            >
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
