<template>
    <div v-if="is_logged_in && show_menu" class="col-sm-3 mb-3">
        <div class="sidebar-container p-3 bg-dark text-light shadow-lg top-0 start-0" style="border-radius: 10px">
            <ul class="list-unstyled mb-0">
                <li v-for="(navItem, i) in menuList" :key="i" class="mb-2">
                    <button
                        class="btn-toggle align-items-center justify-content-between w-100 text-start p-2 d-flex text-light rounded"
                        :aria-expanded="navItem.expand"
                        @click.prevent="navItemCollapse(i)"
                        style="transition: background-color 0.3s ease, color 0.3s ease"
                    >
                        <div class="d-flex align-items-center">
                            <i :class="navItem.icon" class="me-2"></i>
                            <span>{{ navItem.name }}</span>
                        </div>
                        <i :class="navItem.expand ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"></i>
                    </button>

                    <div
                        v-if="navItem.items.length > 0"
                        :id="'collapse-' + i"
                        class="collapse-custom"
                        :class="{ expanded: navItem.expand }"
                    >
                        <ul class="list-unstyled btn-toggle-nav p-2">
                            <li v-for="(subItem, j) in navItem.items" :key="j" class="mb-1">
                                <router-link
                                    :to="{ path: subItem.link }"
                                    :class="[
                                        'd-flex align-items-center p-2 rounded text-decoration-none',
                                        $route.path.replace(/\/+$/, '') === subItem.link.replace(/\/+$/, '')
                                            ? 'active'
                                            : 'text-light',
                                        subItem.className,
                                    ]"
                                    style="transition: background-color 0.3s ease"
                                >
                                    <i
                                        :class="[
                                            subItem.icon,
                                            {
                                                'text-white': $route.path === subItem.link,
                                                'text-light': $route.path !== subItem.link,
                                            },
                                        ]"
                                        class="me-2"
                                    ></i>
                                    <span
                                        :class="{
                                            'text-white': $route.path === subItem.link,
                                            'text-light': $route.path !== subItem.link,
                                        }"
                                    >
                                        {{ subItem.name }}
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        <Changelog />
    </div>
</template>

<script>
import Changelog from "./Changelog.vue";

export default {
    name: "CustomerSidebar",
    components: {
        Changelog,
    },
    data() {
        return {
            show_menu: true,
            menuList: [],
        };
    },
    computed: {
        is_logged_in() {
            return this.$store.getters.is_authenticated;
        },
    },
    watch: {
        $route() {
            this.populateMenuList();
            this.showSidebar();
            this.detectLink(this.$route);
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("logoutAuth");
            this.$router.push("/login/");
        },
        populateMenuList() {
            const userStatus = this.$store.getters.logged_in_user?.data?.user?.status;
            const isPaidUser = userStatus == 1;

            this.menuList = [
                {
                    name: "Widgets",
                    expand: false,
                    icon: "bi bi-grid",
                    items: [
                        {
                            name: "My Widgets",
                            link: "/widgets/",
                            route_name: "Widgets",
                            icon: "bi bi-box",
                        },
                        {
                            name: "Projects",
                            link: "/projects/",
                            route_name: "Projects",
                            icon: "bi bi-folder",
                        },
                        {
                            name: "Explore more widgets",
                            link: "/explore-more-widgets/",
                            route_name: "ExploreMoreWidgets",
                            icon: "bi bi-search",
                        },
                        {
                            name: "What's new?",
                            link: "#",
                            className: "show-whats-new",
                            icon: "bi bi-star",
                        },
                    ],
                },
                {
                    name: "Account",
                    expand: false,
                    icon: "bi bi-person",
                    items: [
                        {
                            name: "Profile",
                            link: "/account/profile/",
                            route_name: "Profile",
                            icon: "bi bi-person-circle",
                        },
                        {
                            name: "Change password",
                            link: "/account/change-password/",
                            route_name: "ChangePassword",
                            icon: "bi bi-lock",
                        },
                        {
                            name: "Change plan",
                            link: "/account/change-plan/",
                            route_name: "ChangePlan",
                            icon: "bi bi-arrow-repeat",
                        },
                        {
                            name: "Billing",
                            link: "/account/billing/",
                            route_name: "Billing",
                            icon: "bi bi-cash-stack",
                        },
                    ],
                },
            ];

            if (isPaidUser) {
                this.menuList[1].items.push({
                    name: "Add-ons",
                    link: "/account/add-ons/",
                    route_name: "AddOns",
                    icon: "bi bi-cart-plus",
                });
            }
        },
        navItemCollapse(index) {
            this.menuList.forEach((item, i) => {
                item.expand = i === index ? !item.expand : false;

                const collapseElement = document.getElementById(`collapse-${i}`);
                if (collapseElement) {
                    collapseElement.style.maxHeight = item.expand ? `${collapseElement.scrollHeight}px` : `0px`;
                }
            });

            this.$nextTick(() => {
                const collapseElement = document.getElementById(`collapse-${index}`);
                if (collapseElement) {
                    const height = collapseElement.scrollHeight;
                    if (this.menuList[index].expand) {
                        collapseElement.style.maxHeight = `${height}px`;
                    } else {
                        collapseElement.style.maxHeight = `0px`;
                    }
                }
            });
        },
        detectLink() {
            const currentPath = this.$route.path.replace(/\/+$/, "");

            this.menuList.forEach((navItem) => {
                navItem.expand = false;

                navItem.items.forEach((item) => {
                    const itemLink = item.link.replace(/\/+$/, "");
                    if (itemLink === currentPath) {
                        navItem.expand = true;
                    }
                });
            });

            this.$nextTick(() => {
                this.menuList.forEach((item, index) => {
                    const collapseElement = document.getElementById(`collapse-${index}`);
                    if (collapseElement) {
                        collapseElement.style.maxHeight = item.expand ? `${collapseElement.scrollHeight}px` : `0px`;
                    }
                });
            });
        },
        showSidebar() {
            this.show_menu = !(
                this.$route.path.includes("create-widget") || this.$route.path.includes("update-widget")
            );
        },
    },
    mounted() {
        this.populateMenuList();
        this.showSidebar();
        this.detectLink();
    },
};
</script>

<style scoped>
.btn-toggle {
    font-weight: 600;
    background-color: transparent;
    border: 0;
}
.btn-toggle:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}
.sidebar-container {
    border-radius: 10px;
    background-color: #011901 !important;
}

.btn-toggle:hover {
    background-color: #333;
    color: #fff;
}

.link-dark:hover {
    background-color: #333;
    color: #fff;
}

.active {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.active i {
    color: #fff !important;
}

.hover-bg:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.collapse-custom {
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    max-height: 0;
}

.collapse-custom.expanded {
    overflow: hidden;
    opacity: 1;
    max-height: auto;
}
</style>
