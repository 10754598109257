<template>
    <div
        class="modal"
        id="editItemModal"
        tabindex="-1"
        aria-labelledby="editItemModalLabel"
        aria-hidden="true"
        ref="editModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editItemModalLabel">Edit Announcement</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <div class="mb-3">
                            <label for="name" class="form-label">Name</label>
                            <input
                                type="text"
                                class="form-control"
                                id="name"
                                v-model.trim="data.name"
                                placeholder="Enter the name"
                                @blur="validateField('name')"
                            />
                            <p v-if="!validations.name" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="content_text" class="form-label">Content Text</label>
                            <textarea
                                class="form-control"
                                id="content_text"
                                v-model="data.content_text"
                                rows="3"
                                placeholder="Enter the content_text"
                                required
                                @blur="validateField('content_text')"
                            ></textarea>
                            <p v-if="!validations.content_text" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="button_text" class="form-label">Button Text</label>
                            <input
                                type="text"
                                class="form-control"
                                id="button_text"
                                v-model.trim="data.button_text"
                                placeholder="Enter the button_text"
                                @blur="validateField('button_text')"
                            />
                            <p v-if="!validations.button_text" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="button_link" class="form-label">Button URL</label>
                            <input
                                type="text"
                                class="form-control"
                                id="button_link"
                                v-model.trim="data.button_link"
                                placeholder="Enter the button_link"
                                @blur="validateField('button_link')"
                            />
                            <p v-if="!validations.button_link" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Status</label>
                            <div>
                                <div class="form-check form-check-inline">
                                    <input
                                        type="radio"
                                        id="statusActiveEdit"
                                        class="form-check-input"
                                        value="1"
                                        v-model="data.status"
                                    />
                                    <label for="statusActiveEdit" class="form-check-label">Active</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input
                                        type="radio"
                                        id="statusInactiveEdit"
                                        class="form-check-input"
                                        value="0"
                                        v-model="data.status"
                                    />
                                    <label for="statusInactiveEdit" class="form-check-label">Inactive</label>
                                </div>
                            </div>
                            <p v-if="!validations.status" class="text-danger small">This field is required.</p>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" @click.prevent="submitForm">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            data: {
                name: "",
                content_text: "",
                button_text: "",
                button_link: "",
                status: 1,
                widget_id: this.$route.params.widget_id,
            },
            validations: {
                name: true,
                content_text: true,
                button_text: true,
                button_link: true,
                status: true,
            },
        };
    },
    computed: {
        ...mapGetters({
            token: "token",
        }),
        isFormValid() {
            return Object.values(this.validations).every((isValid) => isValid);
        },
    },
    methods: {
        async submitForm(event) {
            event.preventDefault();

            this.validateAllFields();

            if (!this.isFormValid) {
                console.warn("Form is invalid");
                return;
            }

            try {
                const response = await axios.put(
                    `${process.env.VUE_APP_API_URL}announcement-items/${this.data.id}`,
                    this.data,
                    {
                        headers: { Authorization: "Bearer " + this.token },
                    }
                );

                console.log("Item Updated:", response.data);

                const modalInstance = Modal.getInstance(this.$refs.editModal);
                this.clearForm();
                modalInstance.hide();

                this.$emit("updated-item");
                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error updating Item:", error);
            }
        },
        clearForm() {
            this.data = {
                name: "",
                content_text: "",
                button_text: "",
                button_link: "",
                status: 1,
                widget_id: this.$route.params.widget_id,
            };
        },
        validateField(field) {
            console.log(this.data[field]);
            if (this.data[field] === "") {
                this.validations[field] = false;
            } else {
                this.validations[field] = true;
            }
        },
        validateAllFields() {
            Object.keys(this.validations).forEach((field) => {
                this.validateField(field);
            });
        },
        showModal(event, item) {
            this.data = { ...item };
            const modalInstance = new Modal(this.$refs.editModal);
            modalInstance.show();
        },
    },
    mounted() {},
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
