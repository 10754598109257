<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Content font size</label>
            <input
                v-model="localWidgetSettings.content_font_size"
                type="number"
                id="content_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Vote Button font size</label>
            <input
                v-model="localWidgetSettings.vote_now_font_size"
                type="number"
                id="vote_now_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Popup font size</label>
            <input
                v-model="localWidgetSettings.popup_font_size"
                type="number"
                id="popup_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Submit Button font size</label>
            <input
                v-model="localWidgetSettings.submit_button_font_size"
                type="number"
                id="submit_button_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Cancel Button font size</label>
            <input
                v-model="localWidgetSettings.cancel_button_font_size"
                type="number"
                id="cancel_button_font_size"
                class="form-control setting"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
