<template>
    <div
        class="modal"
        id="editChangelogItemModal"
        tabindex="-1"
        aria-labelledby="editChangelogItemModalLabel"
        aria-hidden="true"
        ref="editModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editChangelogItemModalLabel">Edit</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <div class="mb-3">
                            <label for="title" class="form-label">Title</label>
                            <input
                                type="text"
                                class="form-control"
                                id="title"
                                v-model="data.title"
                                required
                                placeholder="Enter the title"
                                @blur="validateField('title')"
                            />
                            <p v-if="!validations.title" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="description" class="form-label">Description</label>
                            <QuillEditor
                                id="description"
                                v-model="data.description"
                                theme="snow"
                                @blur="validateField('description')"
                            />
                            <p v-if="!validations.description" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="type" class="form-label">Type</label>
                            <select class="form-select" id="type" name="type" v-model="data.type">
                                <option value="general" selected>General</option>
                                <option value="new_feature">New Feature</option>
                                <option value="bug_fix">Bug Fix</option>
                                <option value="improvement">Improvement</option>
                            </select>
                            <p v-if="!validations.type" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="status" class="form-label">Status</label>
                            <select class="form-select" id="status" name="status" v-model="data.status">
                                <option value="0" selected>Draft</option>
                                <option value="1">Publish</option>
                            </select>
                            <p v-if="!validations.status" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="publish_date" class="form-label">Publish Date</label>
                            <VueDatePicker
                                v-model="data.publish_date"
                                format="yyyy-MM-dd"
                                required
                                @blur="validateField('publish_date')"
                            />
                            <p v-if="!validations.publish_date" class="text-danger small">This field is required.</p>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" @click="submitForm">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import QuillEditor from "../../../components/QuillEditor.vue";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            data: {
                title: "",
                description: "",
                type: "general",
                status: 1,
                publish_date: "",
                widget_id: this.$route.params.widget_id,
            },
            validations: {
                title: true,
                description: true,
                type: true,
                status: true,
                publish_date: true,
            },
        };
    },
    computed: {
        formattedPublishDate: {
            get() {
                return this.data.publish_date ? this.data.publish_date.split(" ")[0] : "";
            },
            set(newDate) {
                this.data.publish_date = newDate ? `${newDate} 00:00:00` : null;
            },
        },
        isFormValid() {
            return Object.values(this.validations).every((isValid) => isValid);
        },
    },
    methods: {
        async submitForm(event) {
            event.preventDefault();

            this.validateAllFields();

            if (!this.isFormValid) {
                console.warn("Form is invalid");
                return;
            }

            try {
                const response = await axios.put(
                    `${process.env.VUE_APP_API_URL}changelog-items/${this.data.id}`,
                    this.data,
                    {
                        headers: { Authorization: "Bearer " + this.token },
                    }
                );

                console.log("Changelog Item Updated:", response.data);

                const modalInstance = Modal.getInstance(this.$refs.editModal);
                this.clearForm();
                modalInstance.hide();

                this.$emit("updated-changelog");
                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error updating changelog Item:", error);
            }
        },
        clearForm() {
            this.data = {
                title: "",
                description: "",
                type: "general",
                status: 1,
                publish_date: "",
                widget_id: this.$route.params.widget_id,
            };
        },
        validateField(field) {
            console.log(this.data[field]);
            if (this.data[field] === "") {
                this.validations[field] = false;
            } else {
                this.validations[field] = true;
            }
        },
        validateAllFields() {
            Object.keys(this.validations).forEach((field) => {
                this.validateField(field);
            });
        },
        showModal(event, item) {
            this.data = { ...item };
            const modalInstance = new Modal(this.$refs.editModal);
            modalInstance.show();
        },
    },
    mounted() {},
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
