<template>
    <Read ref="readComponent" />
    <Create ref="createComponent" />
    <Edit ref="editComponent" />
    <Delete ref="deleteComponent" />
    <ProjectWidgets ref="projectWidgetsComponent" @project-widgets-updated="handleWidgetsUpdated" />
</template>

<script>
import ProjectWidgets from "../../../components/ProjectWidgets.vue";
import Create from "./Create.vue";
import Delete from "./Delete.vue";
import Edit from "./Edit.vue";
import Read from "./Read";

export default {
    components: {
        Create,
        ProjectWidgets,
        Edit,
        Read,
        Delete,
    },

    methods: {
        handleWidgetsUpdated() {
            if (this.$refs.readComponent) {
                this.$refs.readComponent.fetchItems();
            }
        },
    },

    mounted() {},
};
</script>
