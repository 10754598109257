<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Plan Border Radius</label>
            <input
                v-model="localWidgetSettings.plan_border_radius"
                type="number"
                id="plan_border_radius"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Plan Button Border Radius</label>
            <input
                v-model="localWidgetSettings.plan_button_border_radius"
                type="number"
                id="plan_button_border_radius"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Pricing Tab Border Radius</label>
            <input
                v-model="localWidgetSettings.pricing_tab_border_radius"
                type="number"
                id="pricing_tab_border_radius"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Note Border Radius</label>
            <input
                v-model="localWidgetSettings.note_border_radius"
                type="number"
                id="note_border_radius"
                class="form-control setting"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
