<template>
    <div class="form-grid">
        <div class="form-group">
            <label class="form-check-label" for="sendy_enabled">Enable Sendy Integration</label>
            <div class="form-check form-switch">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="sendy_enabled"
                    v-model="localWidgetSettings.sendy_enabled"
                    :true-value="1"
                    :false-value="0"
                />
            </div>
        </div>

        <div class="form-group">
            <label>List ID</label>
            <input type="text" class="form-control" v-model="localWidgetSettings.sendy_list_id" id="sendy_list_id" />
        </div>

        <div class="form-group">
            <label>URL</label>
            <input type="text" class="form-control" v-model="localWidgetSettings.sendy_url" id="sendy_url" />
        </div>

        <div class="form-group">
            <label>API Key</label>
            <input
                type="password"
                class="form-control"
                v-model="localWidgetSettings.sendy_api_key"
                id="sendy_api_key"
                autocomplete="new-password"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
