<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Pricing Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.background_color"
                    type="text"
                    id="background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.background_color" :disabled="isRestricted" />
            </div>
        </div>

        <div class="form-group">
            <label for="">Pricing Tab Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.pricing_tab_background_color"
                    type="text"
                    id="pricing_tab_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.pricing_tab_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Pricing Tab Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.pricing_tab_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.pricing_tab_font_color" :disabled="isRestricted" />
            </div>
        </div>

        <div class="form-group">
            <label for="">Pricing Active Tab Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.pricing_tab_active_background_color"
                    type="text"
                    id="pricing_tab_active_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.pricing_tab_active_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Pricing Active Tab Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.pricing_tab_active_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.pricing_tab_active_font_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>

        <div class="form-group">
            <label for="">Note Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.note_background_color"
                    type="text"
                    id="note_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.note_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Note Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.note_font_color"
                    type="text"
                    id="note_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.note_font_color" :disabled="isRestricted" />
            </div>
        </div>

        <div class="form-group">
            <label for="">Plan Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_background_color"
                    type="text"
                    id="plan_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.plan_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Plan Name Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_title_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.plan_title_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Plan Price Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_price_font_color"
                    type="text"
                    id="plan_price_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.plan_price_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Plan Period Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_price_period_font_color"
                    type="text"
                    id="plan_price_period_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.plan_price_period_font_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>

        <div class="form-group">
            <label for="">Description Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_description_font_color"
                    type="text"
                    id="plan_description_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.plan_description_font_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>

        <div class="form-group">
            <label for="">Featured Tag Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.featured_tag_background_color"
                    type="text"
                    id="featured_tag_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.featured_tag_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>

        <div class="form-group">
            <label for="">Featured Tag Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.featured_tag_font_color"
                    type="text"
                    id="featured_tag_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.featured_tag_font_color" :disabled="isRestricted" />
            </div>
        </div>

        <div class="form-group">
            <label for="">Plan Features Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_features_font_color"
                    type="text"
                    id="plan_features_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.plan_features_font_color" :disabled="isRestricted" />
            </div>
        </div>

        <div class="form-group">
            <label for="">Plan Features Icon Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_features_icon_color"
                    type="text"
                    id="plan_features_icon_color"
                    class="form-control setting setting-color"
                />
                <pickr-color-picker v-model="localWidgetSettings.plan_features_icon_color" :disabled="isRestricted" />
            </div>
        </div>

        <div class="form-group">
            <label for="">Branding color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.branding_color"
                    type="text"
                    id="branding_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.branding_color" :disabled="isRestricted" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
