<template>
    <div class="col-sm-9">
        <div class="d-flex flex-wrap justify-content-between mb-3 flex-column flex-sm-row align-items-lg-center gap">
            <h4 class="me-auto">
                Widget List
                <span class="badge rounded-pill bg-secondary">{{
                    data.total
                }}</span>
            </h4>
    
            <form @submit.prevent="search(event)">
                <div class="input-group float-end">
                    <input
                        v-model="search_keyword"
                        type="text"
                        class="form-control"
                        placeholder="Type any word..."
                        aria-label="Search"
                        aria-describedby="button-addon2"
                        v-on:keyup="detectEmptySearchBox(event)"
                    />
                    <button
                        class="btn btn-secondary"
                        type="submit"
                        id="button-addon2"
                    >
                        Search
                    </button>
                </div>
            </form>
        </div>

        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>
        <div class="table-responsive bg-white p-20 rounded" v-else>
            <table class="table table-hovered">
                <thead v-if="!data.data || data.data.length < 1">
                    <tr>
                        No data found.
                    </tr>
                </thead>
                <thead v-if="data.data && data.data.length > 0">
                    <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="data.data && data.data.length > 0">
                    <tr v-for="item in data.data" :key="item.id">
                        <td class="w-25">
                            {{ getWidgetTypeName(item.widget_type_id) }}
                        </td>
                        <td class="w-50">{{ item.name }}</td>
                        <td class="w-25">
                            <div class="d-flex flex-wrap gap-2">
                                <a v-if="getWidgetRoute(item.widget_type_id, item.id)" 
                                    class="btn btn-primary"
                                    :href="getWidgetRoute(item.widget_type_id, item.id)"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </a>
        
                                <button
                                    @click="
                                        $parent.$refs.deleteComponent.showDeleteModal(
                                            item.id
                                        )
                                    "
                                    type="button"
                                    class="btn btn-danger"
                                >
                                    <i class="bi bi-trash d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Delete</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <nav 
                aria-label="Page navigation example"
                v-if="data.data && data.data.length > 0"
            >
                <ul class="pagination justify-content-center flex-wrap gap-1">
                    <span v-for="(item, index) in data.links" :key="item.url" class="me-1">
                        <li :class="{ active: item.active }" class="page-item">
                            <a
                                v-if="item.url && !(paginationLoadingIndex === index)"
                                @click="read(event, item.url, index)"
                                class="page-link"
                            >
                                <span v-html="item.label"></span>
                            </a>
                            <span v-else-if="paginationLoadingIndex === index" class="page-link">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </span>
                        </li>
                    </span>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import store from "../../../store";

export default {
    data() {
        return {
            loading: true,
            data: "",

            id: "",
            name: "",
            desciption: "",

            edit_modal: null,
            delete_modal: null,

            search_keyword: "",
            widget_types: [],
            paginationLoadingIndex: null,
        };
    },
    methods: {
        async search(event) {
            const search_url = `${process.env.VUE_APP_API_URL}widgets?search=${this.search_keyword}`;
            this.read(event, search_url);
        },

        async detectEmptySearchBox() {
            console.log("detectEmptySearchBox " + this.search_keyword);
            if (!this.search_keyword) {
                this.read();
            }
        },

        async read(event, url, index) {
            this.paginationLoadingIndex = index;

            if (event) {
                event.target.disabled = true;
            }

            // api request
            if (!url) {
                url = process.env.VUE_APP_API_URL + "widgets";
            }

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization:
                        "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            if (this.data && this.data.links) {
                this.data.links = "";
            }

            this.data = await res.json();
            this.loading = false;
            this.paginationLoadingIndex = null;
            window.scrollTo(0, 0);
            if (event) {
                event.target.disabled = false;
            }
        },
        async readWidgetTypes() {
            const url = process.env.VUE_APP_API_URL + "widget-types/all";
            const res = await fetch(url);
            this.widget_types = await res.json();
        },
        getWidgetRoute(widgetTypeId, widgetId) {
            const BASE_URL = process.env.VUE_APP_WEBSITE_URL || "https://webynize.com/";

            const routes = {
                1: `tooltips`,
                2: `onboarding-checklist-items`,
                3: `quick-feedback`,
                4: `product-updates`,
                5: `announcements`,
                6: `social-proofs`,
                7: `vote-button`,
                11: `featured-articles`,
                12: `exit-intent-popup`,
                13: `back-to-top-button`,
                14: `product-tour`,
                15: `changelog`,
                16: `social-share-button`,
                17: `cookie-consent`,
                18: `cancellation-form`,
                19: `faq`,
                20: `table-of-contents`,
                21: `email-signup-form`,
                22: `pricing-table`,
            };

            return routes[widgetTypeId] ? `${BASE_URL}app/update-widget/${routes[widgetTypeId]}/${widgetId}/` : null;
        },
        getWidgetTypeName(widgetTypeId) {
            const widgetType = this.widget_types.find(type => type.id === widgetTypeId);
            return widgetType ? widgetType.name : "Unknown Widget Type";
        }
    },
    mounted() {
        this.readWidgetTypes();
    },
};
</script>
