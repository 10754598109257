// import "bootstrap/dist/css/bootstrap.min.css"

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import "./assets/main.css";
import "./axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import BaseButton from "./UI/BaseButton.vue";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import EmbedCode from "./views/components/EmbedCode.vue";
import Customizer from "./components/Customizer.vue";
import PickrColorPicker from "./components/PickrColorPicker.vue";

// axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const app = createApp(App);

app.component("VueDatePicker", VueDatePicker);
app.component("base-button", BaseButton);

app.component("embed-code", EmbedCode);
app.component("customizer", Customizer);
app.component("pickr-color-picker", PickrColorPicker);

app.use(store);
app.use(router);
app.use(VueAxios, axios);

//* Google Tag Manager
(function () {
    const GTAG_ID = "G-13GVP5DZ87";

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        window.gtag = gtag;

        gtag("js", new Date());
        gtag("config", GTAG_ID);
    };
})();

app.mount("#app");
