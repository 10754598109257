<template>
    <div class="table-responsive">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Contact</th>
                    <th>Submitted On</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="email in emails.data" :key="email.id">
                    <td>
                        <div>
                            <strong>{{ email.name }}</strong>
                        </div>
                        <div>
                            {{ email.email }}
                        </div>
                    </td>
                    <td>{{ formatDate(email.created_at) }}</td>
                    <td>
                        <div class="d-flex flex-wrap gap-2">
                            <button class="btn btn-danger btn-sm" @click="deleteEmail(email.id)">
                                <i class="bi bi-trash d-sm-none"></i>
                                <span class="d-none d-sm-inline">Delete</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav aria-label="Page navigation" v-if="emails.data && emails.data.length > 0">
            <ul class="pagination justify-content-center flex-wrap">
                <span v-for="(item, index) in emails.links" :key="item.url + item.label" class="me-1">
                    <li :class="{ active: item.active }" class="page-item">
                        <button
                            v-if="item.url && !(paginationLoadingIndex === index)"
                            @click="changePage(item.url)"
                            class="page-link"
                        >
                            <span v-html="item.label"></span>
                        </button>
                        <span v-else-if="paginationLoadingIndex === index" class="page-link">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
                    </li>
                </span>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        emails: {
            type: Array,
            required: true,
        },
        paginationLoadingIndex: {
            type: Number,
            required: true,
        },
    },
    methods: {
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString();
        },
        deleteEmail(emailId) {
            this.$emit('delete-email', emailId);
        },
        changePage(url) {
            if (this.paginationLoadingIndex) {
                return;
            }

            const page = new URL(url).searchParams.get('page');

            if (page > 0 && page <= this.emails.last_page) {
                this.$emit('page-changed', Number(page));
            }
        },
    },
};
</script>

<style scoped>
</style>
