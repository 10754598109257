<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Pricing Tab Font Size</label>
            <input
                v-model="localWidgetSettings.pricing_tab_font_size"
                type="number"
                id="pricing_tab_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Plan Title Font Size</label>
            <input
                v-model="localWidgetSettings.plan_title_font_size"
                type="number"
                id="plan_title_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Plan Price Font Size</label>
            <input
                v-model="localWidgetSettings.plan_price_font_size"
                type="number"
                id="plan_price_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Plan Price Period Font Size</label>
            <input
                v-model="localWidgetSettings.plan_price_period_font_size"
                type="number"
                id="plan_price_period_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Plan Description Font Size</label>
            <input
                v-model="localWidgetSettings.plan_description_font_size"
                type="number"
                id="plan_description_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Plan Button Font Size</label>
            <input
                v-model="localWidgetSettings.plan_button_font_size"
                type="number"
                id="plan_button_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Featured Tag Font Size</label>
            <input
                v-model="localWidgetSettings.featured_tag_font_size"
                type="number"
                id="featured_tag_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Plan Features Font Size</label>
            <input
                v-model="localWidgetSettings.plan_features_font_size"
                type="number"
                id="plan_features_font_size"
                class="form-control setting"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
