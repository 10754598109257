<template>
    <div class="form-grid mb-4">
        <div class="form-group">
            <label>Name</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetInfo.name"
                id="widget_name"
                @blur="validateField('widgetInfo', 'name')"
            />
            <div v-if="errors.widgetInfo.name" class="invalid-feedback d-flex align-items-center">
                <i class="bi bi-exclamation-circle-fill me-1"></i> {{ errors.widgetInfo.name }}
            </div>
        </div>

        <div class="form-group">
            <label>Back to Top Button Type</label>
            <select class="form-select" v-model="localWidgetSettings.button_type" id="button_type">
                <option value="text">Text</option>
                <option value="icon">Icon</option>
            </select>
        </div>

        <div class="form-group" v-if="localWidgetSettings.button_type === 'text'">
            <label>Button Text</label>
            <input type="text" class="form-control" v-model="localWidgetSettings.button_text" id="button_text" />
        </div>

        <div class="form-group" v-if="localWidgetSettings.button_type === 'icon'">
            <label>Select Icon</label>
            <div class="d-flex align-items-center">
                <select class="form-select" v-model="localWidgetSettings.button_icon" id="button_icon">
                    <option value="arrow-up">Arrow Up</option>
                    <option value="chevron-up">Chevron Up</option>
                    <option value="caret-up">Caret Up</option>
                </select>
            </div>
        </div>

        <div class="form-group">
            <label>Scroll Offset (px)</label>
            <input
                type="number"
                class="form-control"
                v-model="localWidgetSettings.offset"
                placeholder="e.g., 100 (pixels to scroll down before the button appears)"
                id="offset"
                min="0"
                max="800"
            />
        </div>
        <div v-if="!brandingEnabled" class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="show_branding"
                    v-model="localWidgetInfo.show_branding"
                    true-value="1"
                    false-value="0"
                    :value="1"
                />
                <label class="form-check-label" for="show_branding">Show Branding</label>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        widgetSettings: { type: Object, required: true },
        widgetInfo: { type: Object, required: true },
        errors: { type: Object, required: true },
        validateField: { type: Function, required: false },
    },
    emits: ["update:widgetSettings", "update:widgetInfo", "update:errors"],
    computed: {
        ...mapGetters({
            brandingEnabled: "brandingEnabled",
        }),
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
        localWidgetInfo: {
            get() {
                return this.widgetInfo;
            },
            set(newInfo) {
                this.$emit("update:widgetInfo", newInfo);
            },
        },
        localErrors: {
            get() {
                return this.errors;
            },
            set(newErrors) {
                this.$emit("update:errors", newErrors);
            },
        },
    },
};
</script>
