<template>
    <button class="btn btn-primary fs-5 d-sm-none w-100 mb-1" @click="showCustomizer" v-if="isFloating">
        Customize
    </button>

    <div class="row">
        <div
            class="customizer d-flex align-items-start"
            :class="{
                'col-md-4': !isCollapsed,
                'col-md-2': isCollapsed,
                'customizer--collapsed': isCollapsed,
                'customizer--floating': isFloating,
                'customizer--hidden': isHidden,
            }"
        >
            <div
                class="nav customizer__nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
                @click="expandCustomizer"
            >
                <li
                    v-for="(tab, index) in tabs"
                    :key="tab.id"
                    ref="tabRefs"
                    class="nav-link"
                    :class="{ active: index === 0 }"
                    :id="`${tab.id}-tab`"
                    data-bs-toggle="pill"
                    :data-bs-target="`#${tab.id}`"
                    type="button"
                    role="tab"
                    :aria-controls="tab.id"
                    :aria-selected="index === 0 ? 'true' : 'false'"
                >
                    <i :class="tab.icon" class="me-2"></i>
                    <span class="scrolling-text">{{ tab.label }}</span>
                </li>
            </div>

            <form class="tab-content flex-fill" id="v-pills-tabContent">
                <UpgradePopup :show="showUpgradePopup" @close="showUpgradePopup = false" />

                <div
                    v-for="(tab, index) in tabs"
                    :key="tab.id"
                    class="tab-pane fade mb-4"
                    :class="{ 'show active': index === 0 }"
                    :id="tab.id"
                    role="tabpanel"
                    :aria-labelledby="`${tab.id}-tab`"
                >
                    <component
                        :is="tab.component"
                        v-model:widgetSettings="widgetSettings"
                        v-model:widgetInfo="widgetInfo"
                        v-model:errors="errors"
                        :validateField="validateField"
                        :isRestricted="isRestricted"
                        @show-upgrade="handleProtectedClick"
                        @update-preview="preview"
                    />
                </div>

                <div class="form-grid" v-show="!isCollapsed">
                    <button type="button" class="btn btn-primary" :disabled="isSaving" @click="save">
                        <span
                            v-if="isSaving"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        <span v-if="!isSaving">Save changes</span>
                    </button>
                </div>
            </form>

            <button type="button" class="close-btn" v-show="!isCollapsed" @click.prevent="dismissCustomizer">
                <i class="bi bi-x-lg"></i>
            </button>
        </div>

        <div
            class="px-0 px-md-3 h-100"
            :class="{
                'col-md-8': !isCollapsed,
                'col-md-10': isCollapsed,
            }"
        >
            <div id="preview" v-if="widgetName === 'table-of-contents'">
                <div class="wbnz-sample-page">
                    <div class="wbnz-sample-page__sidebar" ref="previewContainer"></div>
                    <div class="wbnz-sample-page__content">
                        <section class="wbnz-sample-page__section">
                            <h2 id="heading-1">Section 1: Introduction</h2>
                            <p>
                                Lorem ipsum odor amet, consectetuer adipiscing elit. Varius ligula natoque sit at; amet
                                inceptos faucibus. Parturient blandit non turpis dis litora sit platea. Facilisi fusce
                                pellentesque euismod fermentum natoque maximus ultricies fames. Lacus fames quis libero;
                                sem elit magnis cras varius. Tempus torquent senectus; nullam maecenas neque vestibulum
                                fusce. Parturient blandit nisi magna pretium dui tempor velit. Metus id eros finibus
                                egestas blandit vivamus.
                            </p>
                        </section>
                        <section class="wbnz-sample-page__section">
                            <h2 id="heading-2">Section 2: Features</h2>
                            <p>
                                Lorem ipsum odor amet, consectetuer adipiscing elit. Parturient leo porta, vitae
                                inceptos dapibus arcu consectetur justo. Maximus nostra fames consequat tempor feugiat
                                pharetra ad. Mauris faucibus pharetra condimentum eu; sollicitudin curabitur risus amet.
                                Et commodo aptent vulputate diam senectus tortor varius class. Habitasse penatibus
                                hendrerit vulputate mus malesuada accumsan dapibus laoreet. Donec gravida accumsan arcu
                                fermentum vel dis ullamcorper.
                            </p>
                        </section>
                        <section class="wbnz-sample-page__section">
                            <h2 id="heading-3">Section 3: Usage</h2>
                            <p>
                                Lorem ipsum odor amet, consectetuer adipiscing elit. Tristique facilisis habitasse ante
                                ornare; magna accumsan elit. Laoreet platea eleifend erat torquent elit hac luctus
                                vitae. Mus nullam at auctor potenti senectus. Per purus torquent odio porttitor suscipit
                                eget fermentum quam. Sollicitudin rutrum donec quis platea inceptos senectus quam. Nam
                                sed mattis suspendisse fringilla ridiculus etiam suspendisse at. Nulla adipiscing in id
                                nisl libero penatibus.
                            </p>
                        </section>
                        <section class="wbnz-sample-page__section">
                            <h2 id="heading-4">Section 4: Conclusion</h2>
                            <p>
                                Lorem ipsum odor amet, consectetuer adipiscing elit. Tristique facilisis habitasse ante
                                ornare; magna accumsan elit. Laoreet platea eleifend erat torquent elit hac luctus
                                vitae. Mus nullam at auctor potenti senectus. Per purus torquent odio porttitor suscipit
                                eget fermentum quam. Sollicitudin rutrum donec quis platea inceptos senectus quam. Nam
                                sed mattis suspendisse fringilla ridiculus etiam suspendisse at. Nulla adipiscing in id
                                nisl libero penatibus.
                            </p>
                        </section>
                    </div>
                </div>
            </div>

            <div id="preview" ref="previewContainer" v-else></div>
        </div>
    </div>

    <SignupModal ref="signupModal" :widgetName="widgetName" />
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import UpgradePopup from "../views/components/UpgradePopup.vue";
import { trackEvent } from "../utils/gtag";
import SignupModal from "./SignupModal.vue";

export default {
    components: { UpgradePopup, SignupModal },
    props: {
        widgetName: {
            type: String,
            required: true,
        },
        widgetEndpoint: {
            type: String,
            required: true,
        },
        tabs: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            token: "token",
            id: "id",
            brandingEnabled: "brandingEnabled",
            isAuthenticated: "is_authenticated",
            isPaidUser: "isPaidUser",
        }),
        widgetUrl() {
            return process.env.VUE_APP_WIDGET_URL;
        },
        apiUrl() {
            return process.env.VUE_APP_API_URL;
        },
        rules() {
            return {
                widgetInfo: {
                    name: (value) => {
                        if (!value) return "Name is required";
                        if (value.length < 3) return "Name must be at least 3 characters";
                        if (value.length > 150) return "Name must be less than 150 characters";
                        return "";
                    },
                },
                widgetSettings: {},
            };
        },
        isAnonymous() {
            return !this.token;
        },
        isRestricted() {
            return !(this.isAnonymous || this.isPaidUser);
        },
    },
    watch: {
        widget_info: {
            handler() {
                this.dispatchPreviewData();
            },
            deep: true,
            immediate: false,
        },
        widgetSettings: {
            handler() {
                this.dispatchPreviewData();
            },
            deep: true,
            immediate: false,
        },
    },
    data() {
        return {
            widgetId: this.$route.params.widget_id,

            isSaving: false,

            widgetSettings: {},
            widgetInfo: {},

            errors: { widgetInfo: {}, widgetSettings: {} },

            isCollapsed: false,
            isFloating: false,
            isHidden: window.innerWidth < 768,

            showUpgradePopup: false,
        };
    },
    methods: {
        validateField(objectKey, field) {
            if (this.rules[objectKey]?.[field]) {
                if (!this.errors[objectKey]) this.errors[objectKey] = {};
                this.errors[objectKey][field] = this.rules[objectKey][field](this[objectKey][field]);
            }
        },
        validateForm() {
            this.errors = { widgetInfo: {}, widgetSettings: {} };

            Object.keys(this.rules).forEach((objectKey) => {
                Object.keys(this.rules[objectKey]).forEach((field) => this.validateField(objectKey, field));
            });

            return (
                Object.values(this.errors.widgetInfo).every((err) => !err) &&
                Object.values(this.errors.widgetSettings).every((err) => !err)
            );
        },
        handleProtectedClick() {
            if (this.isRestricted) {
                this.showUpgradePopup = true;
            }
        },
        dispatchPreviewData() {
            window.dispatchEvent(
                new CustomEvent("previewDataUpdated", {
                    detail: { previewData: { settings: this.widgetSettings, widget_info: this.widgetInfo } },
                })
            );
        },
        async read() {
            try {
                const [widgetRes, settingsRes] = await Promise.allSettled([
                    fetch(`${this.apiUrl}widgets/${this.id}/${this.widgetId}?widget_id=${this.widgetId}`, {
                        headers: { Authorization: `Bearer ${this.token}` },
                    }),
                    fetch(`${this.apiUrl}${this.widgetEndpoint}/readByWidgetId/${this.widgetId}`, {
                        headers: { Authorization: `Bearer ${this.token}` },
                    }),
                ]);

                if (widgetRes.status === "fulfilled") {
                    const widgetData = await widgetRes.value.json();
                    this.widgetInfo = widgetData.data;
                }
                if (settingsRes.status === "fulfilled") {
                    this.widgetSettings = await settingsRes.value.json();
                }
            } catch (error) {
                console.error("Error reading widget data:", error);
            }
        },
        async save() {
            const isValid = this.validateForm();
            if (!isValid) return;

            this.isSaving = true;

            try {
                if (this.token && this.token.trim() !== "") {
                    await axios.put(`${this.apiUrl}widgets/${this.widgetId}`, this.widgetInfo, {
                        headers: {
                            Authorization: "Bearer " + this.token,
                        },
                    });
                }

                await axios.post(`${this.apiUrl}${this.widgetEndpoint}/save/${this.widgetId}`, this.widgetSettings, {
                    headers: {
                        Authorization: "Bearer " + this.token,
                    },
                });

                await this.read();
            } catch (error) {
                console.error("Error saving widget info and settings:", error);
            } finally {
                this.isSaving = false;
                this.preview();

                if (!this.isAuthenticated) {
                    this.$refs.signupModal.showModal(this.widgetName);
                }
            }
        },
        checkScreenSize() {
            this.isFloating = window.innerWidth < 768;
            if (!this.isFloating) this.isHidden = false;
        },
        dismissCustomizer() {
            if (this.isFloating) {
                this.isHidden = true;
            } else {
                this.isCollapsed = true;

                document.querySelectorAll(".nav-link.active").forEach((tab) => {
                    tab.classList.remove("active");
                    tab.setAttribute("aria-selected", "false");
                });

                document.querySelectorAll(".tab-pane.show.active").forEach((content) => {
                    content.classList.remove("show", "active");
                });
            }
        },
        expandCustomizer(event) {
            if (event.target.closest(".nav-link")) {
                this.isCollapsed = false;

                if (this.showUpgradePopup) this.showUpgradePopup = false;
            }
        },
        hideCustomizer() {
            this.isHidden = true;
        },
        showCustomizer() {
            this.isHidden = false;
        },
        preview() {
            this.startLoading();

            const previewElement = this.$refs.previewContainer;

            previewElement.innerHTML = `
                <div data-webynize-id="${this.widgetId}" class="wbnz-${this.widgetName}"></div>
            `;

            const widgetScript = document.createElement("script");
            widgetScript.src = `${this.widgetUrl}${this.widgetName}.js`;
            widgetScript.defer = true;

            previewElement.append(widgetScript);

            const handleWidgetPreviewRendered = () => {
                this.stopLoading();
                window.removeEventListener("widgetPreviewRendered", handleWidgetPreviewRendered);
                clearTimeout(fallbackTimeout);
            };

            window.addEventListener("widgetPreviewRendered", handleWidgetPreviewRendered);

            const fallbackTimeout = setTimeout(() => {
                console.warn("Widget preview event did not fire, stopping loading manually.");
                this.stopLoading();
                window.removeEventListener("widgetPreviewRendered", handleWidgetPreviewRendered);
            }, 5000);
        },
        ...mapActions(["startLoading", "stopLoading"]),
        trackEventCustomization() {
            if (this.isAuthenticated) {
                trackEvent("conversion_event_page_view");
            }
        },
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener("resize", this.checkScreenSize);
        this.preview();
        this.read();
        this.trackEventCustomization();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.checkScreenSize);
    },
};
</script>

<style scoped>
.customizer {
    background: #121212;
    padding: 15px;
    border-radius: 10px;
    position: relative;
}

.customizer--floating {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(18, 18, 18, 0.9);
    z-index: 1050;
    display: flex;
    border-radius: 0;
    padding: 10px;
    overflow-y: auto;
    transition: transform 0.3s ease-out;
}

.customizer--hidden {
    transform: translateY(100%);
}

.customizer--collapsed {
    display: inline-block !important;
}

.customizer--collapsed .customizer__nav {
    flex-grow: 1;
    border-right: 1px solid transparent;
    margin-right: 0 !important;
    width: 100%;
}

.customizer--collapsed .nav-link {
    border-radius: 5px !important;
}

.customizer.customizer--collapsed .nav-item.show .nav-link,
.customizer.customizer--collapsed .nav-link.active {
    border-color: #282828 #282828 #282828 #282828;
}

.customizer.customizer--collapsed .nav-link:focus,
.customizer.customizer--collapsed .nav-link:hover {
    isolation: isolate;
    border-color: #282828 #282828 #282828 #282828;
}

.customizer__nav {
    border-right: 1px solid #282828;

    width: 50%;
    height: 100%;
}

.customizer--floating .customizer__nav {
    width: 40%;
}

.customizer .nav-link {
    margin-right: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    border-radius: 0;
    color: #b0b0b0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    text-align: left;
}

.customizer .nav-item.show .nav-link,
.customizer .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #282828 #1e1e1e #282828 #282828;

    color: #ffffff;
    background-color: #1e1e1e;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.customizer .nav-link:focus,
.customizer .nav-link:hover {
    isolation: isolate;
    border-color: #282828 #1e1e1e #282828 #282828;

    color: white;
}

.customizer .tab-content {
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.customizer--floating .tab-content {
    padding: 5px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    padding: 2.5px 5px;
    color: white;
    cursor: pointer;
    z-index: 2;
    transition: background-color 0.2s ease-out;
}

.close-btn:hover {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(30, 30, 30, 0.9);
    border: none;
    border-radius: 8px;
    padding: 2.5px 5px;
    color: white;
    cursor: pointer;
}

.close-btn i {
    font-size: 18px;
    line-height: 1;
}

.customizer .form-grid:not(:last-child) {
    margin-bottom: 15px;
}

/* .customizer .tab-content > .form-grid {
    margin-top: auto;
} */

#preview {
    background-color: #f2f3f5;
    width: 100%;
    min-height: 720px;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    overflow: hidden;
}

.wbnz-sample-page {
    display: flex;
}

.wbnz-sample-page__sidebar {
    width: 40%;
    padding: 20px;
}

.wbnz-sample-page__content {
    width: 60%;
    padding: 20px;
}

.wbnz-sample-page__heading {
    font-size: 2.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.2;
}

.wbnz-sample-page__section {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.wbnz-sample-page__section h2 {
    font-size: 2rem;
    font-weight: 500;
    color: #444;
    margin-bottom: 10px;
    line-height: 1.3;
}

.wbnz-sample-page__section p {
    font-size: 1.125rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

.wbnz-sample-page__sidebar p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
}

.wbnz-sample-page__sidebar a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.wbnz-sample-page__sidebar a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    #preview {
        overflow: hidden;
    }

    .wbnz-sample-page {
        flex-direction: column;
    }

    .wbnz-sample-page__sidebar {
        width: 100%;
        border-right: none;
        border-bottom: 2px solid #e0e0e0;
    }

    .wbnz-sample-page__content {
        width: 100%;
    }

    .wbnz-sample-page__heading {
        font-size: 2rem;
    }

    .wbnz-sample-page__section h2 {
        font-size: 1.75rem;
    }
}

.customizer.customizer--floating {
    max-height: 100%;
}

.tab-content .tab-pane {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.tab-content .tab-pane::-webkit-scrollbar {
    width: 8px;
}

.tab-content .tab-pane::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.tab-content .tab-pane::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.tab-content .tab-pane::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
