<template>
    <div class="form-grid">
        <div class="form-group">
            <label>Name</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetInfo.name"
                id="widget_name"
                @blur="validateField('widgetInfo', 'name')"
            />
            <div v-if="errors.widgetInfo.name" class="invalid-feedback d-flex align-items-center">
                <i class="bi bi-exclamation-circle-fill me-1"></i> {{ errors.widgetInfo.name }}
            </div>
        </div>
        <div class="form-group">
            <label>Notify on</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetSettings.notify_on"
                id="notify_on"
                placeholder="Enter your webhook URL (e.g., https://example.com/webhook)"
            />
        </div>
        <div class="form-group form-grid-span-full">
            <label>Content Text</label>
            <textarea
                v-model="localWidgetSettings.content_text"
                class="form-control"
                name="content_text"
                id="content_text"
                cols="30"
                rows="10"
            ></textarea>
        </div>
        <div class="form-group form-grid-span-full">
            <label>Popup Text</label>
            <textarea
                v-model="localWidgetSettings.popup_text"
                class="form-control"
                name="popup_text"
                id="popup_text"
                cols="30"
                rows="10"
            ></textarea>
        </div>
        <div class="form-group">
            <label>Vote Button Text</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetSettings.vote_button_text"
                id="vote_button_text"
            />
        </div>
        <div class="form-group">
            <label>Submit Button Text</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetSettings.submit_button_text"
                id="submit_button_text"
            />
        </div>
        <div class="form-group">
            <label>Cancel Button Text</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetSettings.cancel_button_text"
                id="cancel_button_text"
            />
        </div>
        <div v-if="!brandingEnabled" class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="show_branding"
                    v-model="localWidgetInfo.show_branding"
                    true-value="1"
                    false-value="0"
                    :value="1"
                />
                <label class="form-check-label" for="show_branding">Show Branding</label>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        widgetSettings: { type: Object, required: true },
        widgetInfo: { type: Object, required: true },
        errors: { type: Object, required: true },
        validateField: { type: Function, required: false },
    },
    emits: ["update:widgetSettings", "update:widgetInfo"],
    computed: {
        ...mapGetters({
            brandingEnabled: "brandingEnabled",
        }),
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
        localWidgetInfo: {
            get() {
                return this.widgetInfo;
            },
            set(newInfo) {
                this.$emit("update:widgetInfo", newInfo);
            },
        },
        localErrors: {
            get() {
                return this.errors;
            },
            set(newErrors) {
                this.$emit("update:errors", newErrors);
            },
        },
    },
};
</script>
