<template>
    <div class="form-grid">
        <div class="form-group form-grid-span-full">
            <label>Name</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetInfo.name"
                id="widget_name"
                @blur="validateField('widgetInfo', 'name')"
            />
            <div v-if="errors.widgetInfo.name" class="invalid-feedback d-flex align-items-center">
                <i class="bi bi-exclamation-circle-fill me-1"></i> {{ errors.widgetInfo.name }}
            </div>
        </div>
        <div class="form-group">
            <button type="button" class="btn btn-primary" @click="goToAnnouncementsTab">Manage Announcements</button>
        </div>
        <div class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="open_in_new_tab"
                    v-model="localWidgetSettings.open_in_new_tab"
                    true-value="1"
                    false-value="0"
                    :value="1"
                />
                <label class="form-check-label" for="open_in_new_tab">Open in new tab</label>
            </div>
        </div>
        <div class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="allow_close"
                    v-model="localWidgetSettings.allow_close"
                    true-value="1"
                    false-value="0"
                    :value="1"
                />
                <label class="form-check-label" for="allow_close">Allow Close</label>
            </div>
        </div>
        <div class="form-group">
            <label for="item_transition_interval">Item Transition Interval (Seconds)</label>
            <input
                type="number"
                class="form-control"
                v-model="localWidgetSettings.item_transition_interval"
                id="item_transition_interval"
                placeholder="Enter transition duration (e.g., 5)"
                min="1"
                step="1"
            />
            <div
                v-if="localWidgetSettings.item_transition_interval && localWidgetSettings.item_transition_interval <= 0"
                class="text-danger"
            >
                <small>Transition interval must be a positive number.</small>
            </div>
        </div>
        <div class="form-group">
            <label>Show Again After (Number of Days)</label>
            <input
                type="number"
                class="form-control"
                v-model="localWidgetSettings.days_until_next_display"
                id="days_until_next_display"
                placeholder="Enter days (e.g., 1)"
            />
        </div>
        <div v-if="!brandingEnabled" class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="show_branding"
                    v-model="localWidgetInfo.show_branding"
                    true-value="1"
                    false-value="0"
                    :value="1"
                />
                <label class="form-check-label" for="show_branding">Show Branding</label>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        widgetSettings: { type: Object, required: true },
        widgetInfo: { type: Object, required: true },
        errors: { type: Object, required: true },
        validateField: { type: Function, required: false },
    },
    emits: ["update:widgetSettings", "update:widgetInfo", "update:errors"],
    computed: {
        ...mapGetters({
            brandingEnabled: "brandingEnabled",
        }),
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
        localWidgetInfo: {
            get() {
                return this.widgetInfo;
            },
            set(newInfo) {
                this.$emit("update:widgetInfo", newInfo);
            },
        },
        localErrors: {
            get() {
                return this.errors;
            },
            set(newErrors) {
                this.$emit("update:errors", newErrors);
            },
        },
    },
    methods: {
        goToAnnouncementsTab() {
            const index = 1;
            if (this.$parent.$refs.tabRefs[index]) {
                this.$parent.$refs.tabRefs[index].click();
            }
        },
    },
};
</script>
