<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Content Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.background_color"
                    type="text"
                    id="background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Title Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.title_font_color"
                    type="text"
                    id="title_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.title_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Description Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.description_font_color"
                    type="text"
                    id="description_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.description_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Button Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.button_background_color"
                    type="text"
                    id="button_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.button_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Button Icon Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.button_icon_color"
                    type="text"
                    id="button_icon_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.button_icon_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Branding color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.branding_color"
                    type="text"
                    id="branding_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.branding_color" :disabled="isRestricted" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
