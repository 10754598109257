<template>
    <div ref="pickr"></div>
</template>

<script>
import Pickr from "@simonwep/pickr";
import "/node_modules/@simonwep/pickr/dist/pickr.min.css";

export default {
    name: "PickrColorPicker",
    props: {
        modelValue: {
            type: String,
            default: "#000000",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        this.pickr = Pickr.create({
            el: this.$refs.pickr,
            theme: "classic",
            default: this.modelValue,
            defaultRepresentation: "HEX",
            components: {
                preview: true,
                opacity: true,
                hue: true,
                interaction: {
                    hex: true,
                    rgba: true,
                    hsla: true,
                    input: true,
                },
            },
            position: "dynamic",
        });

        this.pickr.on("init", () => {
            this.pickr.setColor(this.modelValue);
        });

        this.pickr.on("init", () => {
            this.pickr.setColor(this.modelValue);
            if (this.disabled) {
                this.pickr.disable();
            }
        });

        this.pickr.on("change", (color) => {
            const hexColor = color.toHEXA().toString();
            this.$emit("update:modelValue", hexColor);
            this.$emit("input", hexColor);
        });

        this.$watch("modelValue", (newValue) => {
            this.pickr.setColor(newValue);
        });
    },
    beforeUnmount() {
        if (this.pickr) {
            this.pickr.destroyAndRemove();
        }
    },
};
</script>

<style scoped>
.pcr-app {
    z-index: 9999;
}
</style>
