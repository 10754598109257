<template>
    <div :class="['avatar', sizeClass]">
        <img :src="src" :alt="alt" />
    </div>
</template>

<script>
export default {
    name: "BaseAvatar",
    props: {
        src: {
            type: String,
            required: true,
        },
        alt: {
            type: String,
            default: "Avatar",
        },
        size: {
            type: String,
            default: "md",
            validator: (value) => ["xs", "sm", "md", "lg", "xl"].includes(value),
        },
    },
    computed: {
        sizeClass() {
            return this.size ? `avatar-${this.size}` : "";
        },
    },
};
</script>

<style scoped>
.avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    vertical-align: middle;
    border-radius: 50%;
    overflow: hidden;
    width: var(--avatar-size, 40px);
    height: var(--avatar-size, 40px);
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-xs {
    --avatar-size: 24px;
}

.avatar-sm {
    --avatar-size: 32px;
}

.avatar-md {
    --avatar-size: 40px;
}

.avatar-lg {
    --avatar-size: 56px;
}

.avatar-xl {
    --avatar-size: 72px;
}
</style>
