<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Padding</label>
            <input
                v-model="localWidgetSettings.padding"
                type="number"
                id="qf-tb-padding"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Border radius</label>
            <input
                v-model="localWidgetSettings.border_radius"
                type="number"
                id="qf-tb-padding"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="show-on">Show on</label>
            <div class="position-relative">
                <select class="form-select pr-5" v-model="localWidgetSettings.show_on" id="show-on">
                    <option value="mouseover">Hover</option>
                    <option value="click">Click</option>
                </select>
                <span
                    class="dropdown-icon position-absolute"
                    style="top: 50%; right: 10px; transform: translateY(-50%)"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M7.247 11.14l-4.796-5.481A.5.5 0 0 1 2.5 5h11a.5.5 0 0 1 .388.84l-4.796 5.48a.5.5 0 0 1-.776 0z"
                        />
                    </svg>
                </span>
            </div>
        </div>
        <div class="form-group">
            <label>Notify on (Slack or Google chat webhook URL)</label>
            <input type="text" class="form-control" v-model="localWidgetSettings.notify_on" id="notify-on" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
