<template>
    <div class="form-grid">
        <div class="form-group">
            <button type="button" class="btn btn-primary" @click="showCreateModal">Create Changelog</button>
        </div>
        <div class="form-group">
            <input
                type="text"
                class="form-control"
                placeholder="Search changelogs..."
                v-model="searchQuery"
                @input="filterItems"
            />
        </div>
        <div class="form-group form-grid-span-full">
            <Table :items="items" :pagination="pagination" />
        </div>
    </div>

    <Create ref="createComponent" />
    <Edit ref="editComponent" />
    <Delete ref="deleteComponent" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Table from "../Table.vue";
import Create from "../Create.vue";
import Edit from "../Edit.vue";
import Delete from "../Delete.vue";

export default {
    components: {
        Table,
        Create,
        Edit,
        Delete,
    },
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings", "update-preview"],
    data() {
        return {
            widgetId: this.$route.params.widget_id,
            items: [],
            pagination: [],
            searchQuery: "",
            timeoutId: null,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            token: "token",
        }),
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        updatePreview() {
            this.$emit("update-preview");
        },
        showCreateModal() {
            this.$refs.createComponent.showModal();
        },
        showEditModal(event, item) {
            this.$refs.editComponent.showModal(event, item);
        },
        showDeleteModal(event, id) {
            this.$refs.deleteComponent.showModal(event, id);
        },
        filterItems() {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(() => {
                this.fetchItems();
            }, 300);
        },
        changePage(page) {
            this.fetchItems(page);
            this.$parent.preview();
        },
        async fetchItems(page = 1) {
            this.loading = true;

            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API_URL}changelog-items?page=${page}&search=${this.searchQuery}&widget_id=${this.widgetId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                );

                this.items = response.data.data;
                this.pagination = response.data.meta;
            } catch (error) {
                console.error("Error fetching changelogs:", error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchItems();
    },
};
</script>
