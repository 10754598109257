<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Tour start title font size</label>
            <input
                v-model="localWidgetSettings.tour_start_title_font_size"
                type="number"
                id="tour_start_title_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Tour end title font size</label>
            <input
                v-model="localWidgetSettings.tour_end_title_font_size"
                type="number"
                id="tour_end_title_font_size"
                class="form-control setting"
            />
        </div>
        <div class="form-group">
            <label for="">Font size</label>
            <input v-model="localWidgetSettings.font_size" type="number" id="font_size" class="form-control setting" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
