<template>
    <div
        class="modal"
        id="deleteItemModal"
        tabindex="-1"
        aria-labelledby="deleteItemModalLabel"
        aria-hidden="true"
        ref="deleteModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                    <button @click.prevent="deleteItem($event)" type="button" class="btn btn-primary">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            data: {
                widget_id: this.$route.params.widget_id,
            },
        };
    },
    methods: {
        async deleteItem() {
            event.preventDefault();
            try {
                const response = await axios.delete(
                    `${process.env.VUE_APP_API_URL}cancellation-form-items/${this.id}?widget_id=${this.data.widget_id}`,
                    {
                        headers: { Authorization: "Bearer " + this.token },
                    }
                );

                console.log("Item Deleted:", response.data);

                const modalInstance = Modal.getInstance(this.$refs.deleteModal);
                modalInstance.hide();

                this.$emit("deleted-item");
                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error deleting item:", error);
            }
        },
        showModal(event, id) {
            this.id = id;
            const modalInstance = new Modal(this.$refs.deleteModal);
            modalInstance.show();
        },
    },
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
