<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Plan Button Background Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_button_background_color"
                    type="text"
                    id="plan_button_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="!isPaidUser"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.plan_button_background_color"
                    :disabled="!isPaidUser"
                />
            </div>
        </div>

        <div class="form-group">
            <label for="">Plan Button Font Color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.plan_button_font_color"
                    type="text"
                    id="plan_button_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="!isPaidUser"
                />
                <pickr-color-picker v-model="localWidgetSettings.plan_button_font_color" :disabled="!isPaidUser" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    data() {
        return {
            showUpgradePopup: false,
        };
    },
    computed: {
        ...mapGetters({
            isPaidUser: "isPaidUser",
        }),
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick(event) {
            if (!this.isPaidUser) {
                event.preventDefault();
                this.$emit("show-upgrade");
            }
        },
    },
};
</script>
