<template>
    <div class="col-sm-9">
        <div class="d-flex flex-wrap justify-content-between mb-3 flex-column flex-sm-row align-items-lg-center gap">
            <h4 class="me-auto">Plan Settings</h4>
        </div>

        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>

        <div class="table-responsive bg-white p-20 rounded" v-else>
            <ul class="nav nav-tabs" id="planTabs" role="tablist">
                <li class="nav-item" v-for="(planSlug, index) in planSlugs" :key="index" role="presentation">
                    <a
                        class="nav-link"
                        :id="`${planSlug}-tab`"
                        data-bs-toggle="tab"
                        :href="`#${planSlug}`"
                        role="tab"
                        :aria-controls="planSlug"
                        :aria-selected="activeTab === planSlug"
                        :class="{ active: activeTab === planSlug }"
                        @click="setActiveTab(planSlug)"
                    >
                        {{ planSlug.charAt(0).toUpperCase() + planSlug.slice(1) }}
                    </a>
                </li>
            </ul>

            <div class="tab-content mt-3">
                <div
                    v-for="(planSlug, index) in planSlugs"
                    :key="index"
                    class="tab-pane fade"
                    :id="planSlug"
                    role="tabpanel"
                    :aria-labelledby="`${planSlug}-tab`"
                    :class="{ show: activeTab === planSlug, active: activeTab === planSlug }"
                >
                    <form @submit.prevent="savePlanSettings">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                :id="`${planSlug}Status`"
                                v-model="plan[planSlug].status"
                            />
                            <label class="form-check-label" :for="`${planSlug}Status`">
                                Enable {{ planSlug.charAt(0).toUpperCase() + planSlug.slice(1) }} Plan
                            </label>
                        </div>
                        <div class="mb-3">
                            <label :for="`${planSlug}Desc`" class="form-label">Plan Description</label>
                            <QuillEditor v-model="plan[planSlug].description" />
                        </div>
                        <div class="mb-3">
                            <label :for="`${planSlug}Features`" class="form-label">Plan Features</label>
                            <textarea
                                class="form-control"
                                :id="`${planSlug}Features`"
                                v-model="plan[planSlug].features"
                                placeholder="Enter plan features"
                                style="min-height: 300px;"
                            ></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary">
                            Save {{ planSlug.charAt(0).toUpperCase() + planSlug.slice(1) }} Plan Settings
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuillEditor from '@/components/QuillEditor.vue';
import axios from 'axios';

export default {
    components: {
        QuillEditor
    },
    data() {
        return {
            loading: false,
            activeTab: 'free',
            planSlugs: ['free', 'starter', 'professional', 'enterprise'],
            plan: {
                free: { status: false, description: '', features: '' },
                starter: { status: false, description: '', features: '' },
                professional: { status: false, description: '', features: '' },
                enterprise: { status: false, description: '', features: '' }
            }
        };
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab;
        },
        async savePlanSettings() {
            this.loading = true;

            try {
                const requestData = this.planSlugs.reduce((acc, planSlug) => {
                    acc[`${planSlug}-desc`] = this.plan[planSlug].description;
                    acc[`${planSlug}-status`] = this.plan[planSlug].status;
                    acc[`${planSlug}-features`] = this.plan[planSlug].features;
                    return acc;
                }, {});

                const response = await axios.post('plans/save-settings', requestData);

                if (response.data.success) {
                    console.log('Settings saved successfully!');
                } else {
                    console.log('Error saving settings!');
                }
            } catch (error) {
                console.error('Error saving settings:', error);
                console.log('Failed to save settings.');
            } finally {
                this.loading = false;
            }
        },
        getPlanSlug(planId) {
            const slugs = {
                1: 'free',
                2: 'starter',
                3: 'professional',
                4: 'enterprise'
            };
            return slugs[planId] || null;
        },
        async fetchPlanSettings() {
            try {
                this.loading = true;
                const response = await axios.get('plans/get-settings');

                // Log the response to inspect the structure
                console.log(response.data);

                // Populate plan data using plan ID as the mapping key
                const data = response.data;
                if (data && Array.isArray(data)) {
                    data.forEach(plan => {
                        const planSlug = this.getPlanSlug(plan.id); // Map ID to slug using a helper method
                        if (planSlug) {
                            this.plan[planSlug] = {
                                status: plan.status || false,
                                description: plan.description || '',
                                features: plan.features || ''
                            };
                        }
                    });
                }
            } catch (error) {
                console.error('Error fetching settings:', error);
            } finally {
                this.loading = false;
            }
        }
    },
    async created() {
        await this.fetchPlanSettings();
    }
};
</script>