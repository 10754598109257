<template>
    <div class="table-responsive">
        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>
        <table class="table table-dark table-hover table-striped align-middle rounded-1 overflow-hidden" v-else>
            <thead v-if="!items || items.length < 1">
                <tr>
                    No data found.
                </tr>
            </thead>
            <thead v-else>
                <tr>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td>
                        {{ item.item_name }}
                        <div class="d-flex gap-2 mt-2">
                            <button
                                class="btn btn-primary btn-sm flex-grow-1"
                                @click.prevent="this.$parent.showEditModal(event, item)"
                            >
                                <i class="fas fa-pen d-inline d-sm-none"></i>
                                <span class="d-none d-sm-inline">Edit</span>
                            </button>
                            <button
                                class="btn btn-danger btn-sm flex-grow-1"
                                @click.prevent="this.$parent.showDeleteModal(event, item.id)"
                            >
                                <i class="bi bi-trash d-sm-none"></i>
                                <span class="d-none d-sm-inline">Delete</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav aria-label="Page navigation" v-if="items && items.length < 0">
            <ul class="pagination">
                <li class="page-item" :class="{ disabled: !pagination.prev_page_url }">
                    <button class="page-link" @click="changePage(pagination.current_page - 1)">Previous</button>
                </li>
                <li
                    class="page-item"
                    v-for="page in pagination.last_page"
                    :key="page"
                    :class="{ active: pagination.current_page === page }"
                >
                    <button class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: !pagination.next_page_url }">
                    <button class="page-link" @click="changePage(pagination.current_page + 1)">Next</button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    components: {},

    props: {
        items: {
            type: Array,
            required: true,
        },
        pagination: {
            type: Object,
            required: true,
        },
        loading: { type: Boolean, default: false },
    },
    methods: {
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString();
        },
        changePage(page) {
            if (page > 0 && page <= this.pagination.last_page) {
                this.$parent.changePage(page);
            }
        },
        truncate(text, length = 20) {
            if (!text) return "";
            return text.length > length ? text.substring(0, length) + "..." : text;
        },
    },
    mounted() {},
};
</script>

<style scoped></style>
