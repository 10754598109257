<template>
    <!-- create modal -->
    <div
        class="modal fade"
        id="create_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        Create Product Update Item
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <input name="id" v-model="id" type="hidden" />
                    <div>Title</div>
                    <div class="mb-2" v-if="submitted && !title"><small class="text-danger">Please provide a title</small></div>
                    <input
                        id="title"
                        name="title"
                        v-model="title"
                        type="text"
                        class="form-control mb-3"
                    />
                    <div>Product Update</div>
                    <div class="mb-2" v-if="submitted && (!ql_product_update.root.innerText || !ql_product_update.root.innerText.trim())"><small class="text-danger">Please provide an product update</small></div>
                    <div id="product_update" class="quill-textbox"></div>
                    <div class="mt-3">Publish date</div>
                    <div class="mb-2" v-if="submitted && !publish_date"><small class="text-danger">Please provide a publish date</small></div>
                    <input 
                        ref="datepickerInput"
                        class="publish_date form-control" 
                        id="publish_date"
                        placeholder="m/d/y"
                        readonly
                    />
                    <div class="mt-3">Status</div>
                    <select 
                        v-model="status"
                        class="form-select" 
                        name="status" 
                        id="status"
                    >
                        <option value="0" selected>Draft</option>
                        <option value="1">Publish</option>
                    </select>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        @click="create($event)"
                        type="button"
                        class="btn btn-primary"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .quill-textbox {
        height: 375px;
    }
</style>
<script>
import axios from "axios";
import { Modal } from "bootstrap";
import datepicker from 'js-datepicker'
import { Quill } from '@vueup/vue-quill';
import 'quill/dist/quill.snow.css';
export default {
    data() {
        return {
            id: "",
            title: "",
            ql_product_update: "",
            publish_date: "",
            status: 0,
            create_modal: null,
            submitted: false,
        };
    },
    methods: {
        showModal() {
            this.create_modal.show();
            this.resetFields();
        },
        resetFields() {
            this.id = "";
            this.title = "";
            this.ql_product_update.root.innerHTML = '';
            this.publish_date = "";
            this.status = 0;
            const datepickerInputUpdate = this.$refs.datepickerInput;
            datepickerInputUpdate.value = "";
        },
        async create(event) {
            var data = {
                title : this.title,
                product_update: this.ql_product_update.root.innerHTML,
                publish_date: this.publish_date,
                status: this.status,
                widget_id: this.$route.params.widget_id
            }
            
            if (!data.title 
                || (!this.ql_product_update.root.innerText || !this.ql_product_update.root.innerText.trim()) 
                || !data.publish_date
            ) {
                this.submitted = true;
                return;
            }

            event.target.disabled = true;

            axios
                .post(
                    process.env.VUE_APP_API_URL + "product-update-items",
                    data,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.getters.logged_in_user.data.token,
                        },
                    }
                )
                .then(
                    () => {
                        event.target.disabled = false;
                        this.create_modal.hide();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            this.$parent.fetchItems();
            this.$parent.updatePreview();
        },
        formatDate (date) {
            const currentDate = new Date(date);
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async onpasteHandler(editors) {
            for (let index = 0; index < editors.length; index++) {
                const element = editors[index];
                document.getElementById(element).addEventListener('paste', function () {
                    setTimeout(() => {
                        let obj = document.getElementById(element).querySelectorAll('[src*="data:image/png;base64"]');
                        console.log(obj.length);
                        if (obj.length > 0) {
                            obj.forEach(image => {
                                image.remove();
                            });
                            alert('Pasting image is disabled.');
                        }
                    }, 100);
                });
            }
        }
    },
    mounted() {
        var __this = this;
        var toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike', 'link', 'image'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ];
        var toolbar = {
            modules: {
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                        image: imageHandler
                    }
                }
            },
            theme: 'snow'
        };
        function imageHandler() {
            var range = this.quill.getSelection();
            var value = prompt('Please enter the image URL');
            if(value){
                this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
            }
        }

        __this.ql_product_update = new Quill(document.getElementById("product_update"), toolbar);
        const datepickerInput = this.$refs.datepickerInput;
        const picker = datepicker(datepickerInput, {
            formatter: (input, date) => {
                const value = __this.formatDate(date);
                input.value = value
            }
        })
        picker.onSelect = (instance, date) => {
            __this.publish_date = __this.formatDate(date);
        };
        let editors = ['product_update']
        __this.onpasteHandler(editors)
        __this.create_modal = new Modal(document.getElementById("create_modal"));
    },
};
</script>

<style scoped>
.modal-content {
    color: #212529;
}
</style>
