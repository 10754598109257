<template>
    <div class="modal right fade" id="edit_modal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-side">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editModalLabel">Update billing</h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        @click="handleModalClose"
                    ></button>
                </div>

                <div v-if="loading" class="loading-overlay-alt">
                    <div class="spinner"></div>
                </div>
                <div class="modal-body" v-else>
                    <iframe
                        v-if="iframeSrc"
                        class="paddle-frame paddle-frame-inline"
                        name="paddle_frame"
                        style="border: none; background: transparent; display: block; margin: auto"
                        :src="iframeSrc"
                        frameborder="0"
                        width="100%"
                        height="100%"
                        allow="payment"
                    ></iframe>
                    <p v-else class="alert alert-warning text-center">
                        <i class="bi bi-exclamation-triangle"></i>
                        No payment update link available. Please contact support.
                    </p>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            loading: true,
            Paddle: window.Paddle,
            userData: {},
        };
    },
    computed: {
        iframeSrc() {
            const baseUrl = this.userData.update_payment_url;
            if (!baseUrl) return null;
            const separator = baseUrl.includes("?") ? "&" : "?";
            return `${baseUrl}${separator}display_mode=inline`;
        },
    },
    methods: {
        async update(event) {
            event.target.disabled = true;
        },
        async showEditModal(event, userData) {
            event.target.disabled = true;
            this.userData = userData;
            this.edit_modal.show();
            event.target.disabled = false;
            this.loading = false;
        },
        handleModalClose() {
            this.$parent.$refs.readComponent.fetchUserData();
        },
    },
    mounted() {
        this.edit_modal = new Modal(document.getElementById("edit_modal"));

        const modalElement = document.getElementById("edit_modal");
        modalElement.addEventListener("hidden.bs.modal", this.handleModalClose);
    },
};
</script>

<style scoped>
.modal.right .modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    height: 100%;
    max-width: 400px;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
}

.modal.right.show .modal-dialog {
    transform: translateX(0);
}

.modal.right .modal-dialog .modal-content {
    height: 100%;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
</style>
