<template>
    <div
        class="modal"
        id="createFeaturedArticleItemModal"
        tabindex="-1"
        aria-labelledby="createFeaturedArticleItemModalLabel"
        aria-hidden="true"
        ref="createModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createFeaturedArticleItemModalLabel">Create Article Item</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <div class="mb-3">
                            <label for="articleTitle" class="form-label">Title</label>
                            <input
                                type="text"
                                class="form-control"
                                id="articleTitle"
                                v-model="data.title"
                                required
                                placeholder="Enter the title"
                                @blur="validateField('title')"
                            />
                            <p v-if="!validations.title" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="articleDescription" class="form-label">Description</label>
                            <textarea
                                class="form-control"
                                id="articleDescription"
                                v-model="data.description"
                                rows="3"
                                placeholder="Enter the description"
                                required
                                @blur="validateField('description')"
                            ></textarea>
                            <p v-if="!validations.description" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="articleAuthor" class="form-label">Author</label>
                            <input
                                type="text"
                                class="form-control"
                                id="articleAuthor"
                                v-model="data.author"
                                required
                                placeholder="Enter the author"
                                @blur="validateField('author')"
                            />
                            <p v-if="!validations.author" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="articleImageUrl" class="form-label">Image URL</label>
                            <input
                                type="text"
                                class="form-control"
                                id="articleImageUrl"
                                v-model="data.image_url"
                                required
                                placeholder="Enter the image url"
                                @blur="validateField('image_url')"
                            />
                            <p v-if="!validations.image_url" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="articleURL" class="form-label">Link to</label>
                            <input
                                type="text"
                                class="form-control"
                                id="articleURL"
                                v-model="data.url"
                                required
                                placeholder="Enter the url"
                                @blur="validateField('url')"
                            />
                            <p v-if="!validations.url" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="articlePublishedDate" class="form-label">Published Date</label>
                            <input
                                id="articlePublishedDate"
                                class="form-control"
                                type="date"
                                v-model="data.published_date"
                                required
                                @blur="validateField('published_date')"
                            />
                            <p v-if="!validations.published_date" class="text-danger small">This field is required.</p>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" @click="submitForm">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            data: {
                title: "",
                description: "",
                author: "",
                image_url: "",
                url: "",
                published_date: "",
                widget_id: this.$route.params.widget_id,
            },
            validations: {
                title: true,
                description: true,
                author: true,
                image_url: true,
                url: true,
                publish_date: true,
            },
        };
    },
    computed: {
        isFormValid() {
            return Object.values(this.validations).every((isValid) => isValid);
        },
    },
    methods: {
        async submitForm(event) {
            event.preventDefault();
            this.validateAllFields();

            if (!this.isFormValid) {
                console.warn("Form is invalid");
                return;
            }

            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}featured-article-items`, this.data, {
                    headers: { Authorization: "Bearer " + this.token },
                });

                console.log("Article Item Created:", response.data);
                this.clearForm();
                const modalInstance = Modal.getInstance(this.$refs.createModal);
                modalInstance.hide();
                this.$emit("created-article");
                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error creating article item:", error);
            }
        },
        clearForm() {
            this.data = {
                title: "",
                description: "",
                author: "",
                image_url: "",
                url: "",
                published_date: "",
                widget_id: this.$route.params.widget_id,
            };
        },
        validateField(field) {
            console.log(this.data[field]);
            if (this.data[field] === "") {
                this.validations[field] = false;
            } else {
                this.validations[field] = true;
            }
        },
        validateAllFields() {
            Object.keys(this.validations).forEach((field) => {
                this.validateField(field);
            });
        },
        showModal() {
            const modalInstance = new Modal(this.$refs.createModal);
            modalInstance.show();
        },
    },
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
