<template>
    <BaseModal ref="modal" title="Edit User" :loading="isFetching" @save="update">
        <input name="id" v-model="user.id" type="hidden" />

        <div class="mb-3">
            <label class="form-label">Name</label>
            <input name="name" v-model="user.name" type="text" class="form-control" />
        </div>

        <div class="mb-3">
            <label class="form-label">Email</label>
            <input name="email" v-model="user.email" type="text" class="form-control" />
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label">Access Level</label>
                <select v-model="user.access_level" class="form-select">
                    <option value="customer">Customer</option>
                    <option value="support">Support</option>
                    <option value="admin">Admin</option>
                </select>
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">Status</label>
                <select v-model="user.status" class="form-select">
                    <option value="13">Free</option>
                    <option value="1">Paid</option>
                    <option value="6">Premium Trial</option>
                    <option value="7">To Cancel</option>
                    <option value="10">Deleted</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label">Widget Limit</label>
                <input name="widget_limit" v-model="user.widget_limit" type="number" class="form-control" />
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">View Limit</label>
                <input name="view_limit" v-model="user.view_limit" type="number" class="form-control" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label">Cost</label>
                <input name="cost" v-model="user.cost" type="number" step="0.01" class="form-control" />
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">Cycle</label>
                <select v-model="user.cycle" class="form-select">
                    <option value="0">Monthly</option>
                    <option value="1">Yearly</option>
                </select>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">Plan</label>
            <select v-model="user.plan" class="form-select mb-1" aria-label="Select Plan">
                <option value="" disabled>Select a plan</option>
                <option value="free" :title="'This is a free plan'" class="plan-option">Free Plan</option>
                <option v-for="p in plans" :key="p.id" :value="p.id" :title="p.description" class="plan-option">
                    {{ p.name }} - {{ p.description }}
                </option>
            </select>

            <p v-if="isInvalidPlanSelection" class="text-danger small">You cannot choose Free Plan with Paid Status.</p>
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label">Trial Starts At</label>
                <VueDatePicker v-model="user.trial_starts_at" format="yyyy-MM-dd" />
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">Trial Ends At</label>
                <VueDatePicker v-model="user.trial_ends_at" format="yyyy-MM-dd" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label">Cancel Payment URL</label>
                <input name="cancel_payment_url" v-model="user.cancel_payment_url" type="url" class="form-control" />
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">Update Payment URL</label>
                <input name="update_payment_url" v-model="user.update_payment_url" type="url" class="form-control" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label">Activation Date</label>
                <VueDatePicker v-model="user.activation_date" format="yyyy-MM-dd" />
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">Re-Activation Date</label>
                <VueDatePicker v-model="user.re_activation_date" format="yyyy-MM-dd" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-3">
                <label class="form-label">Cancellation Request Date</label>
                <VueDatePicker v-model="user.cancellation_request_date" format="yyyy-MM-dd" />
            </div>

            <div class="col-md-6 mb-3">
                <label class="form-label">Cancellation Date</label>
                <VueDatePicker v-model="user.cancellation_date" format="yyyy-MM-dd" />
            </div>
        </div>
    </BaseModal>
</template>

<script>
import axios from "axios";
import store from "../../../store";
import BaseModal from "../../../components/BaseModal.vue";

export default {
    props: ["plans"],
    components: { BaseModal },
    data() {
        return {
            user: this.getDefaultUser(),
            isFetching: false,
        };
    },
    computed: {
        isInvalidPlanSelection() {
            return this.user.status == "1" && this.user.plan === "free";
        },
    },
    methods: {
        getDefaultUser() {
            return {
                id: "",
                name: "",
                email: "",
                access_level: "",
                status: "",
                widget_limit: "",
                view_limit: "",
                cost: "",
                cycle: "0",
                plan: null,

                subscription_id: "",
                trial_starts_at: "",
                trial_ends_at: "",
                cancel_payment_url: "",
                update_payment_url: "",
                activation_date: "",
                re_activation_date: "",
                cancellation_request_date: "",
                cancellation_date: "",
            };
        },
        async update(done, stop) {
            if (this.isInvalidPlanSelection) {
                alert("You cannot choose Free Plan with Paid Status.");
                stop();
                return;
            }

            try {
                let payload = { ...this.user };

                if (this.user.plan === "free") {
                    payload.plan = null;
                }

                const response = await axios.post(`${process.env.VUE_APP_API_URL}users/update`, payload, {
                    headers: { Authorization: `Bearer ${store.getters.logged_in_user.data.token}` },
                });

                if (response.data.success) {
                    this.$parent.$refs.readComponent.read();
                    const updatedUser = response.data.user;

                    if (updatedUser.id === store.getters.logged_in_user.data.user.id) {
                        this.$store.commit("updateUser", updatedUser);
                    }
                }
            } catch (error) {
                console.error("Error updating user:", error);
            } finally {
                done();
            }
        },
        async fetchUser(id) {
            this.isFetching = true;
            this.user = this.getDefaultUser();
            this.$refs.modal.show();

            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}users/read/${id}`, {
                    headers: { Authorization: `Bearer ${store.getters.logged_in_user.data.token}` },
                });
                const fetchedUser = await response.json();

                this.user.id = fetchedUser.id || "";
                this.user.name = fetchedUser.name || "";
                this.user.email = fetchedUser.email || "";
                this.user.access_level = fetchedUser.access_level || "";
                this.user.status = fetchedUser.status || "";
                this.user.widget_limit = fetchedUser.widget_limit || "";
                this.user.view_limit = fetchedUser.view_limit || "";
                this.user.cost = fetchedUser.cost || "";
                this.user.cycle = fetchedUser.cycle || "0";
                this.user.plan = fetchedUser.plan || "free";
                this.user.subscription_id = fetchedUser.subscription_id || "";
                this.user.trial_starts_at = fetchedUser.trial_starts_at || "";
                this.user.trial_ends_at = fetchedUser.trial_ends_at || "";
                this.user.cancel_payment_url = fetchedUser.cancel_payment_url || "";
                this.user.update_payment_url = fetchedUser.update_payment_url || "";
                this.user.activation_date = fetchedUser.activation_date || "";
                this.user.re_activation_date = fetchedUser.re_activation_date || "";
                this.user.cancellation_request_date = fetchedUser.cancellation_request_date || "";
                this.user.cancellation_date = fetchedUser.cancellation_date || "";
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                this.isFetching = false;
            }
        },
        async showEditModal(event, id) {
            event.target.disabled = true;
            await this.fetchUser(id);
            event.target.disabled = false;
        },
    },
};
</script>
