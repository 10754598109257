<template>
    <div class="container">
        <div class="d-flex flex-wrap justify-content-between mb-3 flex-column flex-sm-row align-items-lg-center gap">
            <h4 class="me-auto">Customize Quick Feedback widget</h4>
            <button
                class="btn btn-link p-2 text-dark fs-5 text-decoration-none"
                @click="showAnalyticsModal(widgetInfo.name)"
                :disabled="!widgetInfo.name"
            >
                <i class="bi bi-bar-chart me-1"></i> Analytics
            </button>
            <embed-code className="wbnz-quick-feedback" widgetName="quick-feedback" />
        </div>

        <customizer widgetName="quick-feedback" widgetEndpoint="quick-feedbacks" :tabs="tabs" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import Basic from "./tabs/Basic.vue";
import Colors from "./tabs/Colors.vue";
import Spacings from "./tabs/Spacings.vue";
import CSS from "./tabs/CSS.vue";
import Texts from "./tabs/Texts.vue";

export default {
    computed: {
        ...mapGetters({
            token: "token",
            id: "id",
        }),
    },
    data() {
        return {
            widgetId: this.$route.params.widget_id,
            widgetInfo: {},
            tabs: [
                {
                    id: "tab-basic",
                    label: "Basic",
                    icon: "bi bi-tools",
                    component: Basic,
                },
                {
                    id: "tab-colors",
                    label: "Colors",
                    icon: "bi bi-palette",
                    component: Colors,
                },
                {
                    id: "tab-texts",
                    label: "Texts",
                    icon: "bi bi-fonts",
                    component: Texts,
                },
                {
                    id: "tab-spacings",
                    label: "Spacings",
                    icon: "bi bi-arrows-expand",
                    component: Spacings,
                },
                {
                    id: "tab-css",
                    label: "CSS",
                    icon: "bi bi-code-slash",
                    component: CSS,
                },
            ],
        };
    },
    methods: {
        async read() {
            const widgetUrl = `${process.env.VUE_APP_API_URL}widgets/${this.id}/${this.widgetId}?widget_id=${this.widgetId}`;

            const headers = new Headers({
                Authorization: "Bearer " + this.token,
            });

            const [widgetResponse] = await Promise.all([fetch(widgetUrl, { headers })]);

            const widgetData = await widgetResponse.json();

            if (widgetData) {
                this.widgetInfo = widgetData.data;
            }
        },
        showAnalyticsModal(widgetName) {
            this.$parent.$refs.analyticsComponent.showModal(widgetName);
        },
    },
    mounted() {
        this.read();
    },
};
</script>
