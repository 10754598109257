<template>
    <div class="col-sm-9">
        <h4>Admin Settings</h4>

        <div class="table-responsive bg-white p-20 rounded">
            <div v-for="setting in settings" :key="setting.id" class="mb-3 row align-items-center">
                <div class="col-sm-4">
                    <label :for="`setting-${setting.id}`" class="form-label">{{ formatKey(setting.key) }}</label>
                </div>
    
                <div class="col-sm-6">
                    <input 
                        v-model="setting.value" 
                        @input="debounceInput(setting.id, setting.value)" 
                        :id="`setting-${setting.id}`" 
                        class="form-control"
                    />
                </div>
    
                <div class="col-sm-2">
                    <button
                        class="btn btn-danger"
                        @click="deleteSetting(setting.id)"
                    >
                        Delete
                    </button>
                </div>
            </div>
    
            <div class="mb-3 row align-items-center">
                <div class="col-sm-4">
                    <input 
                        v-model="newSetting.key" 
                        class="form-control" 
                        placeholder="New Setting Key" 
                    />
                </div>
    
                <div class="col-sm-6">
                    <input 
                        v-model="newSetting.value" 
                        class="form-control" 
                        placeholder="New Setting Value" 
                    />
                </div>
    
                <div class="col-sm-2">
                    <button
                        class="btn btn-primary"
                        @click="addSetting"
                        :disabled="!newSetting.key || !newSetting.value"
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            settings: [],
            newSetting: {
                key: '',
                value: ''
            },
            debounceTimeout: null
        };
    },
    methods: {
        getHeaders() {
            return {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + this.token
            };
        },
        async fetchSettings() {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}settings`, {
                    headers: this.getHeaders()
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                this.settings = await response.json();
            } catch (error) {
                console.error('Error fetching settings:', error);
            }
        },
        debounceInput(id, value) {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
                this.handleInput(id, value);
            }, 300);
        },
        async handleInput(id, value) {
            try {
                await fetch(`${process.env.VUE_APP_API_URL}settings/${id}`, {
                    method: 'PUT',
                    headers: this.getHeaders(),
                    body: JSON.stringify({ value })
                });
            } catch (error) {
                console.error('Error updating setting:', error);
            }
        },
        async updateSetting(id, value) {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}settings/${id}`, {
                    method: 'PUT',
                    headers: this.getHeaders(),
                    body: JSON.stringify({ value })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                await this.fetchSettings();
            } catch (error) {
                console.error('Error updating setting:', error);
            }
        },
        async deleteSetting(id) {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}settings/${id}`, {
                    method: 'DELETE',
                    headers: this.getHeaders()
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                await this.fetchSettings();
            } catch (error) {
                console.error('Error deleting setting:', error);
            }
        },
        async addSetting() {
            try {
                const newSetting = { key: this.newSetting.key, value: this.newSetting.value };
                const response = await fetch(`${process.env.VUE_APP_API_URL}settings`, {
                    method: 'POST',
                    headers: this.getHeaders(),
                    body: JSON.stringify(newSetting)
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                this.newSetting.key = '';
                this.newSetting.value = '';
                await this.fetchSettings();
            } catch (error) {
                console.error('Error adding setting:', error);
            }
        },
        formatKey(key) {
            return key
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
    },
    mounted() {
        this.fetchSettings();
    }
};
</script>
