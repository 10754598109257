<template>
    <transition name="fade-slide">
        <div v-if="visible" class="toast-container" :class="toastClasses">
            <div class="toast-message">
                <slot></slot>
                <button class="close-btn" @click="closeToast">&times;</button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "BaseToast",
    props: {
        type: {
            type: String,
            default: "success",
            validator: (value) => ["success", "error", "warning", "info"].includes(value),
        },
        duration: {
            type: Number,
            default: 3000,
        },
    },
    data() {
        return {
            visible: true,
        };
    },
    computed: {
        toastClasses() {
            return {
                "toast-success": this.type === "success",
                "toast-error": this.type === "error",
                "toast-warning": this.type === "warning",
                "toast-info": this.type === "info",
            };
        },
    },
    mounted() {
        if (this.duration > 0) {
            setTimeout(() => this.closeToast(), this.duration);
        }
    },
    methods: {
        closeToast() {
            this.visible = false;
        },
    },
};
</script>

<style scoped>
.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1050;
    padding: 12px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.toast-success {
    background: #28a745;
    color: white;
}
.toast-error {
    background: #dc3545;
    color: white;
}
.toast-warning {
    background: #ffc107;
    color: black;
}
.toast-info {
    background: #17a2b8;
    color: white;
}

.close-btn {
    background: transparent;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    margin-left: auto;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
    transition: opacity 0.3s, transform 0.3s;
}
.fade-slide-enter-from,
.fade-slide-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}
</style>
