<template>
    <nav class="navbar navbar-expand-lg bg-white px-3">
        <div class="container-fluid d-flex align-items-center">
            <div class="d-flex align-items-center">
                <router-link to="/widgets/" class="nav-link active navbar-brand me-4">
                    <img src="https://webynize.com/app/logo.svg" alt="Logo" />
                </router-link>
            </div>

            <button class="navbar-toggler ms-auto" type="button" @click="toggleOffcanvas">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-end d-none d-lg-flex">
                <ul class="navbar-nav d-flex flex-row gap-3 me-auto" v-if="is_authenticated">
                    <li class="nav-item">
                        <router-link to="/widgets/" class="nav-link">My widgets</router-link>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link" @click.prevent="openSupport">Support</button>
                    </li>
                </ul>
                <div class="d-flex align-items-center gap-3" v-if="is_authenticated">
                    <BaseToast v-if="showToast" type="success" :duration="4000">
                        🎉 Your premium trial has started!
                    </BaseToast>
                    <UserStatusButton @trial-activated="handleTrialActivated" />
                    <BaseDropdown trigger-mode="hover">
                        <template v-slot:trigger>
                            <button
                                class="btn btn-primary btn-md text-uppercase d-flex align-items-center"
                                @click="goToCreateWidget"
                            >
                                <i class="bi bi-plus-lg me-2"></i> Create Widget
                            </button>
                        </template>
                        <template v-slot:menu>
                            <li v-for="widgetType in widgetTypes.slice(0, 4)" :key="widgetType.id">
                                <router-link class="dropdown-item" :to="`/create-widget/${widgetType.id}/`">
                                    {{ widgetType.name }}
                                </router-link>
                            </li>
                            <div class="dropdown-divider"></div>
                            <li v-if="widgetTypes.length > 4">
                                <router-link class="dropdown-item" to="/explore-more-widgets/">
                                    Explore More ({{ widgetTypes.length - 4 }}+)
                                </router-link>
                            </li>
                        </template>
                    </BaseDropdown>

                    <BaseDropdown trigger-mode="hover">
                        <template v-slot:trigger>
                            <BaseAvatar v-if="profilePictureUrl" :src="profilePictureUrl" size="md" alt="User Avatar" />
                            <i v-else class="bi bi-person-circle text-muted" style="font-size: 2rem"></i>
                            <!-- <span class="ms-auto" role="button">
                                <i class="bi bi-chevron-expand"></i>
                            </span> -->
                        </template>
                        <template v-slot:menu>
                            <div class="dropdown-header">
                                <span class="d-block text-xs text-muted mb-1">Signed in as:</span>
                                <span class="d-block text-heading fw-semibold">{{ name }}</span>
                            </div>
                            <router-link class="dropdown-item" to="/admin/users/" v-if="isAdmin || isSupport">
                                <i class="bi bi-shield-lock me-2"></i> Admin
                            </router-link>
                            <router-link class="dropdown-item" to="/account/profile/">
                                <i class="bi bi-person me-2"></i> Account Details
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <button class="dropdown-item text-danger text-start" @click="logout">
                                <i class="bi bi-box-arrow-right me-2"></i> Logout
                            </button>
                        </template>
                    </BaseDropdown>
                </div>

                <ul class="navbar-nav d-flex flex-row gap-3 ms-auto" v-if="!is_authenticated">
                    <li class="nav-item">
                        <router-link to="/login/" class="nav-link">
                            <i class="bi bi-box-arrow-in-right me-2"></i> Login
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <div class="offcanvas offcanvas-end" tabindex="-1" id="mobileNav" ref="offcanvasElement">
        <div class="offcanvas-header">
            <button type="button" class="btn-close" @click="closeOffcanvas"></button>
        </div>
        <div class="offcanvas-body">
            <ul class="navbar-nav">
                <li class="nav-item" v-if="is_authenticated">
                    <router-link to="/widgets/" class="nav-link" @click="closeOffcanvas">My widgets</router-link>
                </li>
                <li class="nav-item" v-if="is_authenticated">
                    <button class="nav-link" @click.prevent="openSupport" @click="closeOffcanvas">Support</button>
                </li>
                <li class="nav-item">
                    <router-link
                        to="/create-widget/"
                        class="btn btn-primary w-100 text-uppercase my-2"
                        @click="closeOffcanvas"
                    >
                        <i class="bi bi-plus-lg me-2"></i> Create Widget
                    </router-link>
                </li>
                <li class="nav-item" v-if="is_authenticated">
                    <router-link
                        class="dropdown-item nav-link"
                        to="/admin/users/"
                        v-if="isAdmin || isSupport"
                        @click="closeOffcanvas"
                    >
                        <i class="bi bi-shield-lock me-2"></i> Admin
                    </router-link>
                </li>
                <li class="nav-item" v-if="is_authenticated">
                    <router-link to="/account/profile/" class="nav-link" @click="closeOffcanvas">
                        <i class="bi bi-person me-2"></i> Account Details
                    </router-link>
                </li>
                <li class="nav-item" v-if="is_authenticated">
                    <button
                        class="nav-link text-danger"
                        @click="
                            logout();
                            closeOffcanvas();
                        "
                    >
                        <i class="bi bi-box-arrow-right me-2"></i> Logout
                    </button>
                </li>
                <li class="nav-item" v-if="!is_authenticated">
                    <router-link to="/login/" class="nav-link" @click="closeOffcanvas">
                        <i class="bi bi-box-arrow-in-right me-2"></i> Login
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Offcanvas } from "bootstrap";
import BaseAvatar from "./ui/BaseAvatar.vue";
import BaseDropdown from "./ui/BaseDropdown.vue";
import BaseToast from "./ui/BaseToast.vue";
import UserStatusButton from "./UserStatusButton.vue";

export default {
    name: "BaseNavbar",
    components: {
        BaseAvatar,
        BaseDropdown,
        BaseToast,
        UserStatusButton,
    },
    computed: {
        ...mapGetters(["is_authenticated", "token", "isAdmin", "isSupport", "name", "profilePicture"]),
        profilePictureUrl() {
            if (!this.profilePicture) {
                return null;
            }
            return `${process.env.VUE_APP_FILE_SERVER_URL}${this.profilePicture}?nocache=${Date.now()}`;
        },
    },
    data() {
        return {
            widgetTypes: [],
            showToast: false,
            offcanvasInstance: null,
        };
    },
    methods: {
        async logout() {
            await this.$store.dispatch("logoutAuth");
            this.$router.push("/login/");
        },
        openSupport() {
            if (window.$crisp) {
                window.$crisp.push(["do", "chat:open"]);
            } else {
                console.warn("Crisp is not loaded yet.");
            }
        },
        handleTrialActivated() {
            this.showToast = true;
        },
        async fetchWidgetTypes() {
            const url = `${process.env.VUE_APP_API_URL}widget-types/status/1`;

            const res = await fetch(url, {
                headers: new Headers({
                    Authorization: `Bearer ${this.token}`,
                }),
            });

            this.widgetTypes = await res.json();
        },
        toggleOffcanvas() {
            if (this.offcanvasInstance) {
                this.offcanvasInstance.toggle();
            }
        },
        closeOffcanvas() {
            if (this.offcanvasInstance) {
                this.offcanvasInstance.hide();
            }
        },
        goToCreateWidget() {
            this.$router.push("/create-widget/");
        },
    },
    mounted() {
        this.fetchWidgetTypes();
        const offcanvasElement = this.$refs.offcanvasElement;
        this.offcanvasInstance = new Offcanvas(offcanvasElement);
    },
};
</script>

<style scoped>
.navbar {
    height: 65px;
}
.navbar-brand img {
    height: 40px;
    width: auto;
}
.offcanvas {
    width: 280px;
}
</style>
