<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Content background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.content_background_color"
                    type="text"
                    id="content_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.content_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Content font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.content_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.content_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Vote Button background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.vote_now_button_background_color"
                    type="text"
                    id="vote_now_button_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.vote_now_button_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Vote Button text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.vote_now_button_text_color"
                    type="text"
                    id="vote_now_button_text_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.vote_now_button_text_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Popup background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.popup_background_color"
                    type="text"
                    id="popup_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.popup_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Popup font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.popup_font_color"
                    type="text"
                    id="popup_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.popup_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Submit Button background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.submit_button_background_color"
                    type="text"
                    id="submit_button_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.submit_button_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Submit Button text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.submit_button_text_color"
                    type="text"
                    id="submit_button_text_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.submit_button_text_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Cancel Button background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.cancel_button_background_color"
                    type="text"
                    id="cancel_button_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.cancel_button_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Cancel Button text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.cancel_button_text_color"
                    type="text"
                    id="cancel_button_text_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.cancel_button_text_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Branding color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.branding_color"
                    type="text"
                    id="branding_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.branding_color" :disabled="isRestricted" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
