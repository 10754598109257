<template>
    <div
        class="modal"
        id="createItemModal"
        tabindex="-1"
        aria-labelledby="createItemModalLabel"
        aria-hidden="true"
        ref="createModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createItemModalLabel">Create Item</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <div class="mb-3">
                            <label for="itemName" class="form-label">Name</label>
                            <input
                                type="text"
                                class="form-control"
                                id="itemName"
                                v-model="data.item_name"
                                required
                                placeholder="Enter the name"
                                @blur="validateField('item_name')"
                            />
                            <p v-if="!validations.item_name" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="itemDescription" class="form-label">Description</label>
                            <QuillEditor
                                id="itemDescription"
                                v-model="data.item_description"
                                theme="snow"
                                @blur="validateField('item_description')"
                                ref="quillEditor"
                            />
                            <p v-if="!validations.item_description" class="text-danger small">
                                This field is required.
                            </p>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click.prevent="submitForm">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import QuillEditor from "../../../components/QuillEditor.vue";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            data: {
                item_name: "",
                item_description: "",
                widget_id: this.$route.params.widget_id,
            },
            validations: {
                item_name: true,
                item_description: true,
            },
        };
    },
    computed: {
        isFormValid() {
            return Object.values(this.validations).every((isValid) => isValid);
        },
    },
    methods: {
        async submitForm(event) {
            event.preventDefault();

            this.validateAllFields();

            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}cancellation-form-items`, this.data, {
                    headers: { Authorization: "Bearer " + this.token },
                });

                console.log("Item Created:", response.data);
                this.clearForm();
                const modalInstance = Modal.getInstance(this.$refs.createModal);
                modalInstance.hide();
                this.$emit("created-item");
                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error creating item:", error);
            }
        },
        clearForm() {
            this.data = {
                item_name: "",
                item_description: "",
                widget_id: this.$route.params.widget_id,
            };
            this.$refs.quillEditor.quillInstance.setText("");
        },
        validateField(field) {
            if (this.data[field] === "") {
                this.validations[field] = false;
            } else {
                this.validations[field] = true;
            }
        },
        validateAllFields() {
            Object.keys(this.validations).forEach((field) => {
                this.validateField(field);
            });
        },
        showModal() {
            const modalInstance = new Modal(this.$refs.createModal);
            modalInstance.show();
        },
    },
    mounted() {},
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
