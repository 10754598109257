<template>
    <button
        :class="buttonClasses"
        :type="type"
        :disabled="isLoading || disabled"
        :aria-busy="isLoading"
        :aria-disabled="isLoading || disabled"
        @click="handleClick"
    >
        <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <slot v-if="!isLoading">{{ label }}</slot>
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        label: {
            type: String,
            default: "Submit",
        },
        type: {
            type: String,
            default: "button",
            validator(value) {
                return ["button", "submit", "reset"].includes(value);
            },
        },
        variant: {
            type: String,
            default: "primary",
            validator(value) {
                return [
                    "primary",
                    "secondary",
                    "danger",
                    "success",
                    "warning",
                    "info",
                    "dark",
                    "light",
                    "outline-primary",
                    "outline-secondary",
                    "outline-danger",
                    "outline-success",
                ].includes(value);
            },
        },
        size: {
            type: String,
            default: "md",
            validator(value) {
                return ["sm", "md", "lg"].includes(value);
            },
        },
        fontSize: {
            type: String,
            default: "inherit",
        },
        block: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonClasses() {
            return [
                "btn",
                `btn-${this.variant}`,
                { [`btn-${this.size}`]: this.size !== "md" },
                { "btn-block": this.block },
                { "btn-loading": this.isLoading },
            ];
        },
    },
    methods: {
        handleClick(event) {
            if (this.isLoading || this.disabled) {
                event.preventDefault();
                return;
            }
            this.$emit("click", event);
        },
    },
};
</script>

<style scoped>
.btn-loading {
    pointer-events: none;
    opacity: 0.7;
}

.btn-block {
    width: 100%;
}

.btn[style*="font-size"] {
    font-size: var(--font-size);
}
</style>
