<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Billing Details</h4>

        <div v-if="loadingUserData" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>

        <div class="pt-3" v-else>
            <div class="row mb-4">
                <div :class="['col-md-6', userData.status === 13 || userData.status === 6 ? 'col-md-12' : '']">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="d-flex flex-row flex-wrap mb-2 justify-content-between align-items-start">
                                <span v-if="loadingUserData" class="skeleton-loader skeleton-20 skeleton-lg"></span>
                                <div v-else>
                                    <div class="d-flex align-items-center">
                                        <h2 class="card-title mb-0">
                                            {{
                                                userData.status == 6
                                                    ? "Premium Trial"
                                                    : userData.current_plan_name ?? "Free Plan"
                                            }}
                                        </h2>

                                        <span
                                            :class="[
                                                'badge',
                                                getBadgeClass(userData.status),
                                                'ms-2',
                                                'fw-normal',
                                                'text-capitalize',
                                            ]"
                                        >
                                            {{ getSubscriptionStatusLabel(userData.status) }}
                                        </span>
                                    </div>
                                </div>

                                <span v-if="loadingUserData" class="skeleton-loader skeleton-20 skeleton-lg"></span>
                                <p
                                    class="text-muted d-flex flex-row justify-content-between align-items-center gap-1 m-0"
                                    v-else
                                >
                                    <span class="price">${{ userData.cost }}</span>
                                    per <span>{{ userData.cycle === 0 ? "month" : "year" }}</span>
                                </p>
                            </div>

                            <div class="mb-3">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <i class="bi bi-box-seam text-primary me-2"></i>
                                        <span v-if="loadingWidgetCount" class="skeleton-loader"></span>
                                        <span class="form-label mb-0" v-else
                                            >{{ formatNumber(widgetCount) }} widgets</span
                                        >
                                    </div>

                                    <p class="form-label mb-0 text-muted d-flex align-items-center">
                                        <span class="me-2">Max:</span>
                                        <span v-if="loadingWidgetCount" class="skeleton-loader"></span>
                                        <span v-else>{{ formatNumber(userData.widget_limit) }}</span>
                                    </p>
                                </div>

                                <ProgressBar :progress="progress" class="mt-2" />
                            </div>

                            <div class="mb-3">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <i class="bi bi-eye text-primary me-2"></i>
                                        <span v-if="loadingViewsByUserOrWidget" class="skeleton-loader"></span>
                                        <span class="form-label mb-0" v-else
                                            >{{ formatNumber(widgetViews) }} views</span
                                        >
                                    </div>

                                    <p class="form-label mb-0 text-muted d-flex align-items-center">
                                        <span class="me-2">Max:</span>
                                        <span v-if="loadingViewsByUserOrWidget" class="skeleton-loader"></span>
                                        <span v-else>
                                            {{
                                                userData.view_limit == 0
                                                    ? "Unlimited views"
                                                    : formatNumber(userData.view_limit)
                                            }}
                                        </span>
                                    </p>
                                </div>

                                <ProgressBar :progress="viewsProgress" class="mt-2" />
                            </div>
                        </div>

                        <div class="card-footer d-flex gap-2 flex-wrap">
                            <router-link :to="{ name: 'ChangePlan' }" class="btn btn-primary flex-fill">
                                Upgrade plan
                            </router-link>
                            <router-link
                                v-if="isPaidUser"
                                :to="{ name: 'AddOns' }"
                                class="btn btn-outline-primary flex-fill"
                            >
                                Buy More Widgets
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-md-6" v-if="userData.status != 13 && userData.status != 6">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="d-flex flex-wrap mb-2 justify-content-between align-items-center mb-3">
                                <h2 class="card-title mb-0">Payment Method</h2>

                                <div class="d-flex align-items-center text-muted">
                                    <i class="bi bi-calendar-event me-2"></i>
                                    <p class="small text-muted d-flex align-items-center mb-0">
                                        <span class="me-2">Next payment: </span>
                                        <span v-if="loadingUserData" class="skeleton-loader skeleton-20"></span>
                                        <span class="fw-semibold" v-else>{{ userData.next_charge_text }}</span>
                                    </p>
                                </div>
                            </div>

                            <div v-if="loadingUserData" class="skeleton-loader skeleton-100" style="height: 100%"></div>
                            <div class="d-flex align-items-center gap-4 mt-3" v-else>
                                <div
                                    v-if="paymentMethod === 2 || paymentMethod === '2'"
                                    class="d-flex align-items-center p-3 rounded shadow-sm border w-100"
                                >
                                    <i class="bi bi-credit-card text-primary" style="font-size: 2rem"></i>
                                    <div class="ms-3">
                                        <span class="d-block fw-semibold">Paddle</span>
                                        <small class="text-muted"
                                            >Paddle uses advanced encryption (SSL/TLS) to ensure that all payment
                                            information is securely transmitted and stored.</small
                                        >
                                    </div>
                                </div>

                                <div
                                    v-if="paymentMethod === 'paypal' || paymentMethod === 1 || paymentMethod === '1'"
                                    class="d-flex align-items-center p-3 rounded shadow-sm border w-100"
                                >
                                    <i class="bi bi-paypal text-primary" style="font-size: 2rem"></i>
                                    <div class="ms-3">
                                        <span class="d-block fw-semibold">PayPal</span>
                                    </div>
                                </div>

                                <div
                                    v-if="paymentMethod === 'BTC' || paymentMethod === 3 || paymentMethod === '3'"
                                    class="d-flex align-items-center p-3 rounded shadow-sm border w-100"
                                >
                                    <i class="bi bi-paypal text-primary" style="font-size: 2rem"></i>
                                    <div class="ms-3">
                                        <span class="d-block fw-semibold">PayPal</span>
                                    </div>
                                </div>

                                <div
                                    v-if="paymentMethod === 'card'"
                                    class="d-flex align-items-center p-3 rounded shadow-sm border w-100"
                                >
                                    <i class="bi bi-credit-card text-primary" style="font-size: 2rem"></i>
                                    <div class="ms-3">
                                        <span class="d-block fw-semibold">Card</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer text-end">
                            <button
                                class="btn btn-primary w-100"
                                @click="$parent.$refs.updateComponent.showEditModal($event, userData)"
                            >
                                Change
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 mb-4">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h2 class="card-title">Billing History</h2>
                            <div class="position-relative w-25">
                                <input
                                    type="text"
                                    class="form-control ps-5"
                                    placeholder="Search invoices..."
                                    v-model="searchTerm"
                                />
                                <i
                                    class="bi bi-search position-absolute top-50 start-0 translate-middle-y ms-3 text-muted"
                                ></i>
                            </div>
                        </div>

                        <div v-if="loadingInvoices" class="skeleton-loader skeleton-xl skeleton-100"></div>
                        <div
                            v-else-if="invoices.data.length === 0"
                            class="alert d-flex align-items-center border-0 bg-light text-dark p-2 rounded-3"
                            role="alert"
                        >
                            <i class="bi bi-file-earmark-x text-secondary me-3" style="font-size: 1.4rem"></i>
                            <span>No billing history available.</span>
                        </div>

                        <div class="table-responsive" v-else>
                            <table class="table table-hover">
                                <thead class="bg-light">
                                    <tr>
                                        <th scope="col" class="text-muted">Invoice #</th>
                                        <th scope="col" class="text-muted">Item</th>
                                        <th scope="col" class="text-muted text-end" style="width: 1%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="invoice in invoices.data"
                                        :key="invoice.id"
                                        class="border-bottom border-light hover-bg-primary hover-text-white transition-all duration-300 ease-in-out"
                                    >
                                        <td class="fw-semibold py-3 align-middle">{{ invoice.invoice_number }}</td>
                                        <td class="text-muted py-3 align-middle">
                                            {{ invoice.item }} - ${{ invoice.cost }}
                                        </td>
                                        <td class="text-end py-3 align-middle">
                                            <a
                                                :href="invoice.invoice_url"
                                                class="btn btn-primary btn-sm px-3"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                View
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <nav aria-label="Page navigation example" v-if="invoices.data && invoices.data.length > 0">
                                <ul class="pagination justify-content-center flex-wrap gap-1">
                                    <span v-for="(item, index) in invoices.links" :key="item.url" class="me-1">
                                        <li :class="{ active: item.active }" class="page-item">
                                            <a
                                                v-if="item.url && !(paginationLoadingIndex === index)"
                                                @click="fetchInvoices(item.url, index)"
                                                class="page-link"
                                            >
                                                <span v-html="item.label"></span>
                                            </a>
                                            <span v-else-if="paginationLoadingIndex === index" class="page-link">
                                                <span
                                                    class="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            </span>
                                        </li>
                                    </span>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from "../../../components/ProgressBar.vue";
import store from "../../../store";

export default {
    components: {
        ProgressBar,
    },
    data() {
        return {
            Paddle: window.Paddle,
            token: `Bearer ${store.getters.logged_in_user?.data?.token || ""}`,
            user_id: store.getters.logged_in_user.data.user.id ?? 0,
            userData: {},
            widgetCount: 0,
            widgetViews: 0,
            plan: {},
            loading: false,
            loadingWidgetCount: true,
            loadingInvoices: true,
            loadingUserData: true,
            loadingViewsByUserOrWidget: true,
            progress: 0,
            viewsProgress: 0,
            paymentMethod: "credit-card",
            invoices: [],
            searchTerm: "",
            searchTimeout: null,
            paginationLoadingIndex: null,
        };
    },
    computed: {
        isPaidUser() {
            return this.$store.getters.logged_in_user?.data?.user?.status == 1;
        },
    },
    watch: {
        searchTerm: {
            handler() {
                clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {
                    this.fetchInvoices();
                }, 300);
            },
            immediate: true,
        },
    },
    methods: {
        getBadgeClass(status) {
            const normalizedStatus = Number(status) || 0;

            switch (normalizedStatus) {
                case 1:
                    return "bg-success";
                case 13:
                    return "bg-success";
                case 6:
                    return "bg-success";
                case 7:
                    return "bg-warning";
                case 0:
                    return "bg-danger";
                default:
                    return "bg-secondary";
            }
        },
        getSubscriptionStatusLabel(status) {
            const normalizedStatus = Number(status) || 0;

            switch (normalizedStatus) {
                case 1:
                    return "Active";
                case 13:
                    return "Active";
                case 6:
                    return "Active";
                case 7:
                    return "To Cancel";
                case 0:
                    return "Cancelled";
                default:
                    return "";
            }
        },
        formatNumber(value = 0) {
            if (!value) return 0;
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        async getWidgetCount() {
            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}users/current-plan-and-widget-count`, {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: this.token,
                    }),
                    body: JSON.stringify({ user_id: this.user_id }),
                });

                const resData = await res.json();

                console.log(resData);

                if (resData && resData.widget_count !== undefined) {
                    this.widgetCount = resData?.widget_count ?? 0;
                    this.plan = resData?.plan ?? { solutions_count: 5, views_per_month: 3000 };
                }
            } catch (error) {
                console.error("Error fetching widget count:", error);
            } finally {
                this.loadingWidgetCount = false;
            }
        },
        async fetchInvoices(url, index) {
            if (this.paginationLoadingIndex) {
                return;
            }

            let page = 1;

            if (url) {
                page = new URL(url).searchParams.get("page");
            }

            try {
                this.paginationLoadingIndex = index;

                const res = await fetch(
                    `${process.env.VUE_APP_API_URL}invoices/user?search=${encodeURIComponent(
                        this.searchTerm
                    )}&page=${page}`,
                    {
                        method: "GET",
                        headers: new Headers({
                            Authorization: this.token,
                        }),
                    }
                );

                const resData = await res.json();
                this.invoices = resData || [];
            } catch (error) {
                console.error("Error fetching invoices:", error);
            } finally {
                this.paginationLoadingIndex = null;
                this.loadingInvoices = false;
            }
        },
        async fetchUserData() {
            this.loadingUserData = true;

            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}users/plan-details/${this.user_id}`, {
                    method: "GET",
                    headers: new Headers({
                        Authorization: this.token,
                    }),
                });

                const resData = await res.json();
                this.userData = resData.user;
                console.log(this.userData);
                this.paymentMethod = this.userData.payment_method || "2";
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                this.loadingUserData = false;
            }
        },
        async getViewsByUserOrWidget(fromDate, toDate, widgetId = null, userId = null) {
            this.loadingViewsByUserOrWidget = true;

            try {
                let url = `${process.env.VUE_APP_ANALYTICS_URL}widget-views/total-by-date-range?from=${fromDate}&to=${toDate}`;

                if (userId) {
                    url += `&user_id=${userId}`;
                } else if (widgetId) {
                    url += `&widget_id=${widgetId}`;
                }

                const res = await fetch(url, {
                    method: "GET",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.token}`,
                    }),
                });

                const data = await res.json();
                this.widgetViews = data.total_views;
            } catch (error) {
                console.error("Error fetching total views:", error);
            } finally {
                this.loadingViewsByUserOrWidget = false;
            }
        },

        async read() {
            // this.loading = false;
            await Promise.all([this.fetchUserData(), this.fetchInvoices(), this.getWidgetCount()]);

            const currentDate = new Date();

            // const planStartDate = new Date(this.userData.activation_date);
            const planStartDate = this.userData.activation_date
                ? new Date(this.userData.activation_date)
                : new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

            let fromDate, toDate;

            if (this.userData.cycle === 0) {
                fromDate = new Date(planStartDate.getFullYear(), planStartDate.getMonth(), 1);
                toDate = currentDate;
            } else {
                fromDate = new Date(planStartDate.getFullYear(), 0, 1);
                toDate = currentDate;
            }

            fromDate = fromDate.toISOString().split("T")[0];
            toDate = toDate.toISOString().split("T")[0];

            await this.getViewsByUserOrWidget(fromDate, toDate, null, this.user_id);

            // this.loading = false;

            const percentage = Math.min((this.widgetCount / this.userData.widget_limit) * 100, 100);
            setTimeout(() => {
                this.progress = percentage;
            }, 300);

            const viewsPercentage =
                this.plan.views_per_month === 0
                    ? 0
                    : Math.min((this.widgetViews / this.plan.views_per_month) * 100, 100);

            setTimeout(() => {
                this.viewsProgress = viewsPercentage;
            }, 300);
        },
    },
    mounted() {
        this.read();
    },
};
</script>

<style scoped>
.card {
    border-radius: 10px;
    border: none;
    transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.025) !important;
}

.card-body {
    padding: 1.5rem;
}

.card-title {
    font-size: 1.25rem;
    color: #333;
    font-weight: 600;
}

.price {
    font-size: 2rem;
    color: #333;
    font-weight: 500;
}

.card-text {
    font-size: 1rem;
}

.card-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff !important;
}

.card-footer:last-child {
    border-radius: 0 0 10px 10px;
}

.btn-primary,
.btn-secondary {
    padding: 0.375rem 1.5rem;
}

.list-group-item {
    border: none;
    padding: 1rem 0;
}

.list-group-item:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
}

.table tr {
    transition: all 0.2s ease;
}

.skeleton-loader {
    display: inline-block;
    min-width: 50px;
    height: 20px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 5px;
}

.skeleton-progress {
    display: inline-block;
    width: 100%;
    height: 10px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 5px;
}

.skeleton-sm {
    height: 1rem;
}
.skeleton-md {
    height: 1.5rem;
}
.skeleton-lg {
    height: 2.5rem;
}
.skeleton-xl {
    height: 3.5rem;
}

.skeleton-10 {
    width: 10%;
}
.skeleton-20 {
    width: 20%;
}
.skeleton-30 {
    width: 30%;
}
.skeleton-40 {
    width: 40%;
}
.skeleton-50 {
    width: 50%;
}
.skeleton-60 {
    width: 60%;
}
.skeleton-70 {
    width: 70%;
}
.skeleton-80 {
    width: 80%;
}
.skeleton-90 {
    width: 90%;
}
.skeleton-100 {
    width: 100%;
}

@keyframes skeleton-loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

@keyframes skeleton-loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
</style>
