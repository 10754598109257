<template>
    <button class="btn btn-primary fs-5 d-flex justify-content-center align-items-center" @click="showModal">
        View Votes
    </button>
    <div class="modal" tabindex="-1" id="voteButtonVotesModal" ref="voteButtonVotesModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title d-flex align-items-center">
                        {{ modalTitle }}
                        <!-- <span class="badge rounded-pill bg-secondary ms-2">{{ votes.total }}</span> -->
                    </h5>

                    <button type="button" class="btn-close ms-2 ms-auto" @click="hideModal"></button>
                </div>
                <div v-if="loading" class="d-flex justify-content-center align-items-center" style="min-height: 100px">
                    <div class="spinner"></div>
                </div>
                <div class="modal-body" v-else>
                    <div v-if="currentView === 'votes'" class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Total</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="vote in votes.data" :key="vote.id">
                                    <td>
                                        <div>
                                            {{ vote.page_group }}
                                        </div>
                                        <div>
                                            {{ vote.keyword_group }}
                                        </div>
                                    </td>
                                    <td>
                                        {{ vote.total_votes }}
                                    </td>
                                    <td>
                                        <button
                                            class="btn btn-secondary btn-sm"
                                            @click="showEmails(vote.keyword_group, vote.page_group)"
                                        >
                                            View Emails
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <nav aria-label="Page navigation" v-if="votes.data && votes.data.length > 0">
                            <ul class="pagination justify-content-center flex-wrap">
                                <span v-for="(item, index) in votes.links" :key="item.url + item.label" class="me-1">
                                    <li :class="{ active: item.active }" class="page-item">
                                        <button
                                            v-if="item.url && !(paginationLoadingIndex === index)"
                                            @click="changePage(item.url)"
                                            class="page-link"
                                        >
                                            <span v-html="item.label"></span>
                                        </button>
                                        <span v-else-if="paginationLoadingIndex === index" class="page-link">
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        </span>
                                    </li>
                                </span>
                            </ul>
                        </nav>
                    </div>

                    <div v-if="currentView === 'emails'">
                        <div class="d-flex justify-content-end mb-3">
                            <button class="btn btn-secondary" @click="showVotes">Back to Votes</button>
                        </div>

                        <ul class="list-group p-1">
                            <li class="list-group-item" v-for="(email, index) in emails.data" :key="index">
                                <div>
                                    <strong>{{ email.email }}</strong>
                                </div>
                                <div>
                                    <span>{{ formatDate(email.created_at) }}</span>
                                </div>
                            </li>
                        </ul>

                        <nav aria-label="Page navigation" v-if="emails.data && votes.data.length > 0" class="pt-3">
                            <ul class="pagination justify-content-center flex-wrap">
                                <span v-for="(item, index) in emails.links" :key="item.url + item.label" class="me-1">
                                    <li :class="{ active: item.active }" class="page-item">
                                        <button
                                            v-if="item.url && !(paginationLoadingIndex === index)"
                                            @click="changePage(item.url)"
                                            class="page-link"
                                        >
                                            <span v-html="item.label"></span>
                                        </button>
                                        <span v-else-if="paginationLoadingIndex === index" class="page-link">
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        </span>
                                    </li>
                                </span>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            loading: true,
            downloading: false,
            processing: false,
            votes: [],
            emails: [],
            modalInstance: null,
            widget_id: this.$route.params.widget_id,
            currentView: "votes",
            modalTitle: "Votes",
            paginationLoadingIndex: null,
            currentKeywordGroup: null,
            currentPageGroup: null,
        };
    },
    methods: {
        async fetchVotes(page = 1) {
            this.paginationLoadingIndex = Number(page);

            let url = `${process.env.VUE_APP_API_URL}widget-votes/${this.$route.params.widget_id}?page=${page}`;

            try {
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });
                this.votes = response.data;
                console.log(this.votes);
            } catch (error) {
                console.error("Error fetching emails:", error);
            } finally {
                this.loading = false;
                this.paginationLoadingIndex = null;
            }
        },
        async fetchEmails(keyword_group, page_group, page = 1) {
            this.paginationLoadingIndex = Number(page);

            if (this.paginationLoadingIndex === 1) {
                this.loading = true;
            }

            let url = `${process.env.VUE_APP_API_URL}widget-votes/${this.widget_id}/emails?page=${page}&widget_id=${this.widget_id}`;

            if (keyword_group && keyword_group !== "N/A" && keyword_group !== "No Keyword") {
                url += `&keyword_group=${keyword_group}`;
                this.currentKeywordGroup = keyword_group;
            }

            if (page_group && page_group !== "N/A") {
                url += `&page_group=${page_group}`;
                this.currentPageGroup = page_group;
            }

            try {
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });
                this.emails = response.data;
                this.currentView = "emails";

                this.modalTitle = `${keyword_group ? `"${keyword_group}"` : "All Keywords"} ${
                    page_group ? `| "${page_group}"` : "| All Pages"
                }`;
            } catch (error) {
                console.error("Error fetching emails:", error);
            } finally {
                this.loading = false;
                this.paginationLoadingIndex = null;
            }
        },

        changePage(url) {
            if (this.paginationLoadingIndex !== null) {
                return;
            }

            const page = new URL(url).searchParams.get("page");

            if (this.currentView === "votes" && page) {
                this.fetchVotes(page);
            } else if (this.currentView === "emails" && page) {
                this.fetchEmails(this.currentKeywordGroup, this.currentPageGroup, page);
            }
        },
        showModal() {
            this.modalInstance = new Modal(this.$refs.voteButtonVotesModal);
            this.fetchVotes();
            this.modalInstance.show();
        },
        hideModal() {
            if (this.modalInstance) {
                this.modalInstance.hide();
            }
        },
        showVotes() {
            this.currentView = "votes";
            this.modalTitle = "Votes";

            this.currentKeywordGroup = null;
            this.currentPageGroup = null;
        },
        showEmails(keyword_group, page_group) {
            this.currentKeywordGroup = keyword_group;
            this.currentPageGroup = page_group;

            this.fetchEmails(keyword_group, page_group);
        },
        formatDate(date) {
            const formattedDate = new Date(date);
            return new Intl.DateTimeFormat("en-US", {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            }).format(formattedDate);
        },
    },
};
</script>

<style scoped></style>
