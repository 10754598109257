<template>
    <div class="d-flex flex-column min-vh-100">
        <div v-if="isLoading" class="loader-overlay">
            <div class="spinner"></div>
        </div>
        <Navbar />
        <div class="container-fluid bg-light p-4 flex-grow-1">
            <div class="row">
                <component :is="isAdminRoute ? 'Sidebar' : 'CustomerSidebar'" />
                <router-view :user="user" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import CustomerSidebar from "./components/CustomerSidebar";
import axiosInstance from "./axios";

export default {
    name: "App",
    components: {
        Navbar,
        Sidebar,
        CustomerSidebar,
    },
    data() {
        return {
            user: null,
            loading: false,
        };
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.loading.isLoading,
        }),
        isAdminRoute() {
            return this.$route.path.startsWith("/admin");
        },
    },
    methods: {
        ...mapActions(["startLoading", "stopLoading"]),
        async fetchUserData() {
            const current_user_url = process.env.VUE_APP_API_URL + "users/current";

            try {
                const response = await axiosInstance.get(current_user_url, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                });

                if (response.data && response.data.success === false) {
                    throw new Error(response.data.error || "An unknown error occurred");
                }

                this.user = response.data;

                if (response.data) {
                    this.$store.commit("updateUser", response.data);
                }
            } catch (error) {
                console.error("Error fetching user data:", error.response?.data?.error || error.message);
            } finally {
                this.stopLoading();
            }
        },
    },
    mounted() {
        this.$router.beforeEach((to, from, next) => {
            this.startLoading();

            const includedPaths = ["/widgets", "/projects", "/account"];
            const isIncluded = includedPaths.some((path) => to.path.startsWith(path));

            if (isIncluded) {
                this.fetchUserData().then(() => {
                    next();
                });
            } else {
                next();
            }
        });

        this.$router.afterEach(() => {
            this.stopLoading();
        });
    },
};
</script>
