<template>
    <div class="container mt-3">
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-striped align-middle rounded-1 overflow-hidden">
                <thead class="table-dark">
                    <tr>
                        <th v-for="header in headers" :key="header" class="py-2 px-3">{{ header }}</th>
                        <th v-if="actions.length" class="py-2 px-3 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in data" :key="index" class="transition-all">
                        <td v-for="key in Object.keys(item)" :key="key" class="py-2 px-3">{{ item[key] }}</td>
                        <td v-if="actions.length" class="py-2 px-3 text-center">
                            <div v-for="action in actions" :key="action.label" class="d-flex flex-wrap gap-2">
                                <button
                                    v-if="action.label === 'View'"
                                    @click="action.handler(item)"
                                    type="button"
                                    class="btn btn-sm btn-primary"
                                >
                                    <i class="fas fa-eye d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">View</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <nav aria-label="Page navigation" v-if="data && pagination?.total > 1">
            <ul class="pagination flex-wrap">
                <li class="page-item" :class="{ disabled: !pagination.prev_page_url }">
                    <button type="button" class="page-link" @click="changePage(pagination.current_page - 1)">Previous</button>
                </li>
                <li
                    class="page-item"
                    v-for="page in pagination.last_page"
                    :key="page"
                    :class="{ active: pagination.current_page === page }"
                >
                    <button type="button" class="page-link" @click="changePage(page)">{{ page }}</button>
                </li>
                <li class="page-item" :class="{ disabled: !pagination.next_page_url }">
                    <button type="button" class="page-link" @click="changePage(pagination.current_page + 1)">Next</button>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        headers: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        pagination: {
            type: Object,
        },
    },
    methods: {
        truncate(text, length = 20) {
            if (!text) return '';
            return text.length > length ? text.substring(0, length) + '...' : text;
        },
        changePage(page) {
            if (page > 0 && page <= this.pagination.last_page) {
                this.$emit('page-changed', page);
            }
        },
    },
};
</script>

<style scoped>
.transition-all {
    transition: all 0.2s ease;
}
</style>
