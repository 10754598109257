<template>
    <Read ref="readComponent" />
</template>

<script>
import Read from "./Read";

export default {
    components: {
        Read,
    },
};
</script>