<template>
    <div class="quill-container">
        <MyQuillEditor
            ref="editor"
            v-model:content="content"
            contentType="html"
            :theme="theme"
            class="quill-editor"
            :toolbar="toolbarOptions"
            @ready="onEditorReady"
        />
    </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import "@vueup/vue-quill/dist/vue-quill.bubble.css";
import DOMPurify from "dompurify";

export default {
    name: "QuillEditor",
    components: {
        MyQuillEditor: QuillEditor,
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        theme: {
            type: String,
            default: "snow",
        },
    },
    computed: {
        content: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
                this.$emit("input", value);
            },
        },
    },
    data() {
        return {
            toolbarOptions: [
                ["bold", "italic", "underline", "strike"],
                ["blockquote", "code-block"],
                ["link", "image", "video", "formula"],

                [{ header: 1 }, { header: 2 }],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                [{ direction: "rtl" }],

                [{ size: ["small", false, "large", "huge"] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ color: [] }, { background: [] }],
                [{ font: [] }],
                [{ align: [] }],
                ["html"],

                ["clean"],
            ],
            htmlHandlerAttached: false,
        };
    },
    methods: {
        onEditorReady(quill) {
            this.quillInstance = quill;
            this.setupToolbarHandlers();
        },
        setupToolbarHandlers() {
            const toolbar = this.quillInstance.getModule("toolbar");
            if (toolbar) {
                toolbar.addHandler("image", this.imageHandler);

                const htmlButton = this.$el.querySelector(".ql-html");

                if (htmlButton && !this.htmlHandlerAttached) {
                    htmlButton.innerHTML = '<i class="fa fa-code"></i>';
                    htmlButton.addEventListener("click", this.htmlHandler);
                    this.htmlHandlerAttached = true;
                }
            }
        },
        imageHandler() {
            const url = prompt("Please enter the image URL:");
            if (url) {
                const range = this.quillInstance.getSelection();
                if (range) {
                    this.quillInstance.insertEmbed(range.index, "image", url);
                } else {
                    console.error("Selection range is not found.");
                }
            }
        },
        htmlHandler() {
            const that = this;
            console.log("HTML Handler Triggered");
            const htmlContent = prompt("Please enter the HTML content:");
            if (htmlContent) {
                const sanitizedContent = DOMPurify.sanitize(htmlContent, { ADD_ATTR: ["target", "onclick"] });

                console.log("sanitize: ", sanitizedContent);

                const range = that.quillInstance.getSelection();
                if (range) {
                    that.quillInstance.root.innerHTML = sanitizedContent;
                } else {
                    console.error("Selection range is not found.");
                }
            }
        },
    },
};
</script>

<style>
.quill-container {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}
.ql-editor {
    height: 300px;
    max-height: 400px;
    background-color: #f1f1f1;
    color: #000000;
}
.ql-toolbar {
    background-color: #ffffff;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.quill-editor {
    background-color: #ffffff;
    color: #000000;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.ql-snow.ql-toolbar .ql-formats button {
    border-radius: 3px;
    transition: all 0.2s ease-out;
}

.ql-snow.ql-toolbar button.ql-active {
    background-color: #21ba58;
    color: #ffffff;
}

.ql-snow.ql-toolbar button.ql-active svg > * {
    stroke: #ffffff !important;
}

.ql-snow.ql-toolbar button:hover {
    background-color: #21ba58;
    color: #ffffff;
}

.ql-snow.ql-toolbar button:hover svg > * {
    stroke: #ffffff !important;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
    background-color: #21ba58;
    color: #ffffff;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active svg > * {
    stroke: #ffffff !important;
}

.ql-picker-label {
    border: 5px;
    transition: all 0.2s ease-out;
}

.ql-snow.ql-toolbar .ql-picker-label:hover {
    background-color: #21ba58;
    color: #ffffff;
}

.ql-snow.ql-toolbar .ql-picker-label:hover svg > * {
    stroke: #ffffff !important;
}

.ql-editor.ql-blank::before {
    color: #aaaaaa;
}

.ql-editor a {
    color: #21ba58;
}

.ql-picker-options .ql-picker-item {
    transition: all 0.2s ease-out;
}

.ql-picker-options .ql-picker-item:hover,
.ql-picker-options .ql-picker-item.ql-selected {
    background-color: #21ba58 !important;
    color: #ffffff !important;
}
</style>
