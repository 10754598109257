<template>
    <div class="col-sm-9">
        <div class="d-flex flex-wrap justify-content-between mb-3 flex-column flex-sm-row align-items-lg-center gap">
            <h4 class="me-auto d-flex align-items-center">
                <span class="me-2" @click="resetProjectId" style="cursor: pointer">My Widgets</span>
                <span v-if="projectName" class="fw-normal"> &rsaquo; {{ projectName }}</span>
            </h4>

            <form @submit.prevent="search(event)">
                <div class="input-group float-end">
                    <input
                        v-model="searchKeyword"
                        type="text"
                        class="form-control"
                        placeholder="Type any word..."
                        aria-label="Search"
                        aria-describedby="button-addon2"
                    />
                    <button class="btn btn-secondary" type="submit" id="button-addon2">
                        <i class="bi bi-search"></i>
                    </button>
                </div>
            </form>
        </div>

        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>
        <div class="table-responsive bg-white p-20 rounded" v-else>
            <table class="table table-hovered table-fixed">
                <colgroup v-if="hasProjects">
                    <col style="width: 40%" />
                    <col style="width: 30%" />
                    <col style="width: 30%" />
                </colgroup>
                <colgroup v-else>
                    <col style="width: 70%" />
                    <col style="width: 30%" />
                </colgroup>

                <thead v-if="!data.data || data.data.length < 1">
                    <tr>
                        No widgets found.
                    </tr>
                </thead>
                <thead v-if="data.data && data.data.length > 0">
                    <tr>
                        <th>Name</th>
                        <th v-if="hasProjects">Project</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody v-if="data.data && data.data.length > 0">
                    <tr v-for="item in data.data" :key="item.id">
                        <td>
                            {{ item.name }}
                            <div v-if="item.widget_type_id == 1">
                                <router-link :to="'/update-widget/users/' + item.id + '/tooltips'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 2">
                                <router-link :to="'/update-widget/onboarding-checklist-items/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 3">
                                <router-link :to="'/update-widget/quick-feedback/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 6">
                                <router-link :to="'/update-widget/social-proofs/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 4">
                                <router-link :to="'/update-widget/product-updates/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 5">
                                <router-link :to="'/update-widget/announcements/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 7">
                                <router-link :to="'/update-widget/vote-button/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 11">
                                <router-link :to="'/update-widget/featured-articles/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 12">
                                <router-link :to="'/update-widget/exit-intent-popup/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 13">
                                <router-link :to="'/update-widget/back-to-top-button/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 15">
                                <router-link :to="'/update-widget/changelog/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 14">
                                <router-link :to="'/update-widget/product-tour/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 16">
                                <router-link :to="'/update-widget/social-share-button/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 17">
                                <router-link :to="'/update-widget/cookie-consent/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 18">
                                <router-link :to="'/update-widget/cancellation-form/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 19">
                                <router-link :to="'/update-widget/faq/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 20">
                                <router-link :to="'/update-widget/table-of-contents/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 21">
                                <router-link :to="'/update-widget/email-signup-form/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type_id == 22">
                                <router-link :to="'/update-widget/pricing-table/' + item.id + '/'">
                                    {{ item.widget_type.name }}
                                </router-link>
                            </div>
                            <div v-else-if="item.widget_type">
                                {{ item.widget_type.name }}
                            </div>
                        </td>

                        <td v-if="hasProjects">
                            <span
                                v-if="item.project"
                                class="badge rounded-pill fw-normal p-0 d-inline-flex align-items-center"
                                :style="{
                                    backgroundColor: item.project.background_color,
                                    color: item.project.text_color,
                                }"
                            >
                                <button
                                    @click="showProjectWidgets(item.project.id)"
                                    class="px-3 cursor-pointer lh-sm bg-transparent border-0"
                                    :style="{
                                        color: item.project.text_color,
                                    }"
                                >
                                    {{ item.project.name }}
                                </button>

                                <tooltip-wrapper tooltipText="Remove widget from project" size="sm" v-if="item.project">
                                    <button
                                        class="btn btn-sm p-0 border-0 text-white bg-dark bg-opacity-25 px-1 rounded-pill rounded-start-0"
                                        @click="removeWidgetFromProject(item.id)"
                                        :disabled="loadingWidgetId === item.id"
                                    >
                                        <i v-if="loadingWidgetId !== item.id" class="bi bi-x"></i>
                                        <span v-else class="spinner-border spinner-border-sm"></span>
                                    </button>
                                </tooltip-wrapper>
                            </span>
                            <span v-else>
                                <button
                                    class="btn btn-link p-0 fw-normal"
                                    style="text-decoration: none"
                                    @click="$parent.$refs.selectProjectComponent.show(item.id)"
                                >
                                    <span class="badge rounded-pill bg-light border text-primary">
                                        <i class="bi bi-plus"></i> Add to project
                                    </span>
                                </button>
                            </span>
                        </td>

                        <td>
                            <div class="d-flex flex-wrap gap-2">
                                <router-link
                                    v-if="item.widget_type_id == 1"
                                    class="btn btn-primary"
                                    :to="'/update-widget/tooltips/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 2"
                                    class="btn btn-primary"
                                    :to="'/update-widget/onboarding-checklist-items/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 3"
                                    class="btn btn-primary"
                                    :to="'/update-widget/quick-feedback/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 4"
                                    class="btn btn-primary"
                                    :to="'/update-widget/product-updates/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 5"
                                    class="btn btn-primary"
                                    :to="'/update-widget/announcements/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 6"
                                    class="btn btn-primary"
                                    :to="'/update-widget/social-proofs/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 7"
                                    class="btn btn-primary"
                                    :to="'/update-widget/vote-button/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 11"
                                    class="btn btn-primary"
                                    :to="'/update-widget/featured-articles/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 12"
                                    class="btn btn-primary"
                                    :to="'/update-widget/exit-intent-popup/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 13"
                                    class="btn btn-primary"
                                    :to="'/update-widget/back-to-top-button/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 15"
                                    class="btn btn-primary"
                                    :to="'/update-widget/changelog/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 14"
                                    class="btn btn-primary"
                                    :to="'/update-widget/product-tour/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 16"
                                    class="btn btn-primary"
                                    :to="'/update-widget/social-share-button/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 17"
                                    class="btn btn-primary"
                                    :to="'/update-widget/cookie-consent/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 18"
                                    class="btn btn-primary"
                                    :to="'/update-widget/cancellation-form/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 19"
                                    class="btn btn-primary"
                                    :to="'/update-widget/faq/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 20"
                                    class="btn btn-primary"
                                    :to="'/update-widget/table-of-contents/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 21"
                                    class="btn btn-primary"
                                    :to="'/update-widget/email-signup-form/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <router-link
                                    v-if="item.widget_type_id == 22"
                                    class="btn btn-primary"
                                    :to="'/update-widget/pricing-table/' + item.id + '/'"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </router-link>
                                <button
                                    @click="$parent.$refs.deleteComponent.showDeleteModal(item.id)"
                                    type="button"
                                    class="btn btn-danger"
                                >
                                    <i class="bi bi-trash d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Delete</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <nav aria-label="Page navigation example" v-if="data.data && data.data.length > 0">
                <ul class="pagination justify-content-center flex-wrap gap-1">
                    <span v-for="(item, index) in data.links" :key="item.url + item.label" class="me-1">
                        <li :class="{ active: item.active }" class="page-item">
                            <a
                                v-if="item.url && !(paginationLoadingIndex === index)"
                                @click="handlePageClick(item, index)"
                                class="page-link"
                            >
                                <span v-html="item.label"></span>
                            </a>
                            <span v-else-if="paginationLoadingIndex === index" class="page-link">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </span>
                        </li>
                    </span>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import TooltipWrapper from "../../../components/TooltipWrapper.vue";
import store from "../../../store";

export default {
    components: { TooltipWrapper },
    data() {
        return {
            loading: true,
            data: "",

            id: "",
            name: "",
            desciption: "",

            edit_modal: null,
            delete_modal: null,

            paginationLoadingIndex: null,

            url: process.env.VUE_APP_API_URL + `widgets/${this.$store.getters.logged_in_user.data.user.id}`,

            loadingWidgetId: null,
            projects: [],
            searchKeyword: "",
            projectId: null,
            page: 1,
        };
    },
    watch: {
        "$route.query": {
            handler() {
                this.read();
            },
            deep: true,
            immediate: true,
        },

        searchKeyword(newSearch) {
            if (newSearch === "") {
                this.search();
            }
        },
    },
    computed: {
        hasProjects() {
            return this.projects.length > 0;
        },
        projectName() {
            if (!this.projectId) return null;
            const project = this.projects.find((p) => p.id == this.projectId);
            return project ? project.name : null;
        },
    },
    methods: {
        resetProjectId() {
            this.projectId = null;

            this.$router.push({
                query: {
                    ...this.$route.query,
                    project_id: undefined,
                    page: undefined,
                },
            });
        },
        handlePageClick(item, index) {
            this.paginationLoadingIndex = index;
            const pageNumber = this.getPageNumberFromUrl(item.url);
            this.page = pageNumber;

            this.$router.push({
                query: {
                    ...this.$route.query,
                    search: this.searchKeyword ?? undefined,
                    page: this.page ?? 1,
                },
            });
        },
        getPageNumberFromUrl(url) {
            const urlParams = new URLSearchParams(url.split("?")[1]);
            return parseInt(urlParams.get("page")) || 1;
        },
        async search() {
            this.loading = true;
            this.$router.push({
                query: {
                    ...this.$route.query,
                    search: this.searchKeyword ?? undefined,
                    page: undefined,
                },
            });
        },
        showProjectWidgets(projectId) {
            this.projectId = projectId;
            this.$router.push({
                query: {
                    ...this.$route.query,
                    project_id: String(projectId),
                    page: undefined,
                },
            });
        },
        async read() {
            const params = new URLSearchParams(this.$route.query).toString();
            const url = `${process.env.VUE_APP_API_URL}widgets/${this.$store.getters.logged_in_user.data.user.id}?${params}`;

            try {
                const res = await fetch(url, {
                    headers: new Headers({
                        Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                    }),
                });

                this.data = await res.json();

                // this.data.data.forEach(async (widget) => {
                //     const widgetType = widget.widget_type.name.replace(/\s+/g, "-").toLowerCase();
                //     const folderName = this.pluralize(widgetType);
                //     const widgetId = widget.id;
                //     const fileServerUrl = process.env.VUE_APP_FILE_SERVER_URL || "";
                //     const fileUrl = `${fileServerUrl}${folderName}/${widgetId}.json`;

                //     try {
                //         const widgetRes = await fetch(fileUrl, {
                //             headers: new Headers({
                //                 Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                //             }),
                //         });

                //         if (widgetRes.ok) {
                //             const widgetData = await widgetRes.json();
                //             window.widgetData[widgetId] = widgetData;
                //         }
                //     } catch (error) {
                //         console.error(`Error fetching JSON for widget ${widgetId}:`, error);
                //     }
                // });

                window.scrollTo(0, 0);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                this.paginationLoadingIndex = null;
                this.loading = false;
            }
        },

        async removeWidgetFromProject(widgetId) {
            if (this.loadingWidgetId) return;
            this.loadingWidgetId = widgetId;

            try {
                const res = await fetch(`${process.env.VUE_APP_API_URL}widgets/${widgetId}/remove-project`, {
                    method: "POST",
                    headers: new Headers({
                        Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                        "Content-Type": "application/json",
                    }),
                });

                if (!res.ok) {
                    throw new Error("Failed to remove widget from project");
                }

                this.read();
            } catch (error) {
                console.error("Error removing widget from project:", error);
            } finally {
                this.loadingWidgetId = null;
            }
        },

        async fetchProjects() {
            try {
                const res = await fetch(process.env.VUE_APP_API_URL + `projects/read`, {
                    headers: {
                        Authorization: `Bearer ${store.getters.logged_in_user.data.token}`,
                    },
                });

                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }

                this.projects = await res.json();
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        },
        pluralize(word) {
            if (word.toLowerCase().endsWith("s")) {
                return word.toLowerCase();
            }

            return word + "s";
        },
    },
    async mounted() {
        this.searchKeyword = this.$route.query.search || "";
        this.projectId = this.$route.query.project_id || "";
        this.page = this.$route.query.page || 1;

        await Promise.all([this.fetchProjects(), this.read()]);

        this.loading = false;
    },
};
</script>

<style scoped>
.table-fixed {
    table-layout: fixed;
    width: 100%;
}

@media (max-width: 768px) {
    .table-fixed {
        table-layout: auto;
    }
}
</style>
