<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Content text align</label>
            <select
                v-model="localWidgetSettings.content_alignment"
                class="form-select setting"
                name="content_alignment"
                id="content_alignment"
            >
                <option value="0">Left</option>
                <option value="1">Center</option>
                <option value="2">Right</option>
            </select>
        </div>
        <div class="form-group">
            <label for="">Position</label>
            <select v-model="localWidgetSettings.position" class="form-select setting" name="position" id="position">
                <option value="0">Lower Left Corner</option>
                <option value="1">Lower Center</option>
                <option value="2">Lower Right Corner</option>
                <option value="3">Upper Left Corner</option>
                <option value="4">Upper Center</option>
                <option value="5">Upper Right Corner</option>
                <option value="6">Left Center</option>
                <option value="7">Right Center</option>
                <option value="8">Center</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
