<template>
    <div class="row">
        <div class="d-flex justify-content-center align-items-center">
            <div :class="['card', { 'full-width-card': fullWidth }]" :style="cardStyle">
                <div class="card-body">
                    <h5 class="card-title mb-4">Signup to Webynize</h5>
                    <div>
                        <div class="d-grid mx-auto mb-2">
                            <a href="#" id="btn-google" class="btn btn-dark btn-block">
                                <img
                                    :src="require('@/assets/google-logo.svg')"
                                    alt=""
                                    :style="{ width: '25px', height: '25px' }"
                                    class="me-2"
                                />
                                Signup with Google
                            </a>
                        </div>
                        <div class="divider text-center">or</div>
                        <label class="form-label">Email</label>
                        <input type="email" class="form-control mb-3" required v-model="email" />

                        <button @click="signUp($event)" type="submit" class="btn btn-primary mb-3">Signup</button>

                        <div class="">
                            Already have an account?
                            <router-link
                                :to="{ name: 'Login', query: widget_id ? { widget_id: widget_id } : {} }"
                                class="card-link"
                            >
                                Login
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props: {
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            token: "",
            email: "",
            password: "",
            sign_up_failed: false,
            widget_id: this.$route.query.widget_id || this.$route.params.widget_id,
        };
    },
    computed: {
        cardStyle() {
            if (this.fullWidth) {
                return {
                    width: "100%",
                    border: "none",
                };
            }
            return {
                width: "100%",
                maxWidth: "600px",
            };
        },
    },
    methods: {
        ...mapActions(["signUpAuth"]),
        async signUp(event) {
            event.target.disabled = true;
            event.target.classList.add("loading-btn");

            try {
                const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
                this.token = await window.grecaptcha.execute(recaptchaSiteKey, { action: "submit" });

                var user = {
                    token: this.token,
                    email: this.email,
                    ...(this.widget_id ? { widget_id: this.widget_id } : {}),
                };

                await this.signUpAuth(user);
            } catch {
                this.sign_up_failed = true;
            }

            event.target.disabled = false;
            event.target.classList.remove("loading-btn");
        },
    },
    mounted() {
        let __this = this;
        const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
        let url = process.env.VUE_APP_API_URL + "google/loginOrSignup/";

        if (this.widget_id) {
            url += `?widget_id=${this.widget_id}`;
        }

        document.getElementById("btn-google").setAttribute("href", url);
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=" + recaptchaSiteKey;
        script.onload = () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(recaptchaSiteKey, {
                        action: "submit",
                    })
                    .then((token) => {
                        const badgeElement = document.querySelector(".grecaptcha-badge");
                        if (badgeElement) {
                            badgeElement.style.display = "none";
                        }
                        __this.token = token;
                    });
            });
        };
        document.body.appendChild(script);
    },
    beforeUnmount() {
        const script = document.querySelector('script[src*="recaptcha/api.js"]');
        if (script) {
            script.remove();
        }
    },
};
</script>

<style scoped></style>
