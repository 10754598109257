<template>
    <div class="modal" id="vote_viewer_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Vote Viewer</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div v-if="loading" class="loading-overlay-alt">
                    <div class="spinner"></div>
                </div>
                <div class="modal-body" v-else>
                    <div v-if="showEmailList">
                        <div class="container mt-3 d-flex justify-content-between align-items-center">
                            <h6 class="mb-0">Email List for: {{ selectedPage }}</h6>
                            <button @click="showEmailList = false" class="btn btn-sm btn-secondary px-3">Back</button>
                        </div>

                        <BaseTable
                            title="Vote Summary"
                            :headers="['Email', 'Date']"
                            :data="voterEmails"
                            :pagination="paginationEmails"
                        />
                    </div>

                    <div v-else>
                        <thead v-if="!voteSummary || voteSummary.length < 1">
                            <tr>
                                No data found.
                            </tr>
                        </thead>
                        <BaseTable
                            title="Vote Summary"
                            :headers="['Page Title', 'Keyword', 'Total Votes']"
                            :data="voteSummary"
                            :pagination="pagination"
                            :actions="[{ label: 'View', handler: fetchVoterEmails }]"
                            @page-changed="handlePageChange"
                            v-else
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

<script>
import axios from "axios";
import { Modal } from "bootstrap";
import BaseTable from "./BaseTable.vue";

export default {
    components: {
        BaseTable,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            widget_id: "",
            vote_viewer_modal: null,
            loading: true,
            voteSummary: [],
            pagination: {},
            showEmailList: false,
            selectedPage: "",
            selectedKeyword: null,
            voterEmails: [],
            paginationEmails: {},
            widget_settings: {
                selector: "",
                show_on: "mouseover",
                position: "bottom",
            },
        };
    },
    methods: {
        async read() {
            var url = process.env.VUE_APP_API_URL + `vote-buttons/readByWidgetId/${this.$route.params.widget_id}`;
            const res = await fetch(url, {
                headers: new Headers({
                    Authorization: "Bearer " + this.$store.getters.logged_in_user.data.token,
                }),
            });
            var data = await res.json();
            if (data) {
                this.widget_settings = data;
            }
            this.fetchVoteSummary();
        },
        async fetchVoteSummary(page = 1) {
            this.loading = true;

            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API_URL}widget-votes/${this.$route.params.widget_id}?page=${page}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                );
                this.voteSummary = response.data.data;
                this.pagination = response.data.meta;
            } catch (error) {
                console.error("Error fetching emails:", error);
            } finally {
                this.loading = false;
            }
        },
        async fetchVoterEmails(item, page = 1) {
            this.selectedPage = item.page;
            this.selectedKeyword = item.keyword || null;

            try {
                const encodedPage = encodeURIComponent(this.selectedPage);
                let url = `${process.env.VUE_APP_API_URL}widget-votes/${this.$route.params.widget_id}/${encodedPage}?page=${page}`;

                if (this.selectedKeyword) {
                    url += `&keyword=${encodeURIComponent(this.selectedKeyword)}`;
                }

                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                });

                this.voterEmails = response.data;
                this.showEmailList = true;
            } catch (error) {
                console.error("Error fetching emails:", error);
            } finally {
                this.loading = false;
            }
        },
        handlePageChange(page) {
            this.fetchVoteSummary(page);
        },
        showVoteViewer() {
            this.vote_viewer_modal.show();
            this.read();
        },
    },
    mounted() {
        this.vote_viewer_modal = new Modal(document.getElementById("vote_viewer_modal"));
    },
};
</script>
