<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Title Text Align</label>
            <select
                v-model="localWidgetSettings.title_text_align"
                class="form-select setting"
                name="title_text_align"
                id="title_text_align"
            >
                <option value="0">Left</option>
                <option value="1">Center</option>
                <option value="2">Right</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
