<template>
    <div
        class="modal"
        id="editProductTourItemModal"
        tabindex="-1"
        aria-labelledby="editProductTourItemModalLabel"
        aria-hidden="true"
        ref="editModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editProductTourItemModalLabel">Edit</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <input name="id" v-model="id" type="hidden" />

                        <div class="mb-3">
                            <label for="title" class="form-label">Title</label>
                            <input
                                type="text"
                                class="form-control"
                                id="title"
                                v-model="data.title"
                                required
                                placeholder="Enter the name"
                                @blur="validateField('title')"
                            />
                            <p v-if="!validations.title" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="description" class="form-label">Description</label>
                            <QuillEditor
                                id="description"
                                v-model="data.description"
                                theme="snow"
                                @blur="validateField('description')"
                            />
                            <p v-if="!validations.description" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="video_url" class="form-label">Video URL</label>
                            <input
                                type="text"
                                class="form-control"
                                id="video_url"
                                v-model="data.video_url"
                                required
                                placeholder="Enter the name"
                                @blur="validateField('video_url')"
                            />
                            <p v-if="!validations.video_url" class="text-danger small">This field is required.</p>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="submitForm">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import QuillEditor from "../../../components/QuillEditor.vue";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            data: {
                title: "",
                description: "",
                video_url: "",
                widget_id: this.$route.params.widget_id,
            },
            validations: {
                title: true,
                description: true,
                video_url: true,
            },
        };
    },
    computed: {
        isFormValid() {
            return Object.values(this.validations).every((isValid) => isValid);
        },
    },
    methods: {
        async submitForm(event) {
            event.preventDefault();

            this.validateAllFields();

            if (!this.isFormValid) {
                console.warn("Form is invalid");
                return;
            }

            try {
                const response = await axios.put(
                    `${process.env.VUE_APP_API_URL}onboarding-checklist-items/${this.data.id}`,
                    this.data,
                    {
                        headers: { Authorization: "Bearer " + this.token },
                    }
                );

                console.log("Item Updated:", response.data);

                const modalInstance = Modal.getInstance(this.$refs.editModal);
                this.clearForm();
                modalInstance.hide();

                this.$emit("updated-item");
                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error updating item:", error);
            }
        },
        clearForm() {
            this.data = {
                title: "",
                description: "",
                video_url: "",
                widget_id: this.$route.params.widget_id,
            };
        },
        validateField(field) {
            console.log(this.data[field]);
            if (this.data[field] === "") {
                this.validations[field] = false;
            } else {
                this.validations[field] = true;
            }
        },
        validateAllFields() {
            Object.keys(this.validations).forEach((field) => {
                this.validateField(field);
            });
        },
        showModal(event, item) {
            this.data = { ...item };
            const modalInstance = new Modal(this.$refs.editModal);
            modalInstance.show();
        },
    },
    mounted() {},
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
