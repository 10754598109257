<template>
    <div class="form-grid">
        <div class="form-group">
            <button type="button" class="btn btn-primary" @click="showCreateModal">
                <i class="bi bi-plus"></i> Create New Plan
            </button>
        </div>

        <div class="form-group form-grid-span-full">
            <Table :items="items" />
        </div>
    </div>

    <Create ref="createComponent" />
    <Edit ref="editComponent" />
    <Delete ref="deleteComponent" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Table from "../Table.vue";
import Create from "../Create.vue";
import Edit from "../Edit.vue";
import Delete from "../Delete.vue";

export default {
    components: {
        Table,
        Create,
        Edit,
        Delete,
    },
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings", "update-preview"],
    data() {
        return {
            widgetId: this.$route.params.widget_id,
            items: [],
        };
    },
    computed: {
        ...mapGetters({
            token: "token",
        }),
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        updatePreview() {
            this.$emit("update-preview");
        },
        showCreateModal() {
            this.$refs.createComponent.showModal();
        },
        showEditModal(event, item) {
            this.$refs.editComponent.showModal(event, item);
        },
        showDeleteModal(event, id) {
            this.$refs.deleteComponent.showModal(event, id);
        },
        async cloneItem(event, id) {
            this.loading = true;

            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}pricing-table-items/${id}/clone`,
                    { widget_id: this.widgetId },
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                );

                const clonedItem = response.data.data;
                this.items.push(clonedItem);
                this.fetchItems();
                this.updatePreview();
            } catch (error) {
                console.error("Error cloning item:", error);
            } finally {
                this.loading = false;
            }
        },
        async fetchItems() {
            this.loading = true;

            try {
                const response = await axios.get(
                    `${process.env.VUE_APP_API_URL}pricing-table-items?widget_id=${this.widgetId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                );

                this.items = response.data;
            } catch (error) {
                console.error("Error fetching items:", error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchItems();
    },
};
</script>
