<template>
    <Read ref="readComponent" />
    <Analytics ref="analyticsComponent" widget-type="Tooltips" :show-clicks="false" />
</template>

<script>
import Read from "./Read";

import Analytics from "../../../components/Analytics.vue";

export default {
    components: {
        Read,

        Analytics,
    },
};
</script>
