<template>
    <div class="col-sm-6 col-lg-6 mb-3 d-flex">
        <div class="option-card" :class="{ active: isSelected }" @click="toggleSelection" style="cursor: pointer">
            <div class="option-content">
                <div class="option-text">
                    <p class="option-title">
                        {{ item.name.length > 30 ? item.name.slice(0, 30) + "..." : item.name }}
                    </p>

                    <p v-if="item.description" class="option-description">
                        {{ truncatedDescription }}
                    </p>
                </div>
                <div v-if="isSelected" class="option-check">
                    <svg class="check-icon" viewBox="0 0 24 24" fill="none">
                        <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                        <path
                            d="M7 13l3 3 7-7"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectableCard",
    props: {
        item: {
            type: Object,
            required: true,
        },

        selected: {
            type: [Number, String, Array],
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        truncateLimit: {
            type: Number,
            default: 12,
        },
    },
    computed: {
        isSelected() {
            if (this.multiple) {
                return Array.isArray(this.selected) && this.selected.includes(this.item.id);
            }
            return this.selected === this.item.id;
        },
        truncatedDescription() {
            const text = this.item.description || "";
            return text.length <= this.truncateLimit ? text : text.substring(0, this.truncateLimit) + "...";
        },
    },
    methods: {
        toggleSelection() {
            if (this.multiple) {
                let newSelection = Array.isArray(this.selected) ? [...this.selected] : [];
                if (newSelection.includes(this.item.id)) {
                    newSelection = newSelection.filter((id) => id !== this.item.id);
                } else {
                    newSelection.push(this.item.id);
                }
                this.$emit("update:selected", newSelection);
            } else {
                this.$emit("update:selected", this.item.id);
            }
        },
    },
};
</script>

<style scoped>
.option-card {
    position: relative;
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.75rem;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
    width: 100%;
    align-content: center;
}
.option-card:hover {
    background-color: #f3f4f6;
}
.option-card.active {
    background-color: #21ba58;
    color: #fff;
    border-color: transparent;
}
.option-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.option-text {
    font-size: 0.875rem;
    flex-grow: 1;
}
.option-title {
    margin: 0;
    font-weight: 500;
}
.option-description {
    margin: 0;
    color: inherit;
    font-size: 0.75rem;
    opacity: 0.9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.option-check {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}
.check-icon {
    width: 1.5rem;
    height: 1.5rem;
}
</style>
