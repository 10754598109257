<template>
    <div class="d-grid">
        <div v-if="!items && items.length < 1" class="text-center">No data found.</div>

        <div class="table-responsive" v-else>
            <table class="table table-dark table-hover table-striped align-middle rounded-1 overflow-hidden">
                <thead>
                    <tr>
                        <th>Plans</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.id">
                        <td>
                            {{ truncate(item.name, 35) }}
                            <span v-if="item.status === 1" class="badge bg-success ms-2">Active</span>
                            <span v-else class="badge bg-secondary ms-2">Inactive</span>
                            <div class="d-flex gap-2 mt-2">
                                <button
                                    class="btn btn-primary btn-sm flex-grow-1"
                                    @click.prevent="this.$parent.showEditModal(event, item)"
                                >
                                    <i class="fas fa-pen d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Edit</span>
                                </button>
                                <button
                                    class="btn btn-danger btn-sm flex-grow-1"
                                    @click.prevent="this.$parent.showDeleteModal(event, item.id)"
                                >
                                    <i class="bi bi-trash d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Delete</span>
                                </button>
                                <button
                                    class="btn btn-secondary btn-sm flex-grow-1"
                                    @click.prevent="this.$parent.cloneItem(event, item.id)"
                                >
                                    <i class="fas fa-copy d-inline d-sm-none"></i>
                                    <span class="d-none d-sm-inline">Clone</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "Table",
    components: {},
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    methods: {
        truncate(text, length = 20) {
            if (!text) return "";
            return text.length > length ? text.substring(0, length) + "..." : text;
        },
    },
};
</script>

<style scoped>
.buttons {
    margin-top: 35px;
}
</style>
