<template>
    <div class="card h-100">
        <div class="card-body">
            <div class="d-flex flex-row flex-wrap mb-2 justify-content-between align-items-start">
                <span v-if="loading" class="skeleton-loader skeleton-20 skeleton-lg"></span>
                <div v-else>
                    <div class="d-flex align-items-center">
                        <h2 class="card-title mb-0">{{ title }}</h2>
                    </div>
                </div>
            </div>

            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        loading: Boolean,
        title: String,
    },
};
</script>

<style scoped>
.card {
    border-radius: 10px;
    border: none;
    transition: box-shadow 0.3s ease-in-out;
}
.card:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.025) !important;
}
.card-body {
    padding: 1.5rem;
}
.card-title {
    font-size: 1.25rem;
    color: #333;
    font-weight: 600;
}
.price {
    font-size: 2rem;
    color: #333;
    font-weight: 500;
}
.card-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff !important;
    border-radius: 0 0 10px 10px;
}
.btn-primary,
.btn-outline-primary {
    padding: 0.375rem 1.5rem;
}
</style>
