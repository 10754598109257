<template>
    <button class="btn btn-primary fs-5 d-flex justify-content-center align-items-center" @click="showModal">
        View Feedbacks
    </button>
    <div class="modal" tabindex="-1" id="feedbacksModal" ref="feedbacksModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Feedbacks</h5>
                    <button type="button" class="btn-close" @click="hideModal"></button>
                </div>
                <div v-if="loading" class="loading-overlay-alt">
                    <div class="spinner"></div>
                </div>
                <div class="modal-body" v-else>
                    <CancellationFormFeedbacksTable
                        :items="items"
                        :pagination="pagination"
                        @page-changed="fetchItems"
                        @delete-item="handleDeleteItem"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CancellationFormFeedbacksTable from './CancellationFormFeedbacksTable.vue';
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    components: {
        CancellationFormFeedbacksTable,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? '',
            loading: true,
            items: [],
            pagination: {},
            modalInstance: null,
            widget_id: this.$route.params.widget_id,
        };
    },
    methods: {
        async fetchItems(page = 1) {
            this.loading = true;

            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}cancellation-form-feedbacks?page=${page}&widget_id=${this.widget_id}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                });
                this.items = response.data.data;
                this.pagination = response.data.meta;
            } catch (error) {
                console.error("Error fetching items:", error);
            }  finally {
                this.loading = false;
            }
        },
        async handleDeleteItem(itemId) {
            this.loading = true;

            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}cancellation-form-feedbacks/${itemId}?widget_id=${this.widget_id}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                });
                await this.fetchItems(this.pagination.current_page);
            } catch (error) {
                console.error("Error deleting item:", error.response ? error.response.data : error.message);
            } finally {
                this.loading = false;
            }
        },
        showModal() {
            this.modalInstance = new Modal(this.$refs.feedbacksModal);
            this.modalInstance.show();
            this.fetchItems();
        },
        hideModal() {
            if (this.modalInstance) {
                this.modalInstance.hide();
            }
        },
    },
    mounted() {
        this.fetchItems();
    },
};
</script>

<style scoped>
</style>
