<template>
    <div>
        <div class="wbnz-pricing-table" data-webynize-id="599"></div>
    </div>
</template>

<script>
export default {
    mounted() {
        const script = document.createElement("script");
        script.src = "https://widgets.webynize.com/pricing-table.js";
        script.async = true;
        this.$el.appendChild(script);
    },
};
</script>

<style scoped></style>
