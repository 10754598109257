<template>
    <div class="col-sm-12 p-20">
        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>
        <div v-else>
            <h2 class="text-center">{{ widgetTitle }}</h2>
            <form style="margin: 0px auto !important" class="col-md-6 col-lg-3" @submit.prevent="create">
                <div class="mb-3">
                    <label for="widget_type_id" class="text-start mb-1">Type</label>
                    <select
                        id="widget_type_id"
                        name="widget_type_id"
                        type="text"
                        class="form-select mb-2"
                        v-model="selected_type"
                        @change="updateWidgetTypeRoute"
                    >
                        <option value="" disabled>Please select a widget type…</option>
                        <option v-for="widget_type in widget_types" :key="widget_type.id" :value="widget_type.id">
                            {{ widget_type.name }}
                        </option>
                    </select>
                    <div v-if="errors.selected_type" class="text-danger small">{{ errors.selected_type }}</div>
                </div>

                <div v-if="selectedDescription" class="widget-description mb-3">
                    <div class="card">
                        <div class="card-body text-start">
                            <p class="card-text fs-6">{{ selectedDescription }}</p>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="widget_name" class="text-start mb-2">Name</label>
                    <input id="widget_name" v-model="name" type="text" class="form-control mb-1" required />
                    <div v-if="errors.name" class="text-danger small">{{ errors.name }}</div>
                </div>

                <button
                    ref="submitButton"
                    type="button"
                    class="w-100 btn btn-primary d-flex align-items-center justify-content-center"
                    @click="create"
                    :disabled="creating || !selected_type || !name"
                >
                    <span
                        v-if="creating"
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span>{{ creating ? "Creating..." : "Next" }}</span>
                </button>
            </form>
        </div>
        <div class="modal" id="signup_modal" tabindex="-1" aria-labelledby="SignUpModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="SignUpModalLabel">You’re almost there!</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="bb-content">
                            <p>Please sign up so we can save your progress on creating widgets to your account.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <router-link to="/signup/" class="btn btn-primary"> Sign up now </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="prompt"
        tabindex="-1"
        aria-labelledby="promptLabel"
        aria-hidden="true"
        ref="promptModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Widget Creation Failed</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ message }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Okay</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            loading: false,
            creating: false,
            selected_type: "",
            name: "",
            widget_types: [],
            selectedDescription: "",
            signup_modal: null,
            modalInstance: null,
            message: "Failed to create widget. Please try again later.",
            widgetTitle: "Create a widget",
            errors: {},
        };
    },
    methods: {
        validateForm() {
            this.errors = {};

            if (!this.selected_type) {
                this.errors.selected_type = "Widget type is required.";
            }
            if (!this.name) {
                this.errors.name = "Name is required.";
            } else if (this.name.length < 3) {
                this.errors.name = "Name must be at least 3 characters.";
            } else if (this.name.length > 150) {
                this.errors.name = "Name must not exceed 150 characters.";
            }

            return Object.keys(this.errors).length === 0;
        },
        async readWidgetTypes() {
            this.loading = true;
            const url = `${process.env.VUE_APP_API_URL}widget-types/status/1?order_by=asc`;
            const res = await fetch(url);
            this.widget_types = await res.json();
            this.updateTitle();
            this.updateDescription();
            this.loading = false;
        },
        updateWidgetTypeRoute() {
            this.$router.push({ path: `/create-widget/${this.selected_type}/` });
            this.updateTitle();
            this.updateDescription();
        },
        updateDescription() {
            const widget = this.widget_types.find((type) => type.id === this.selected_type);
            this.selectedDescription = widget ? widget.description : "";
        },
        async create() {
            if (!this.validateForm()) return;

            this.creating = true;

            const user_id = this.$store.getters?.logged_in_user?.data?.user?.id || 0;
            const payload = {
                name: this.name,
                user_id,
                widget_type_id: this.selected_type,
            };

            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}widgets`, payload);
                if (response.data.id) {
                    const widgetId = response.data.id;
                    const typeRoutes = {
                        1: "tooltips",
                        2: "onboarding-checklist-items",
                        3: "quick-feedback",
                        4: "product-updates",
                        5: "announcements",
                        6: "social-proofs",
                        7: "vote-button",
                        11: "featured-articles",
                        12: "exit-intent-popup",
                        13: "back-to-top-button",
                        14: "product-tour",
                        15: "changelog",
                        16: "social-share-button",
                        17: "cookie-consent",
                        18: "cancellation-form",
                        19: "faq",
                        20: "table-of-contents",
                        21: "email-signup-form",
                        22: "pricing-table",
                    };
                    const route = typeRoutes[this.selected_type] || "widgets";
                    this.$router.push(`/update-widget/${route}/${widgetId}/`);
                }
                console.log(response.data);
                if (!response.data.success) {
                    this.showModal(response.data.message);
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.creating = false;
            }
        },
        updateTitle() {
            const widget = this.widget_types.find((type) => type.id === this.selected_type);
            this.widgetTitle = widget ? `Create a ${widget.name} widget` : "Create a widget";
            document.title = this.widgetTitle;
        },
        hideModal() {
            this.modalInstance.hide();
        },
        showModal(message) {
            this.message = message;
            this.modalInstance.show();
        },
    },
    mounted() {
        this.selected_type = Number(this.$route.params.widget_id) || "";
        this.readWidgetTypes();
        this.signup_modal = new Modal(document.getElementById("signup_modal"));
        this.modalInstance = new Modal(this.$refs.promptModal);
    },
    watch: {
        "$route.params.widget_id": function (newVal) {
            this.selected_type = Number(newVal) || "";
            this.updateTitle();
            this.updateDescription();
        },
        selected_type() {
            this.updateTitle();
            this.updateDescription();
        },
    },
};
</script>
