<template>
    <div class="form-group mb-4">
        <label for="">Button font size</label>
        <input
            v-model="localWidgetSettings.button_font_size"
            type="number"
            id="button_font_size"
            class="form-control setting"
        />
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
