<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Add-ons</h4>

        <div class="pt-3">
            <div class="row">
                <div class="col-md-6 mb-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between mb-3">
                                <div>
                                    <h2 class="card-title">Buy more widgets</h2>
                                    <p>Purchase additional widgets</p>
                                </div>

                                <div class="d-flex align-items-center">
                                    <button class="btn qty-btn" @click="decreaseQuantity">
                                        <i class="bi bi-dash"></i>
                                    </button>
                                    <input
                                        type="number"
                                        v-model="quantity"
                                        class="form-control text-center qty-input"
                                        min="1"
                                        @input="validateQuantity"
                                    />
                                    <button class="btn qty-btn" @click="increaseQuantity">
                                        <i class="bi bi-plus"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between mb-0">
                                <span>Total Cost:</span>
                                <div class="d-flex align-items-center">
                                    <span class="text-primary fw-bold fs-4">${{ totalPrice }}</span>
                                    <span class="text-muted small ms-2">
                                        /
                                        <tooltip-wrapper
                                            :tooltipText="cycle === 1 ? 'Billed annually' : 'Billed monthly'"
                                            size="sm"
                                        >
                                            {{ cycle === 1 ? "year" : "month" }}
                                        </tooltip-wrapper>
                                    </span>
                                </div>
                            </div>

                            <div v-if="message" :class="`alert mt-3 ${messageType}`">
                                {{ message }}
                            </div>

                            <button class="btn btn-primary w-100 mt-3" @click="showModal" :disabled="loading">
                                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                                <span v-else>Buy Now</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="confirmPurchaseModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirm Purchase</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to purchase <strong>{{ quantity }}</strong> additional widgets? You will
                        be <strong>charged immediately</strong> for <strong>${{ totalPrice }}</strong
                        >, and this amount will also be added to your
                        <strong>{{ cycle === 1 ? "yearly" : "monthly" }}</strong> billing moving forward.
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary" @click="buyWidgets">
                            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                            <span v-else>Proceed</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "../store";
import { Modal } from "bootstrap";
import TooltipWrapper from "../components/TooltipWrapper.vue";

export default {
    components: {
        TooltipWrapper,
    },
    data() {
        return {
            cycle: store.getters.logged_in_user?.data?.user?.cycle,
            quantity: 1,
            loading: false,
            message: null,
            messageType: "alert-success",
            modalInstance: null,
        };
    },
    computed: {
        totalPrice() {
            const pricePerSource = this.cycle === 1 ? 50 : 5;
            return this.quantity * pricePerSource;
        },
    },
    methods: {
        decreaseQuantity() {
            if (this.quantity > 1) {
                this.quantity--;
            }
        },
        increaseQuantity() {
            this.quantity++;
        },
        validateQuantity() {
            if (this.quantity < 1 || isNaN(this.quantity)) {
                this.quantity = 1;
            }
        },
        showModal() {
            if (!this.modalInstance) {
                this.modalInstance = new Modal(document.getElementById("confirmPurchaseModal"));
            }
            this.modalInstance.show();
        },
        async buyWidgets() {
            this.loading = true;
            this.message = null;

            try {
                const response = await axios.post("buy-more-widgets", {
                    quantity: this.quantity,
                });

                if (response.data.success) {
                    this.message = "Widgets purchased successfully!";
                    this.messageType = "alert-success";
                } else {
                    this.message = response.data.message || "Something went wrong.";
                    this.messageType = "alert-danger";
                }

                if (response.data.user) {
                    this.$store.commit("updateUser", response.data.user);
                }
            } catch (error) {
                this.message = "An error occurred. Please try again.";
                this.messageType = "alert-danger";
            } finally {
                this.loading = false;
                this.modalInstance.hide();
            }
        },
    },
};
</script>

<style scoped>
.card {
    border-radius: 10px;
    border: none;
    transition: box-shadow 0.3s ease-in-out;
}

.card:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.025) !important;
}

.card-body {
    padding: 1.5rem;
}

.card-title {
    font-size: 1.25rem;
    color: #333;
    font-weight: 600;
}

.price {
    font-size: 2rem;
    color: #333;
    font-weight: 500;
}

.card-text {
    font-size: 1rem;
}

.card-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fff !important;
}

.card-footer:last-child {
    border-radius: 0 0 10px 10px;
}

.btn-primary,
.btn-secondary {
    padding: 0.375rem 1.5rem;
}

.list-group-item {
    border: none;
    padding: 1rem 0;
}

.list-group-item:not(:last-child) {
    border-bottom: 1px solid #e9ecef;
}

.table tr {
    transition: all 0.2s ease;
}

.skeleton-loader {
    display: inline-block;
    min-width: 50px;
    height: 20px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 5px;
}

.skeleton-progress {
    display: inline-block;
    width: 100%;
    height: 10px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 5px;
}

.skeleton-sm {
    height: 1rem;
}
.skeleton-md {
    height: 1.5rem;
}
.skeleton-lg {
    height: 2.5rem;
}
.skeleton-xl {
    height: 3.5rem;
}

.skeleton-10 {
    width: 10%;
}
.skeleton-20 {
    width: 20%;
}
.skeleton-30 {
    width: 30%;
}
.skeleton-40 {
    width: 40%;
}
.skeleton-50 {
    width: 50%;
}
.skeleton-60 {
    width: 60%;
}
.skeleton-70 {
    width: 70%;
}
.skeleton-80 {
    width: 80%;
}
.skeleton-90 {
    width: 90%;
}
.skeleton-100 {
    width: 100%;
}

@keyframes skeleton-loading {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

@keyframes skeleton-loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.qty-btn {
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.qty-btn:hover {
    background-color: #e0e0e0;
    border-color: #bbb;
}

.qty-btn:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.qty-btn:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.qty-input {
    width: 60px;
    padding: 0.375rem 0;
    font-size: 1rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 0;
}

.qty-input::-webkit-outer-spin-button,
.qty-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.qty-input[type="number"] {
    -moz-appearance: textfield;
}
</style>
