<template>
    <div
        class="modal fade"
        id="prompt"
        tabindex="-1"
        aria-labelledby="promptLabel"
        aria-hidden="true"
        ref="promptModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Widget Creation Failed</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>{{ message }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Okay</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            modalInstance: null,
            message: "Failed to create widget. Please try again later.",
        };
    },
    methods: {
        hideModal() {
            this.modalInstance.hide();
        },
        showModal(message) {
            this.message = message;
            this.modalInstance.show();
        },
    },
    mounted() {
        this.modalInstance = new Modal(this.$refs.promptModal);
    },
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
