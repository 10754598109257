<template>
    <div class="modal" ref="signupModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Embed {{ formatWidgetName(widgetName) }} widget</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div v-if="isLoading" class="loading-overlay-alt">
                    <div class="spinner"></div>
                </div>

                <div class="modal-body" v-else>
                    <div class="alert alert-info d-flex align-items-center mx-3" role="alert">
                        <i class="bi bi-info-circle-fill me-2"></i>
                        <p class="mb-0">
                            Please signup to save your {{ formatWidgetName(widgetName) }} to your account.
                        </p>
                    </div>

                    <Signup :full-width="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import Signup from "../views/Signup.vue";

export default {
    name: "SignupModal",
    components: {
        Signup,
    },
    props: {
        widgetName: {
            type: String,
            default: "Widget",
        },
    },
    mounted() {
        this.modalInstance = new Modal(this.$refs.signupModal);
    },
    unmounted() {
        this.modalInstance.hide();
    },
    methods: {
        showModal() {
            this.modalInstance.show();
        },
        hideModal() {
            this.modalInstance.hide();
        },
        formatWidgetName(name) {
            return name
                .split("-")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        },
    },
};
</script>
