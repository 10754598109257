<template>
    <!-- edit modal -->
    <div class="modal" id="edit_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit plan</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <input name="id" v-model="id" type="hidden" />

                    <div>Name</div>
                    <input id="name" name="name" v-model="name" type="text" class="form-control mb-3" />

                    <div>Cost</div>
                    <input name="cost" v-model="cost" type="text" class="form-control mb-3" />
                    <div>Payment link</div>
                    <input name="payment_link" v-model="payment_link" type="text" class="form-control mb-3" />

                    <div>Views per month</div>
                    <input name="views_per_month" v-model="views_per_month" type="text" class="form-control mb-3" />
                    <div>Order</div>
                    <input name="order" v-model="order" type="text" class="form-control mb-3" />
                    <div>Cycle</div>
                    <select v-model="cycle" class="form-select mb-3">
                        <option value="0" selected>Monthly</option>
                        <option value="1">Yearly</option>
                    </select>
                    <div>Status</div>
                    <select v-model="status" class="form-select mb-3">
                        <option value="4" selected>Active</option>
                        <option value="0">Inactive</option>
                    </select>

                    <div>Description</div>
                    <textarea name="description" v-model="description" type="text" class="form-control mb-3" />
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button @click="update($event)" type="button" class="btn btn-primary">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "../../../store";
import { Modal } from "bootstrap";

export default {
    data() {
        return {
            id: "",
            name: "",
            cost: "",
            cycle: "",
            status: "",
            payment_link: "",
            order: "",
            views_per_month: "",
            description: "",
            edit_modal: null,
        };
    },
    methods: {
        resetFields() {
            this.id = "";
            this.name = "";
            this.cost = "";
            this.cycle = "";
            this.status = "";
            this.payment_link = "";
            this.order = "";
            this.views_per_month = "";
            this.description = "";
        },
        async update(event) {
            event.target.disabled = true;

            axios
                .put(
                    process.env.VUE_APP_API_URL + "plans/update",
                    {
                        id: this.id,
                        name: this.name,
                        cost: this.cost,
                        cycle: this.cycle,
                        status: this.status,
                        payment_link: this.payment_link,
                        order: this.order,
                        views_per_month: this.views_per_month,
                        description: this.description,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                        },
                    }
                )
                .then(
                    (response) => {
                        if (response.data.success == true) {
                            this.$parent.$refs.readComponent.read();
                        }

                        event.target.disabled = false;
                        this.edit_modal.hide();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        async showEditModal(event, id) {
            event.target.disabled = true;
            this.resetFields();

            // request data from api
            const url = process.env.VUE_APP_API_URL + "plans/read/" + id;
            const res = await fetch(url, {
                headers: new Headers({
                    Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });
            var record = await res.json();

            // assign values to model
            this.id = record.id;
            this.name = record.name;
            this.cost = record.cost;
            this.cycle = record.cycle;
            this.status = record.status;
            this.payment_link = record.payment_link;
            this.order = record.order;
            this.views_per_month = record.views_per_month;
            this.description = record.description;

            this.edit_modal.show();
            event.target.disabled = false;
        },
    },
    mounted() {
        this.edit_modal = new Modal(document.getElementById("edit_modal"));
    },
};
</script>
