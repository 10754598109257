<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.background_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Title font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.title_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.title_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Description font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.description_font_color"
                    type="text"
                    id="description_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.description_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Radio Button color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.radio_button_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.radio_button_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Item background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.item_name_background_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.item_name_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Item Name font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.item_name_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.item_name_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Item Description font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.item_description_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.item_description_font_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Submit Button font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.submit_button_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.submit_button_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Submit Button background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.submit_button_background_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.submit_button_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Contact Button font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.contact_button_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.contact_button_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Contact Button background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.contact_button_background_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.contact_button_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Input font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.input_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.input_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Label font color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.label_font_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.label_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Input background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.input_background_color"
                    type="text"
                    id="qf-tb-bg-color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.input_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Branding color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.branding_color"
                    type="text"
                    id="branding_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.branding_color" :disabled="isRestricted" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
