<template>
    <div
        class="modal"
        id="editProductTourItemModal"
        tabindex="-1"
        aria-labelledby="editProductTourItemModalLabel"
        aria-hidden="true"
        ref="editModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editProductTourItemModalLabel">Edit</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <div class="mb-3">
                            <label for="question" class="form-label">Question</label>
                            <input
                                type="text"
                                class="form-control"
                                id="question"
                                v-model="data.question"
                                required
                                placeholder="Enter the question"
                                @blur="validateField('question')"
                            />
                            <p v-if="!validations.question" class="text-danger small">This field is required.</p>
                        </div>

                        <div class="mb-3">
                            <label for="answer" class="form-label">Answer</label>
                            <QuillEditor
                                id="answer"
                                v-model="data.answer"
                                theme="snow"
                                @blur="validateField('answer')"
                            />
                            <p v-if="!validations.answer" class="text-danger small">This field is required.</p>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <base-button type="button" :isLoading="loading" :disabled="loading" @click="submitForm">
                        Save
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import QuillEditor from "../../../components/QuillEditor.vue";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            loading: false,
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            data: {
                question: "",
                answer: "",
                widget_id: this.$route.params.widget_id,
            },
            validations: {
                question: true,
                answer: true,
            },
        };
    },
    computed: {
        isFormValid() {
            return Object.values(this.validations).every((isValid) => isValid);
        },
    },
    methods: {
        async submitForm(event) {
            event.preventDefault();

            this.validateAllFields();

            if (!this.isFormValid) {
                console.warn("Form is invalid");
                return;
            }

            this.loading = true;

            try {
                const response = await axios.put(`${process.env.VUE_APP_API_URL}faq-items/${this.data.id}`, this.data, {
                    headers: { Authorization: "Bearer " + this.token },
                });

                console.log("Item Updated:", response.data);

                const modalInstance = Modal.getInstance(this.$refs.editModal);
                this.clearForm();
                modalInstance.hide();

                this.$emit("updated-item");
                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error updating item:", error);
            } finally {
                this.loading = false;
            }
        },
        clearForm() {
            this.data = {
                question: "",
                answer: "",
                widget_id: this.$route.params.widget_id,
            };
        },
        validateField(field) {
            console.log(this.data[field]);
            if (this.data[field] === "") {
                this.validations[field] = false;
            } else {
                this.validations[field] = true;
            }
        },
        validateAllFields() {
            Object.keys(this.validations).forEach((field) => {
                this.validateField(field);
            });
        },
        showModal(event, item) {
            this.data = { ...item };
            const modalInstance = new Modal(this.$refs.editModal);
            modalInstance.show();
        },
    },
    mounted() {},
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
</style>
