<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Content background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.content_background_color"
                    type="text"
                    id="content_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.content_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Title text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.title_font_color"
                    type="text"
                    id="title_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.title_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">List text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.list_font_color"
                    type="text"
                    id="list_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.list_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Mark done background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.mark_done_button_background_color"
                    type="text"
                    id="mark_done_button_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.mark_done_button_background_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Mark done text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.mark_done_button_font_color"
                    type="text"
                    id="mark_done_button_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker
                    v-model="localWidgetSettings.mark_done_button_font_color"
                    :disabled="isRestricted"
                />
            </div>
        </div>
        <div class="form-group">
            <label for="">Checkbox background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.checkbox_background_color"
                    type="text"
                    id="checkbox_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.checkbox_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Popup background color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.popup_background_color"
                    type="text"
                    id="popup_background_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.popup_background_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Popup text color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.popup_font_color"
                    type="text"
                    id="popup_font_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.popup_font_color" :disabled="isRestricted" />
            </div>
        </div>
        <div class="form-group">
            <label for="">Branding color</label>
            <div class="form-group">
                <input
                    v-model="localWidgetSettings.branding_color"
                    type="text"
                    id="branding_color"
                    class="form-control setting setting-color"
                    @click="handleProtectedClick"
                    :readonly="isRestricted"
                />
                <pickr-color-picker v-model="localWidgetSettings.branding_color" :disabled="isRestricted" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
        isRestricted: { type: Boolean, required: true },
    },
    emits: ["update:widgetSettings", "show-upgrade"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
    methods: {
        handleProtectedClick() {
            this.$emit("show-upgrade");
        },
    },
};
</script>
