<template>
    <div class="modal" id="edit_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Edit User Activity</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div v-if="loading" class="spinner-container">
                    <div class="spinner" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>

                <div class="modal-body" v-else>
                    <input name="id" v-model="id" type="hidden" />
                    <div class="mb-3">
                        <label for="description" class="form-label">Description</label>
                        <QuillEditor id="description" v-model="description" theme="snow" @blur="validateField('description')" />
                        <p v-if="!validations.description" class="text-danger small">This field is required.</p>
                    </div>

                    <div class="mb-3">
                        <label for="date_time">Date and Time</label>
                        <input name="date_time" v-model="date_time" type="datetime-local" class="form-control mb-3" @blur="validateField('date_time')" />
                        <p v-if="!validations.date_time" class="text-danger small">This field is required.</p>
                    </div>

                    <div class="mb-3">
                        <label for="activity_type_id">Activity Type</label>
                        <select v-model="activity_type_id" class="form-select mb-3" @blur="validateField('activity_type_id')">
                            <option v-for="type in activityTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                        </select>
                        <p v-if="!validations.activity_type_id" class="text-danger small">This field is required.</p>
                    </div>

                    <div class="mb-3">
                        <label for="widget_type_id">Widget Type</label>
                        <select v-model="widget_type_id" class="form-select mb-3" @blur="validateField('widget_type_id')">
                            <option v-for="type in widgetTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <base-button type="button" variant="secondary" data-bs-dismiss="modal">Close</base-button>
                    <base-button @click="update($event)" type="button" :isLoading="saving" :disabled="!isFormValid || loading">Save changes</base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "../../../store";
import { Modal } from "bootstrap";
import QuillEditor from "../../../components/QuillEditor.vue";

export default {
    components: {
        QuillEditor,
    },
    data() {
        return {
            saving: false,
            id: "",
            description: "",
            date_time: "",
            activity_type_id: null,
            widget_type_id: null,
            activityTypes: [],
            widgetTypes: [],
            validations: {
                description: true,
                date_time: true,
                activity_type_id: true,
            },
            loading: false,
            edit_modal: null,
        };
    },
    computed: {
        isFormValid() {
            return Object.values(this.validations).every(isValid => isValid);
        }
    },
    methods: {
        resetFields() {
            this.id = "";
            this.description = "";
            this.date_time = "";
            this.activity_type_id = null;
            this.widget_type_id = null;
        },

        validateField(field) {
            if (this[field] === null || this[field] === '') {
                this.validations[field] = false;
            } else {
                this.validations[field] = true;
            }
        },

        async update(event) {
            this.saving = true;
            event.target.disabled = true;

            this.validateField('description');
            this.validateField('date_time');
            this.validateField('activity_type_id');

            if (!this.isFormValid) {
                event.target.disabled = false;
                return;
            }

            axios.put(
                process.env.VUE_APP_API_URL + "user-activities/update",
                {
                    id: this.id,
                    description: this.description,
                    date_time: this.date_time,
                    activity_type_id: this.activity_type_id,
                    widget_type_id: this.widget_type_id,
                },
                {
                    headers: {
                        Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                    },
                }
            ).then(
                (response) => {
                    console.log(response)
                    if (response.data.success === true || response.statusText === "OK") {
                        this.$parent.$refs.readComponent.read();
                    }

                    event.target.disabled = false;
                    this.edit_modal.hide();
                },
                (error) => {
                    console.log(error);
                }
            ).finally(() => {
                this.saving = false;
            });
        },

        async showEditModal(event, id) {
            event.target.disabled = true;
            this.resetFields();
            this.loading = true;
            this.edit_modal.show();

            const activityTypes = this.$parent.$refs.readComponent.activityTypes;
            const widgetTypes = this.$parent.$refs.readComponent.widgetTypes;

            const url = process.env.VUE_APP_API_URL + "user-activities/read/" + id;
            const res = await fetch(url, {
                headers: new Headers({
                    Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                }),
            });

            const activity = await res.json();

            this.id = activity.id;
            this.description = activity.description;
            this.date_time = activity.date_time;
            this.activity_type_id = activity.activity_type_id;
            this.widget_type_id = activity.widget_type_id;

            this.activityTypes = activityTypes;
            this.widgetTypes = widgetTypes;

            this.loading = false;

            event.target.disabled = false;
        },
    },
    mounted() {
        this.edit_modal = new Modal(document.getElementById("edit_modal"));
    },
};
</script>

<style scoped>
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
</style>