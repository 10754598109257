<template>
    <div class="modal fade" ref="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="btn-close" @click="handleClose" :disabled="isSaving"></button>
                </div>
                <div class="modal-body">
                    <div v-if="loading" class="d-flex justify-content-center my-3">
                        <div class="spinner-border text-primary" role="status"></div>
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <slot v-else></slot>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" @click="handleClose" :disabled="isSaving">
                        Close
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary d-flex align-items-center gap-2"
                        @click="handleSave"
                        :disabled="isSaving"
                    >
                        <span v-if="isSaving" class="spinner-border spinner-border-sm"></span>
                        <span>Save Changes</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
    props: {
        title: String,
        loading: { type: Boolean, default: false },
    },
    data() {
        return { isSaving: false };
    },
    mounted() {
        this.modalInstance = new Modal(this.$refs.modal, { backdrop: "static", keyboard: false });
    },
    methods: {
        show() {
            this.isSaving = false;
            this.modalInstance.show();
        },
        handleClose() {
            this.modalInstance.hide();
            this.$emit("close");
        },
        async handleSave() {
            this.isSaving = true;
            this.$emit("save", this.finish, this.stop);
        },
        finish() {
            this.isSaving = false;
            this.handleClose();
        },
        stop() {
            this.isSaving = false;
        },
    },
};
</script>
