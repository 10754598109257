<template>
    <transition name="fade-blur">
        <div v-if="show" class="overlay" @click.self="handleClose" @keydown.esc="handleClose" tabindex="0">
            <div class="overlay-content">
                <i class="fas fa-bolt icon"></i>
                <p class="mb-3 fs-6">
                    This feature is available only in our paid plan.
                    <template v-if="canStartTrial">
                        Please <a href="#" @click.prevent="showTrialPopup">start a 7-day premium trial</a> or
                    </template>
                    <router-link :to="{ name: 'ChangePlan' }">purchase a paid plan</router-link>.
                </p>
                <router-link :to="{ name: 'ChangePlan' }" class="btn btn-primary btn-sm overlay-button">
                    Upgrade Now
                </router-link>
                <button type="button" class="btn btn-outline-secondary btn-sm overlay-button mt-2" @click="handleClose">
                    Close
                </button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close"],
    computed: {
        canStartTrial() {
            return this.$store.getters.canStartTrial;
        },
    },
    methods: {
        showTrialPopup() {
            const trialButton = document.querySelector(".user-status-button");
            if (trialButton) {
                trialButton.click();
            }
        },
        handleClose() {
            this.$emit("close");
        },
        handleKeydown(event) {
            if (event.key === "Escape") {
                this.handleClose();
            }
        },
    },
    mounted() {
        document.addEventListener("keydown", this.handleKeydown);
    },
    beforeUnmount() {
        document.removeEventListener("keydown", this.handleKeydown);
    },
};
</script>

<style scoped>
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
    backdrop-filter: blur(1px);
    transition: backdrop-filter 0.3s ease;
}

.overlay-content {
    padding: 25px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    max-width: 350px;
    width: 90%;
    background: #ffffff;
    color: #333;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 1;
    transform: scale(1);
}

.icon {
    font-size: 40px;
    color: #ffbe00;
    margin-bottom: 15px;
}

.btn {
    font-weight: 600;
    border-radius: 6px;
    transition: background-color 0.3s, transform 0.2s;
    padding: 8px 16px;
    text-transform: uppercase;
    font-size: 14px;
}

.overlay-button {
    width: 100%;
    text-decoration: none;
    margin-top: 15px;
}

.fade-blur-enter-active,
.fade-blur-leave-active {
    transition: opacity 0.3s ease, backdrop-filter 0.3s ease, transform 0.3s ease;
}

.fade-blur-enter-from,
.fade-blur-leave-to {
    opacity: 0;
    backdrop-filter: blur(0px);
    transform: scale(0.9);
}
</style>
