<template>
    <div class="form-grid">
        <div class="form-group">
            <label for="">Text</label>
            <textarea
                v-model="localWidgetSettings.text"
                class="form-control"
                name="text"
                id="qf-tb-text"
                cols="30"
                rows="10"
            ></textarea>
        </div>
        <div class="form-group">
            <label>Text font size</label>
            <input
                type="number"
                class="form-control"
                v-model="localWidgetSettings.text_font_size"
                id="text_font_size"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        widgetSettings: { type: Object, required: true },
    },
    emits: ["update:widgetSettings"],
    computed: {
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
    },
};
</script>
