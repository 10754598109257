import axios from "axios";
import router from "@/router";

const state = {
    user: null,
};

const getters = {
    is_authenticated: (state) => !!state.user,
    logged_in_user: (state) => state.user,
    token: (state) => state.user?.data?.token ?? "",
    id: (state) => state.user?.data?.user?.id ?? 12,
    brandingEnabled: (state) => state.user?.data?.user?.show_branding ?? 1,
    isPaidUser: (state) => [1, 6, 7].includes(state.user?.data?.user?.status),
    isAdmin: (state) => state.user?.data?.user?.access_level === "admin",
    isSupport: (state) => state.user?.data?.user?.access_level === "support",
    name: (state) => state.user?.data?.user?.name ?? "Guest",
    profilePicture: (state) => state.user?.data?.user?.profile_picture || null,
    canStartTrial: (state, getters) => {
        const user = state.user?.data?.user;
        if (!user) return false;

        return (
            !user.trial_starts_at &&
            !user.trial_ends_at &&
            !getters.isPaidUser &&
            user.access_level !== "admin" &&
            user.access_level !== "support"
        );
    },
};

const actions = {
    // called when user signs up
    async signUpAuth({ dispatch }, user) {
        var temp_pw = (Math.random() + 1).toString(36).substring(7);
        var user_data = {
            token: user.token,
            name: "No name",
            email: user.email,
            password: temp_pw,
            access_level: "customer",
            widget_id: user.widget_id || null,
        };

        // creat user
        await axios.post(process.env.VUE_APP_API_URL + "signup", user_data).then(
            async function (response) {
                if (response.data.success) {
                    const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;
                    window.grecaptcha.ready(() => {
                        window.grecaptcha
                            .execute(recaptchaSiteKey, {
                                action: "submit",
                            })
                            .then((token) => {
                                user_data.token = token;
                                dispatch("loginAuth", user_data);
                            });
                    });
                } else {
                    alert("Email is already exists.");
                }
            },
            (error) => {
                console.log(error);
            }
        );
    },

    // called when user needs to login
    async loginAuth({ commit }, user) {
        const user_data = await axios.post(process.env.VUE_APP_API_URL + "users/login", {
            token: user.token,
            email: user.email,
            password: user.password,
            widget_id: user.widget_id || null,
        });
        if (user_data && user_data.data && user_data.data.token) {
            console.log(router);
            // token will be used for every api request
            localStorage.setItem("token", user_data.data.token);

            await commit("setUser", user_data);

            if (user_data.data.user.access_level === "admin" || user_data.data.user.access_level === "support") {
                router.push("/admin/users/");
            } else if (user_data.data.project_count >= 2) {
                router.push("/projects/");
            } else {
                router.push("/widgets/");
            }
        }
    },

    // called when user click logout
    async logoutAuth({ commit }) {
        localStorage.removeItem("token");
        localStorage.removeItem("access_level");
        localStorage.removeItem("email");
        localStorage.removeItem("vuex");
        localStorage.removeItem("user_id");
        // must destroy the token on the server

        // change user state
        commit("logout");
    },
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    logout(state) {
        state.user = null;
    },
    updateUser(state, user) {
        state.user.data.user = user;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
