<template>
    <div class="d-flex">
        <tooltip-wrapper
            v-if="userRole !== 'admin' && userRole !== 'support'"
            :tooltipText="tooltipMessage"
            size="sm"
            placement="bottom"
        >
            <button v-if="!canStartTrial" :class="['btn', buttonClass, 'user-status-button']" @click="handleClick">
                <span v-if="userRole === 'admin'" class="icon"><i class="fas fa-user-shield"></i></span>
                <span v-if="userRole === 'support'" class="icon"><i class="fas fa-headset"></i></span>
                <span v-if="userRole === 'customer'" class="icon"><i class="fas fa-star"></i></span>
                {{ buttonText }}
            </button>
        </tooltip-wrapper>

        <button v-if="canStartTrial" :class="['btn', buttonClass, 'user-status-button']" @click="showModal">
            <i class="bi bi-star-fill"></i>
            Start 7-day Premium Trial
        </button>

        <div
            class="modal fade"
            id="startTrialModal"
            tabindex="-1"
            aria-labelledby="startTrialModalLabel"
            aria-hidden="true"
            ref="startTrialModal"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content p-3 shadow-lg rounded-4">
                    <div class="modal-header border-0">
                        <h5 class="modal-title fw-bold text-dark d-flex align-items-center">
                            <i class="bi bi-stars text-warning fs-4 me-2"></i> Upgrade Your Widgets – Free for 7 Days!
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body text-center">
                        <p class="text-muted">Enjoy 7 days of premium features – no credit card required! 🚀</p>

                        <div class="bg-light p-3 rounded-3 text-start">
                            <p class="fw-bold mb-2 text-primary">What You’ll Get:</p>
                            <ul class="list-unstyled mb-0">
                                <li>
                                    <i class="bi bi-check-circle-fill text-success me-2"></i> Full Color Customization –
                                    Match widgets perfectly to your brand
                                </li>
                                <li>
                                    <i class="bi bi-check-circle-fill text-success me-2"></i> Remove Branding – Keep
                                    your site looking clean and professional
                                </li>
                                <li>
                                    <i class="bi bi-check-circle-fill text-success me-2"></i> Higher View Limit – Get up
                                    to 50,000 views during your trial
                                </li>
                                <li>
                                    <i class="bi bi-check-circle-fill text-success me-2"></i> Priority Support – Get
                                    fast responses from our expert team
                                </li>
                                <li>
                                    <i class="bi bi-check-circle-fill text-success me-2"></i> Faster Widget Loading –
                                    Improve your site’s performance
                                </li>
                                <li>
                                    <i class="bi bi-check-circle-fill text-success me-2"></i> Enhanced Design Controls –
                                    Fine-tune how widgets appear
                                </li>
                                <li>
                                    <i class="bi bi-check-circle-fill text-success me-2"></i> More Display Options –
                                    Customize placement and behavior
                                </li>
                                <li>
                                    <i class="bi bi-check-circle-fill text-success me-2"></i> Early Access to New
                                    Features – Try the latest improvements first
                                </li>
                            </ul>
                        </div>

                        <p class="mt-3 text-secondary">Would you like to experience premium for free now?</p>
                    </div>

                    <div class="modal-footer border-0 justify-content-center">
                        <button type="button" class="btn btn-outline-secondary px-4 py-2" data-bs-dismiss="modal">
                            <i class="bi bi-x-circle me-1"></i> No, Thanks
                        </button>
                        <button
                            @click="startPremiumTrial"
                            type="button"
                            class="btn btn-primary px-4 py-2 fw-bold"
                            :disabled="loading"
                        >
                            <i v-if="loading" class="spinner-border spinner-border-sm me-1"></i>
                            <i v-else class="bi bi-lightning-charge-fill me-1"></i>
                            {{ loading ? "Starting..." : "Yes, Start Trial!" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import { mapGetters } from "vuex";
import TooltipWrapper from "./TooltipWrapper.vue";

export default {
    components: {
        TooltipWrapper,
    },
    name: "UserStatusButton",
    data() {
        return {
            modalInstance: null,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            user: "logged_in_user",
        }),
        userRole() {
            return this.user?.data?.user?.access_level || "customer";
        },
        userPlan() {
            return this.user?.data?.user?.plan || null;
        },
        canStartTrial() {
            const user = this.user?.data?.user || {};
            return (
                !user.trial_starts_at &&
                !user.trial_ends_at &&
                user.status !== 1 &&
                user.status !== 7 &&
                user.access_level !== "admin" &&
                user.access_level !== "support"
            );
        },
        buttonText() {
            const user = this.user?.data?.user || {};

            if (this.userRole === "admin") return "Admin";
            if (this.userRole === "support") return "Support";

            const planNames = {
                109: "Pro",
                110: "Business",
                111: "Pro",
                112: "Business",
            };

            if (this.userPlan === null && user?.trial_starts_at && user?.trial_ends_at && user.status == 6) {
                const trialEndsAt = new Date(user.trial_ends_at);
                const now = new Date();

                if (trialEndsAt > now) {
                    return "Premium Trial";
                }
            }

            if (this.userPlan === null) {
                return "Upgrade now";
            }

            return planNames[this.userPlan] || "Unknown Plan";
        },
        buttonClass() {
            return (
                {
                    admin: "admin",
                    support: "support",
                    customer: "customer",
                }[this.userRole] || "customer"
            );
        },
        tooltipMessage() {
            if (this.userRole === "admin" || this.userRole === "support") {
                return "Manage users";
            }
            if (this.userRole === "customer" && this.userPlan === null) {
                return "Upgrade plan to unlock more features";
            }
            return "Add-ons";
        },
    },
    methods: {
        handleClick() {
            if (this.userRole === "admin" || this.userRole === "support") {
                this.$router.push({ name: "Users" });
            } else if (
                this.userRole === "customer" &&
                this.user?.data?.user?.status == 1 &&
                this.userPlan !== "Unknown Plan"
            ) {
                this.$router.push({ name: "AddOns" });
            } else if (this.user?.data?.user?.status == 6) {
                this.$router.push({ name: "Billing" });
            } else if (this.user?.data?.user?.status == 13) {
                this.$router.push({ name: "ChangePlan" });
            }
        },
        showModal() {
            this.modalInstance = new Modal(this.$refs.startTrialModal);
            this.modalInstance.show();
        },
        async startPremiumTrial() {
            this.loading = true;

            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}activate-premium-trial`,
                    {},
                    {
                        headers: { Authorization: "Bearer " + this.user.data.token },
                    }
                );

                if (response.data && !response.data.success) {
                    throw new Error(response.data.message);
                }

                console.log("Trial started:", response.data);

                if (response.data.user) {
                    this.$store.commit("updateUser", response.data.user);
                }

                this.modalInstance.hide();
                this.$emit("trial-activated");
            } catch (error) {
                console.error("Error activating trial:", error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
.user-status-button {
    text-transform: uppercase;
    font-weight: 500;
    transition: background-position 0.5s ease-in-out;
    display: flex;
    align-items: center;
    gap: 8px;
}

.admin {
    background: linear-gradient(90deg, #b22222, #8b0000);
    background-size: 200% 100%;
    background-position: 100% 0;
    color: white;
}

.support {
    background: linear-gradient(90deg, #008080, #005f5f);
    background-size: 200% 100%;
    background-position: 100% 0;
    color: white;
}

.customer {
    background: linear-gradient(90deg, #ffd700, #f2a900);
    background-size: 200% 100%;
    background-position: 100% 0;
    color: white;
}

.user-status-button:hover {
    background-position: 0% 0;
    color: white;
}

.user-status-button:focus {
    box-shadow: rgba(0, 123, 255, 0.5) 0px 0px 0px 0.2rem !important;
}

.modal-content {
    border-radius: 16px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

:deep(.modal-dialog) {
    --bs-modal-width: 600px;
    max-width: var(--bs-modal-width);
}
</style>
