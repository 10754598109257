<template>
    <Read ref="readComponent" />
    <Analytics ref="analyticsComponent" widget-type="Cookie Consent" />
</template>

<script>
import Read from "./Read";
import Analytics from "../../../components/Analytics.vue";

export default {
    components: {
        Read,
        Analytics,
    },
};
</script>

<style scoped>
body {
    background: red;
}
</style>
