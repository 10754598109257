<template>
    <VoteViewer ref="voteViewerComponent" />
    <Read ref="readComponent" />
    <Analytics ref="analyticsComponent" widget-type="Vote Button" />
</template>

<script>
import Read from "./Read";
import VoteViewer from "./VoteViewer.vue";
import Analytics from "../../../components/Analytics.vue";

export default {
    components: {
        Read,
        VoteViewer,
        Analytics,
    },
};
</script>
