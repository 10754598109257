<template>
    <Read ref="readComponent" />
    <Update ref="updateComponent" />
</template>

<script>
import Read from "./Read";
import Update from "./Update";

export default {
    components: {
        Read,
        Update,
    },

    mounted() {},
};
</script>
