<template>
    <div class="form-grid">
        <div class="form-group">
            <label>Name</label>
            <input
                type="text"
                class="form-control"
                v-model="localWidgetInfo.name"
                id="widget_name"
                @blur="validateField('widgetInfo', 'name')"
            />
            <div v-if="errors.widgetInfo.name" class="invalid-feedback d-flex align-items-center">
                <i class="bi bi-exclamation-circle-fill me-1"></i> {{ errors.widgetInfo.name }}
            </div>
        </div>
        <div class="form-group">
            <label>Title</label>
            <input type="text" class="form-control" v-model="localWidgetSettings.title" id="title" />
        </div>
        <div class="form-group">
            <label>Selector</label>
            <input type="text" class="form-control" v-model="localWidgetSettings.selector" id="selector" />
        </div>
        <div class="form-group">
            <label>Width</label>
            <input type="text" class="form-control" v-model="localWidgetSettings.width" id="width" />
        </div>
        <div class="form-group">
            <label for="">Display Mode</label>
            <select
                v-model="localWidgetSettings.display_mode"
                class="form-select setting"
                name="position"
                id="display_mode"
            >
                <option value="static">Static</option>
                <option value="fixed">Fixed</option>
            </select>
        </div>
        <div v-if="!brandingEnabled" class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="show_branding"
                    v-model="localWidgetInfo.show_branding"
                    true-value="1"
                    false-value="0"
                    :value="1"
                />
                <label class="form-check-label" for="show_branding">Show Branding</label>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        widgetSettings: { type: Object, required: true },
        widgetInfo: { type: Object, required: true },
        errors: { type: Object, required: true },
        validateField: { type: Function, required: false },
    },
    emits: ["update:widgetSettings", "update:widgetInfo", "update:errors"],
    computed: {
        ...mapGetters({
            brandingEnabled: "brandingEnabled",
        }),
        localWidgetSettings: {
            get() {
                return this.widgetSettings;
            },
            set(newSettings) {
                this.$emit("update:widgetSettings", newSettings);
            },
        },
        localWidgetInfo: {
            get() {
                return this.widgetInfo;
            },
            set(newInfo) {
                this.$emit("update:widgetInfo", newInfo);
            },
        },
        localErrors: {
            get() {
                return this.errors;
            },
            set(newErrors) {
                this.$emit("update:errors", newErrors);
            },
        },
    },
};
</script>
