<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Projects</h4>

        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>

        <div class="pt-3 p-20 rounded" v-else>
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 gy-5">
                <div class="col">
                    <div class="folder add-folder" @click="showCreateModal">
                        <div class="folder-tab"></div>
                        <div class="folder-body">
                            <div class="plus-icon"><i class="bi bi-plus"></i></div>
                        </div>
                    </div>
                </div>

                <div v-for="project in projects" :key="project.id" class="col">
                    <div
                        class="folder"
                        :style="{
                            backgroundColor: project.background_color,
                            color: project.text_color,
                            '--folder-bg': project.background_color,
                        }"
                    >
                        <div class="folder-tab"></div>
                        <div class="folder-body" @click="showProjectWidgets(project.id)">
                            <h5 class="folder-title">{{ project.name }}</h5>
                            <div class="widget-container">
                                <span
                                    v-for="widget in project.widgets.slice(0, 3)"
                                    :key="widget.id"
                                    class="widget-chip"
                                >
                                    {{ widget.name.length > 15 ? widget.name.slice(0, 15) + "..." : widget.name }}
                                </span>
                                <span v-if="project.widgets.length > 3" class="more-widgets">
                                    +{{ project.widgets.length - 3 }} more
                                </span>
                            </div>
                        </div>
                        <div class="folder-actions">
                            <button class="action-btn edit" @click.stop="showEditModal(project)">
                                <i class="bi bi-pencil"></i>
                            </button>
                            <button class="action-btn delete" @click.stop="showDeleteModal(project.id)">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            loading: false,
            projects: [],
        };
    },
    methods: {
        async fetchItems() {
            this.loading = true;
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}projects/read`, {
                    headers: { Authorization: "Bearer " + (this.$store?.getters?.logged_in_user?.data?.token ?? "") },
                });
                this.projects = response.data;
            } catch (error) {
                console.error("Error fetching projects:", error);
            } finally {
                this.loading = false;
            }
        },
        showCreateModal() {
            this.$parent.$refs.createComponent.showModal();
        },
        showProjectWidgets(id) {
            this.$router.push({ name: "Widgets", query: { project_id: id } });
        },
        showEditModal(item) {
            this.$parent.$refs.editComponent.showModal(item);
        },
        showDeleteModal(id) {
            this.$parent.$refs.deleteComponent.showModal(id);
        },
        async deleteProject(projectId) {
            if (!confirm("Are you sure you want to delete this project?")) return;
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}projects/delete/${projectId}`, {
                    headers: { Authorization: "Bearer " + (this.$store?.getters?.logged_in_user?.data?.token ?? "") },
                });
                this.projects = this.projects.filter((project) => project.id !== projectId);
            } catch (error) {
                console.error("Error deleting project:", error);
            }
        },
    },

    mounted() {
        this.fetchItems();
    },
};
</script>

<style scoped>
.folder {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 150px;
    max-height: 180px;
    border-radius: 0 15px 15px 15px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.folder:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.folder-tab {
    position: absolute;
    top: -15px;
    left: 0;
    width: 50%;
    height: 15px;
    background: inherit;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* filter: brightness(0.95); */
}

.folder-tab::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 100%;
    background: inherit;
    transform: skewX(40deg);
    transform-origin: top right;
    border-radius: 5px 5px 0 0;
    background: var(--folder-bg);
}

.folder-body {
    font-weight: bold;
    height: 100%;
}

.folder-title {
    text-align: left;
    margin-bottom: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.widget-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: start;
}

.widget-chip {
    background: rgba(255, 255, 255, 0.9);
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 50px;
    white-space: nowrap;
    font-weight: 600;
    color: #333;
}

.more-widgets {
    font-size: 12px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
}

.folder.add-folder {
    background-color: #f8f9fa;
    border: 2px dashed #ced4da;
    color: #6c757d;
    transition: all 0.2s ease;
}

.folder.add-folder .folder-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.plus-icon i {
    font-size: 3rem;
    line-height: 1;
}

.folder.add-folder .folder-tab {
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 50%;
    height: 20px;
    background: #f8f9fa;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 2px dashed #ced4da;
    transition: all 0.2s ease;
}

.folder.add-folder:hover {
    border-color: #adb5bd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

.folder.add-folder:hover .folder-tab {
    border-color: #adb5bd;
}

.folder-actions {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 8px;
}

.action-btn {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-btn i {
    font-size: 16px;
    color: #f8f9fa;
    transition: color 0.2s ease-in-out;
}
</style>
