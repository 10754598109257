<template>
    <div class="col-sm-9">
        <h4 class="d-inline-block">Profile</h4>

        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>
        <div class="bg-white p-20 rounded" v-else>
            <form @submit.prevent="submitForm" enctype="multipart/form-data">
                <div class="row">
                    <div class="col-md-4 d-flex flex-column justify-content-center align-items-center mb-3">
                        <div class="rounded-circle bg-light d-flex justify-content-center align-items-center" style="width: 8rem; height: 8rem;">
                            <img v-if="currentProfilePicture || imagePreview" 
                                :src="imagePreview || currentProfilePicture" 
                                class="img-fluid rounded-circle" 
                                alt="Profile Image" 
                                style="object-fit: cover; width: 100%; height: 100%;" />
                            <i v-else class="bi bi-person-circle text-muted" style="font-size: 3rem;"></i>
                        </div>
                        <button
                            type="button"
                            class="btn btn-outline-secondary btn-sm mt-3"
                            @click="triggerFileInput">
                            Change
                        </button>
                        <input type="file" ref="fileInput" class="d-none" accept="image/*" @change="onFileChange" />
                    </div>

                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <label for="name" class="form-label">Name</label>
                                <input type="text" class="form-control" name="name" v-model="name" required>
                                <div v-if="errors.name" class="text-danger mt-1">
                                    <ul class="list-unstyled">
                                        <li v-for="(error, index) in errors.name" :key="index">{{ error }}</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <label for="email" class="form-label">Primary email</label>
                                <input type="email" class="form-control" name="email" v-model="email" required>
                                <div v-if="errors.email" class="text-danger mt-1">
                                    <ul class="list-unstyled">
                                        <li v-for="(error, index) in errors.email" :key="index">{{ error }}</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <label for="secondary_email" class="form-label">Secondary email</label>
                                <input type="email" class="form-control" name="secondary_email" v-model="secondary_email">
                            </div>

                            <div class="col-md-12 mb-3">
                                <label for="company" class="form-label">Company name</label>
                                <input type="text" class="form-control" name="company" v-model="company">
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                        <base-button type="submit" :isLoading="saving" :disabled="saving">Submit</base-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import store from "../store";

export default {
    data() {
        return {
            loading: true,
            saving: false,
            name: '',
            email: '',
            secondary_email: '',
            company: '',
            profileImage: null,
            imagePreview: null,
            currentProfilePicture: null,
            errors: {},
        };
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.profileImage = file;

                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        async submitForm() {
            this.errors = {};
            await this.updateProfile();
        },
        async updateProfile() {
            this.saving = true;
            const formData = new FormData();
            formData.append('id', this.$store.getters.logged_in_user.data.user.id);
            formData.append('name', this.name);
            formData.append('email', this.email);
            formData.append('secondary_email', this.secondary_email || '');
            formData.append('company', this.company || '');

            if (this.profileImage) {
                formData.append('profile_picture', this.profileImage);
            }

            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}users/update`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${store.getters.logged_in_user.data.token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                if (response.data.success) {
                    alert('Profile was updated successfully!');
                } else {
                    alert('Update failed');
                }
            } catch (error) {
                if (error.response && error.response.data.errors) {
                    this.errors = error.response.data.errors;
                } else {
                    console.error('Error updating profile:', error);
                }
            } finally {
                this.saving = false;
            }
        },
        async readUser() {
            let url = `${process.env.VUE_APP_API_URL}users/read/${store.getters.logged_in_user.data.user.id}`;
            const res = await fetch(url, {
                headers: new Headers({
                    Authorization: `Bearer ${store.getters.logged_in_user.data.token}`,
                }),
            });

            let user = await res.json();
            this.name = user.name;
            this.email = user.email;
            this.secondary_email = user.secondary_email;
            this.company = user.company;

            this.currentProfilePicture = user.profile_picture
            ? `${process.env.VUE_APP_FILE_SERVER_URL}${user.profile_picture}?nocache=${Date.now()}`
            : null;

            this.loading = false;
        },
    },
    mounted() {
        this.readUser();
    },
};
</script>
