<template>
    <div class="col-sm-9">
        <div class="d-flex flex-wrap justify-content-between mb-3 flex-column flex-sm-row align-items-center gap-3">
            <h4 class="me-auto d-flex align-items-center">Stats</h4>

            <div class="d-flex align-items-center">
                <div class="form-check form-switch d-flex align-items-center">
                    <input v-model="showChart" class="form-check-input me-2" type="checkbox" id="toggleChart" />
                    <label class="form-check-label mb-0" for="toggleChart">Chart View</label>
                </div>

                <VueDatePicker v-model="dateRange" range @update:modelValue="fetchStats" class="w-auto ms-sm-3" />
            </div>
        </div>

        <div v-if="loading" class="loading-overlay-alt">
            <div class="spinner"></div>
        </div>

        <div class="pt-3" v-else>
            <div class="row">
                <div class="col-md-4 mb-4">
                    <StatCard title="Most Created Widgets">
                        <DoughnutChart v-if="showChart && chartData.widgets" :chart-data="chartData.widgets" />
                        <ul
                            v-else
                            class="list-group list-group-flush mt-3 rounded"
                            style="max-height: 400px; overflow-y: auto"
                        >
                            <li
                                v-for="widget in mostCreatedWidgets"
                                :key="widget.type"
                                class="list-group-item d-flex justify-content-between align-items-center px-0"
                            >
                                <span class="fw-medium text-secondary">{{ widget.type }}</span>
                                <span class="badge bg-primary px-3 py-2">{{ widget.count }}</span>
                            </li>
                        </ul>
                    </StatCard>
                </div>

                <div class="col-md-4 mb-4">
                    <StatCard title="User Stats">
                        <DoughnutChart v-if="showChart && chartData.userStats" :chart-data="chartData.userStats" />
                        <ul v-else class="list-group list-group-flush mt-3" style="max-height: 400px; overflow-y: auto">
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                <span class="fw-medium text-body-secondary">Signed Up</span>
                                <span class="badge bg-secondary px-3 py-2">{{ userStats.signups }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                <span class="fw-medium text-body-secondary">Paid</span>
                                <span class="badge bg-success px-3 py-2">{{ userStats.paid_users }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                <span class="fw-medium text-body-secondary">Premium Trials</span>
                                <span class="badge bg-info px-3 py-2">{{ userStats.premium_trials }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                <span class="fw-medium text-body-secondary">Created Widgets</span>
                                <span class="badge bg-primary px-3 py-2">{{ userStats.users_who_have_widgets }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                <span class="fw-medium text-body-secondary">Has No Widgets</span>
                                <span class="badge bg-danger px-3 py-2">{{ userStats.users_without_widgets }}</span>
                            </li>
                        </ul>
                    </StatCard>
                </div>

                <div class="col-md-4 mb-4">
                    <StatCard title="Login Stats">
                        <DoughnutChart v-if="showChart && chartData.logins" :chart-data="chartData.logins" />
                        <ul v-else class="list-group list-group-flush mt-3" style="max-height: 400px; overflow-y: auto">
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                <span class="fw-medium text-body-secondary">Via Google</span>
                                <span class="badge bg-primary px-3 py-2">{{ logins.via_google }}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center px-0">
                                <span class="fw-medium text-body-secondary">Via Email</span>
                                <span class="badge bg-primary px-3 py-2">{{ logins.via_email }}</span>
                            </li>
                        </ul>
                    </StatCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { Doughnut } from "vue-chartjs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import StatCard from "./StatCard.vue";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.font.family = "Work Sans, sans-serif";

export default {
    components: { VueDatePicker, DoughnutChart: Doughnut, StatCard },
    data() {
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        return {
            loading: false,
            dateRange: [firstDay, lastDay],
            mostCreatedWidgets: [],
            userStats: {},
            logins: {},
            chartData: {
                widgets: null,
                userStats: null,
                logins: null,
            },
            showChart: false,
        };
    },
    mounted() {
        this.fetchStats();
    },
    methods: {
        async fetchStats() {
            this.loading = true;
            if (!this.dateRange || this.dateRange.length < 2) return;
            try {
                const response = await axios.get("stats", {
                    params: {
                        start_date: this.dateRange[0].toISOString().split("T")[0],
                        end_date: this.dateRange[1].toISOString().split("T")[0],
                    },
                });
                console.log(response.data);
                this.mostCreatedWidgets = response.data.most_created_widgets;
                this.userStats = response.data.user_stats;
                this.logins = response.data.logins;
                this.generateChartData();
            } catch (error) {
                console.error("Error fetching stats:", error);
            } finally {
                this.loading = false;
            }
        },
        generateChartData() {
            this.chartData.widgets = this.formatChartData(this.mostCreatedWidgets, "type", "count");
            this.chartData.userStats = this.formatChartData(this.userStats, null, null, [
                "signups",
                "paid_users",
                "premium_trials",
                "users_who_have_widgets",
                "users_without_widgets",
            ]);
            this.chartData.logins = this.formatChartData(this.logins, null, null, ["via_google", "via_email"]);
        },
        formatChartData(data, labelKey = null, valueKey = null, keys = []) {
            if (!data || (Array.isArray(data) && data.length === 0)) return null;

            const formatLabel = (str) => {
                return str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
            };

            const labels = labelKey ? data.map((item) => formatLabel(item[labelKey])) : keys.map(formatLabel);
            const values = valueKey ? data.map((item) => item[valueKey]) : keys.map((key) => data[key]);

            return {
                labels,
                datasets: [
                    {
                        data: values,
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.8)",
                            "rgba(54, 162, 235, 0.8)",
                            "rgba(255, 206, 86, 0.8)",
                            "rgba(75, 192, 192, 0.8)",
                            "rgba(153, 102, 255, 0.8)",
                        ],
                        hoverBackgroundColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(153, 102, 255, 1)",
                        ],
                        borderWidth: 1,
                        borderColor: "#fff",
                        hoverOffset: 12,
                        cutout: "60%",
                        borderRadius: 5,
                        shadowOffsetX: 2,
                        shadowOffsetY: 2,
                        shadowBlur: 10,
                        shadowColor: "rgba(0, 0, 0, 0.3)",
                    },
                ],
                options: {
                    responsive: true,
                    cutout: "50%",
                    animation: {
                        animateRotate: true,
                        animateScale: true,
                    },
                    plugins: {
                        legend: {
                            position: "left",
                            labels: {
                                boxWidth: 14,
                                boxHeight: 14,
                                padding: 12,
                                color: "#555",
                                font: {
                                    size: 14,
                                    weight: "bold",
                                    family: "Poppins",
                                },
                            },
                            usePointStyle: true,
                        },
                        tooltip: {
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            titleColor: "#fff",
                            bodyColor: "#ddd",
                            bodyFont: { size: 14 },
                            padding: 10,
                        },
                    },
                },
            };
        },
    },
};
</script>
