<template>
    <Create ref="createComponent" />
    <Read ref="readComponent" />
    <Update ref="updateComponent" :plans="plans" />
    <Delete ref="deleteComponent" />
    <ChangePassword ref="changePasswordComponent" />
</template>

<script>
import Create from "./Create";
import Read from "./Read";
import Update from "./Update";
import Delete from "./Delete";
import ChangePassword from "./ChangePassword";
import store from "../../../store";
import axios from "axios";

export default {
    components: {
        Create,
        Read,
        Update,
        Delete,
        ChangePassword,
    },
    data() {
        return {
            plans: [],
        };
    },

    methods: {
        async fetchPlans(sortBy = "id", sortDirection = "desc") {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}plans`, {
                    headers: {
                        Authorization: "Bearer " + store.getters.logged_in_user.data.token,
                    },
                    params: {
                        sort_by: sortBy,
                        sort_direction: sortDirection,
                    },
                });

                const res = response.data;

                if (res.success) {
                    this.plans = res.data;
                } else {
                    console.error("Error fetching plans:", res.message);
                }
            } catch (error) {
                console.error("Error fetching plans:", error);
            }
        },
    },
    mounted() {
        this.fetchPlans();
        this.$refs.readComponent.read();
    },
};
</script>

<style scoped></style>
