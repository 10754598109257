<template>
    <div
        class="modal fade"
        id="createItemModal"
        tabindex="-1"
        aria-labelledby="createItemModalLabel"
        aria-hidden="true"
        ref="createModal"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createItemModalLabel">Create new plan</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form @submit.prevent="submitForm">
                        <fieldset>
                            <legend>General Information</legend>
                            <div class="mb-3">
                                <label for="name" class="form-label">
                                    Name
                                    <span class="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="name"
                                    v-model.trim="data.name"
                                    placeholder="Enter the name"
                                    @blur="validateField('name')"
                                />
                                <p v-if="!validations.name" class="text-danger small">This field is required.</p>
                            </div>

                            <div class="mb-3">
                                <label for="description" class="form-label">
                                    Description
                                    <span class="text-danger">*</span>
                                </label>
                                <textarea
                                    class="form-control"
                                    id="description"
                                    v-model.trim="data.description"
                                    placeholder="Enter the description"
                                    rows="4"
                                    @blur="validateField('description')"
                                ></textarea>
                                <p v-if="!validations.description" class="text-danger small">This field is required.</p>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>
                                Features
                                <span class="text-danger">*</span>
                            </legend>
                            <draggable v-model="data.features" :options="{ handle: '.drag-handle', animation: 200 }">
                                <template #item="{ index }">
                                    <div class="d-flex align-items-center mb-2" :key="index">
                                        <div
                                            class="drag-handle me-2"
                                            style="cursor: move; padding: 0.2rem; background: #ccc; border-radius: 5px"
                                        >
                                            <i class="bi bi-grip-vertical"></i>
                                        </div>

                                        <div class="input-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model.trim="data.features[index].text"
                                                :placeholder="'Feature ' + (index + 1)"
                                            />
                                            <div class="input-group-text">
                                                <input
                                                    type="checkbox"
                                                    class="form-check-input"
                                                    v-model="data.features[index].included"
                                                />
                                            </div>
                                            <button type="button" class="btn btn-danger" @click="removeFeature(index)">
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </div>

                                        <div class="input-group ms-4">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model.trim="data.features[index].tooltip"
                                                placeholder="Tooltip (Optional)"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </draggable>

                            <button type="button" class="btn btn-success mt-2 mb-2" @click="addFeature">
                                <i class="bi bi-plus"></i> Add Feature
                            </button>
                            <p v-if="!validations.features" class="text-danger small">
                                At least one feature is required.
                            </p>
                        </fieldset>

                        <fieldset>
                            <legend>Pricing</legend>

                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="price" class="form-label">
                                        Monthly Price
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="monthly_price"
                                        v-model.number="data.monthly_price"
                                        placeholder="Enter the monthly price"
                                        @blur="validateField('monthly_price')"
                                    />
                                    <p v-if="!validations.monthly_price" class="text-danger small">
                                        This field is required.
                                    </p>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="price" class="form-label">
                                        Yearly Price
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="yearly_price"
                                        v-model.number="data.yearly_price"
                                        placeholder="Enter the yearly price"
                                        @blur="validateField('yearly_price')"
                                    />
                                    <p v-if="!validations.yearly_price" class="text-danger small">
                                        This field is required.
                                    </p>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend>Buttons</legend>

                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="monthly_button_text" class="form-label">
                                        Monthly Button Text
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="monthly_button_text"
                                        v-model.trim="data.monthly_button_text"
                                        placeholder="Enter monthly button text"
                                        @blur="validateField('monthly_button_text')"
                                    />
                                    <p v-if="!validations.monthly_button_text" class="text-danger small">
                                        This field is required.
                                    </p>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="monthly_button_link" class="form-label">
                                        Monthly Button URL
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="monthly_button_link"
                                        v-model.trim="data.monthly_button_link"
                                        placeholder="Enter monthly button URL"
                                        @blur="validateField('monthly_button_link')"
                                    />
                                    <p v-if="!validations.monthly_button_link" class="text-danger small">
                                        Please enter a valid URL.
                                    </p>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="monthly_button_function" class="form-label"
                                        >Monthly Button Function Name</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="monthly_button_function"
                                        v-model.trim="data.monthly_button_function"
                                        placeholder="Enter function name for monthly button"
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="yearly_button_text" class="form-label">
                                        Yearly Button Text
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="yearly_button_text"
                                        v-model.trim="data.yearly_button_text"
                                        placeholder="Enter yearly button text"
                                        @blur="validateField('yearly_button_text')"
                                    />
                                    <p v-if="!validations.yearly_button_text" class="text-danger small">
                                        This field is required.
                                    </p>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="yearly_button_link" class="form-label">
                                        Yearly Button URL
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="yearly_button_link"
                                        v-model.trim="data.yearly_button_link"
                                        placeholder="Enter yearly button URL"
                                        @blur="validateField('yearly_button_link')"
                                    />
                                    <p v-if="!validations.yearly_button_link" class="text-danger small">
                                        Please enter a valid URL.
                                    </p>
                                </div>

                                <div class="col-md-6 mb-3">
                                    <label for="yearly_button_function" class="form-label"
                                        >Yearly Button Function Name</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="yearly_button_function"
                                        v-model.trim="data.yearly_button_function"
                                        placeholder="Enter function name for yearly button"
                                    />
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div class="mb-3">
                                <label class="form-label">
                                    Status
                                    <span class="text-danger">*</span>
                                </label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            id="statusActive"
                                            class="form-check-input"
                                            :value="1"
                                            v-model="data.status"
                                        />
                                        <label for="statusActive" class="form-check-label">Active</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            type="radio"
                                            id="statusInactive"
                                            class="form-check-input"
                                            :value="0"
                                            v-model="data.status"
                                        />
                                        <label for="statusInactive" class="form-check-label">Inactive</label>
                                    </div>
                                </div>
                                <p v-if="!validations.status" class="text-danger small">This field is required.</p>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div class="mb-3">
                                <div class="form-check form-switch">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        id="isFeatured"
                                        v-model="data.is_featured"
                                    />
                                    <label class="form-check-label" for="isFeatured"> Feature this plan </label>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" @click="submitForm" :disabled="loading">
                        <span
                            v-if="loading"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        <span v-else>Save</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
import axios from "axios";
import draggable from "vuedraggable";

export default {
    components: {
        draggable,
    },
    data() {
        return {
            token: this.$store?.getters?.logged_in_user?.data?.token ?? "",
            data: {
                name: "",
                description: "",
                monthly_button_text: "",
                monthly_button_link: "",
                yearly_button_text: "",
                yearly_button_link: "",
                monthly_button_function: "",
                yearly_button_function: "",
                monthly_price: 0,
                yearly_price: 0,
                status: 1,
                widget_id: this.$route.params.widget_id,
                features: [],
                period: 0,
                is_featured: false,
            },
            validations: {
                name: true,
                description: true,
                monthly_button_text: true,
                monthly_button_link: true,
                yearly_button_text: true,
                yearly_button_link: true,
                monthly_price: true,
                yearly_price: true,
                status: true,
                features: true,
                period: true,
                is_featured: true,
            },
            loading: false,
        };
    },
    computed: {
        isFormValid() {
            return Object.values(this.validations).every((isValid) => isValid);
        },
    },
    methods: {
        addFeature() {
            this.data.features.push({ text: "", included: true });
            this.validations.features = true;
        },
        removeFeature(index) {
            this.data.features.splice(index, 1);
            this.validations.features = this.data.features.length > 0;
        },
        async submitForm() {
            if (!this.data.price) {
                this.data.price = 0;
            }

            this.validateAllFields();

            if (!this.isFormValid) {
                console.warn("Form is invalid");
                return;
            }

            this.loading = true;

            try {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}pricing-table-items`,
                    {
                        ...this.data,
                    },
                    {
                        headers: { Authorization: "Bearer " + this.token },
                    }
                );

                console.log("Item Created:", response.data);
                this.clearForm();
                const modalInstance = Modal.getInstance(this.$refs.createModal);
                modalInstance.hide();
                this.$emit("created-item");
                this.$parent.fetchItems();
                this.$parent.updatePreview();
            } catch (error) {
                console.error("Error creating item:", error);
            } finally {
                this.loading = false;
            }
        },
        clearForm() {
            this.data = {
                name: "",
                description: "",
                monthly_button_text: "",
                monthly_button_link: "",
                monthly_button_function: "",
                yearly_button_text: "",
                yearly_button_link: "",
                yearly_button_function: "",
                monthly_price: 0,
                yearly_price: 0,
                status: 1,
                widget_id: this.$route.params.widget_id,
                features: [],
                period: 0,
                is_featured: false,
            };
        },
        validateField(field) {
            if (field === "features") {
                this.validations.features = this.data.features.length > 0;
            } else if (field === "monthly_button_link") {
                if (this.data.monthly_button_link.trim() !== "") {
                    if (!/^https?:\/\//i.test(this.data.monthly_button_link)) {
                        this.data.monthly_button_link = "https://" + this.data.monthly_button_link.trim();
                    }

                    const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
                    this.validations.monthly_button_link = urlRegex.test(this.data.monthly_button_link);
                } else {
                    this.validations.monthly_button_link = false;
                }
            } else if (field === "yearly_button_link") {
                if (this.data.yearly_button_link.trim() !== "") {
                    if (!/^https?:\/\//i.test(this.data.yearly_button_link)) {
                        this.data.yearly_button_link = "https://" + this.data.yearly_button_link.trim();
                    }

                    const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
                    this.validations.yearly_button_link = urlRegex.test(this.data.yearly_button_link);
                } else {
                    this.validations.yearly_button_link = false;
                }
            } else if (this.data[field] === "" || this.data[field] === null) {
                this.validations[field] = false;
            } else {
                this.validations[field] = true;
            }
        },
        validateAllFields() {
            Object.keys(this.validations).forEach((field) => {
                this.validateField(field);
            });
        },
        showModal() {
            const modalInstance = new Modal(this.$refs.createModal);
            modalInstance.show();
        },
    },
};
</script>

<style scoped>
.modal-content {
    color: #000;
}
.drag-handle {
    cursor: move;
    padding: 0.2rem;
    background: #ccc;
    border-radius: 5px;
}
</style>
